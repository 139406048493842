import { Button, Modal } from "antd";
import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Left from "../../../themes/assets/images/svg/leave_management/ChevronLeft.svg";
import Right from "../../../themes/assets/images/svg/leave_management/ChevronRight.svg";
import moment from "moment";
import "./CalendarComponent.css";

const CalendarComponent = (props: any) => {
  const labelData = [
    { displayName: "Today", colorCode: "#0642B5" },
    { displayName: "Start & End Date", colorCode: "#0642B5" },
    { displayName: "Selected Days", colorCode: "#E6EFFF" },
  ];
  const initialSelect = props?.initialSelect;
  const parseInitialSelect = (
    dateRange: [string | undefined, string | undefined]
  ): [Date | null, Date | null] => {
    if (!dateRange || dateRange.length !== 2) {
      return [null, null];
    }
    const [startDateStr, endDateStr] = dateRange;
    if (!startDateStr || !endDateStr) {
      return [null, null];
    }
    const startDate = moment(startDateStr, "YYYY-MM-DD").startOf("day");
    const endDate = moment(endDateStr, "YYYY-MM-DD").endOf("day");
    if (!startDate.isValid() || !endDate.isValid()) {
      return [null, null];
    }
    if (startDate.isAfter(endDate)) {
      console.error("Invalid date range: startDate is after endDate");
      return [null, null];
    }
    return [startDate.toDate(), endDate.toDate()];
  };
  const [selectedWeek, setSelectedWeek] = useState<[Date | null, Date | null]>(
    initialSelect?.length === 2
      ? initialSelect
      : parseInitialSelect(initialSelect)
  );
  const today = new Date();
  const getLastEnabledWeek = (): [Date | null, Date | null] => {
    let current = moment().endOf("month").startOf("isoWeek");
    while (current.isAfter(moment().startOf("month"))) {
      const startOfWeek = current.toDate();
      if (
        !isWeekDisabled(startOfWeek) &&
        moment(startOfWeek).startOf("isoWeek").toDate() <= today
      ) {
        return [startOfWeek, moment(startOfWeek).endOf("isoWeek").toDate()];
      }
      current.subtract(1, "week");
    }
    return [null, null];
  };
  const isWeekDisabled = (startOfWeek) => {
    return (
      startOfWeek >= moment(today).startOf("isoWeek").toDate() ||
      startOfWeek.getDay() === 6 ||
      startOfWeek.getDay() === 0
    );
  };
  useEffect(() => {
    if (!selectedWeek[0] || !selectedWeek[1]) {
      const lastWeek = getLastEnabledWeek();
      setSelectedWeek(lastWeek as [Date | null, Date | null]);
    }
  }, []);

  const formatWeekRange = (startWeek, endWeek) => {
    const yearStart = moment(startWeek).year();
    const yearEnd = moment(endWeek).year();
    const formattedStart =
      yearStart === yearEnd
        ? moment(startWeek).format("MMM D")
        : moment(startWeek).format("MMM D, YYYY");
    const formattedEnd = moment(endWeek).format("MMM D, YYYY");
    return `${formattedStart} - ${formattedEnd}`;
  };
  const handleDateClick = (date) => {
    const startWeek = moment(date).startOf("isoWeek").toDate();
    const endWeek = moment(date).endOf("isoWeek").toDate();
    const formattedWeek = formatWeekRange(startWeek, endWeek);
    setSelectedWeek([startWeek, endWeek]);
    if (props?.selectedWeekDateObj) {
      props?.selectedWeekDateObj(formattedWeek);
      props?.selectedWeekStart(
        moment(date).startOf("isoWeek").format("YYYY-MM-DD")
      );
      props?.selectedWeekEnd(
        moment(date).endOf("isoWeek").format("YYYY-MM-DD")
      );
    }
    if (props?.weekHandler) {
      props?.weekHandler(
        moment(startWeek, "ddd MMM DD YYYY").format("YYYY-MM-DD")
      );
    }
  };

  return (
    <div id="react-calendar1" className="react-calendar">
      <Calendar
        className="calendar-view"
        maxDetail="month"
        returnValue="range"
        allowPartialRange={true}
        value={
          selectedWeek[0] && selectedWeek[1]
            ? selectedWeek
            : props?.selectedDate
            ? props?.selectedDate
            : parseInitialSelect(initialSelect)
        }
        defaultActiveStartDate={
          selectedWeek[1] && moment(selectedWeek[1]).isValid()
            ? moment(selectedWeek[1]).month() === 0
              ? new Date(moment(selectedWeek[1])?.year(), 0, 1)
              : new Date(selectedWeek[1])
            : selectedWeek[0] && moment(selectedWeek[0])?.isValid()
            ? new Date(selectedWeek[0])
            : new Date()
        }
        // onClickDay={(value, event) => console.log("valuevalue", value)}
        onClickDay={handleDateClick}
        tileContent={({ activeStartDate, date, view }) => {
          const colorCode = "#0642B5";
          return view === "month" && colorCode ? (
            <div
              id="leave-request-card-legend-color"
              className="leave-request-card-legend-color"
              style={{ backgroundColor: colorCode }}
            ></div>
          ) : null;
        }}
        minDetail="month"
        next2Label={null}
        prev2Label={null}
        nextLabel={<img src={Right} />}
        prevLabel={<img src={Left} />}
        tileDisabled={({ date }) => {
          const startOfWeek = moment(date).startOf("isoWeek").toDate();
          const isPastWeek =
            startOfWeek >= moment(today).startOf("isoWeek").toDate();
          const isWeekend = date.getDay() === 6 || date.getDay() === 0;
          return isPastWeek || isWeekend;
        }}
      />

      <div
        id="leave-request-card-legends-container-indicator"
        className="leave-request-card-legends-container-indicator"
      >
        {labelData.map((item, index) => (
          <div
            id="leave-request-card-legends-text-container-indicator"
            className="leave-request-card-legends-text-container-indicator"
          >
            <div
              id="leave-request-card-legends-text-container-indicator"
              className="leave-request-card-legend-color-indicator"
              style={{
                backgroundColor: index > 0 ? item.colorCode : "transparent",
                borderColor: index > 0 ? "transparent" : item.colorCode,
                // borderWidth: index > 0 ? 0 : 1,
              }}
            ></div>
            <div id="indicator-displayName">{item.displayName}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalendarComponent;
