import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../services/dex";
//import {CardDetails} from '../../components/separation/clearance-detail/ClearanceInterface'
import { cardData } from "../reducers/card_details";
import moment from "moment";

//get detail action
export const getWebLandingDetail = createAsyncThunk(
  "getWebLandingDetail",
  async (args: any, { rejectWithValue, dispatch }) => {
    const url = `dex-webapi/api/v1/webapi/page`;
    const month = moment().format("MMMM");
    const year = moment().format("YYYY");
    const lowerCaseCovertion = month.toLowerCase();
    const param = {
      storyOfDay: {
        contentType: "STORY_OF_THE_DAY",
        isPagination: "false",
      },
      calendarEvent: {
        month: lowerCaseCovertion,
        year: year,
      },
      holiday: {
        tag: "UPCOMING",
        countryCode: "IND",
        locationCode: "IND_BLR",
      },
      justOut: {
        contentType: "JUST_OUT",
        isPagination: false,
      },
    };

    try {
      const response = await api.post(url, param);
      const result = response.data;
      const { justOut, ...restResponse } = result;
      dispatch(cardData(justOut));
      return restResponse;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Header Notification
export const getHeaderNotification = createAsyncThunk(
  "getHeaderNotification",
  async (args: any, { rejectWithValue }) => {
    const { page = 0 } = args;
    let pageNum = page + 1;
    let url = `dex-webapi/api/v1/notification?page=${pageNum}`;
    try {
      const response = await api.get(url);
      const result = response.data;
      return { result, pageNum };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Header Notification Mark
export const putHeaderNotificationMark = createAsyncThunk(
  "putHeaderNotificationMark",
  async (args: any, { rejectWithValue }) => {
    const { notificationId, index } = args;
    let url = `dex-webapi/api/v1/notification`;
    if (notificationId) {
      url += `?notificationId=${notificationId}`;
    }
    try {
      const response = await api.put(url);
      const result = response.data;
      return { result, notificationId, index };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//getMegaMenu
export const getMegaMenu = createAsyncThunk(
  "getMegaMenu",
  async (args: any, { rejectWithValue }) => {
    const url = `dex-content/api/v1/icon-config/myworkspace-web`;
    try {
      const response = await api.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
