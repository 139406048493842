import { useAppSelector, useAppDispatch } from "../../redux/store/redux-hooks";
import {
  START,
  TIMESHEET_BREADCRUMB,
  MY_SPACE,
  NO_TIMESHEET,
  NO_TIMESHEET_SUBTITLE,
  MY_ACTIVITIES,
  CREATE_TIMESHEET,
  VIEW_ALL_LINK,
  TimeSheetStrings,
  NOTIFICATION_DURATION,
  SUCCESS,
  FAILURE,
  WARNING,
  DELETE_TIMESHEET,
  DELETE_TIMESHEET_MESSAGE,
  DELETE,
  CANCEL,
  CLONE_TIMESHEET,
} from "../../constants";
import n from "../navigation/Route.Names";
import amplitude from "amplitude-js";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import "./index.css";
import BreadcrumbComponent from "../common/breadcrumb/breadcrumb";
import TypesOfTimeSheet from "./components/TypesOfTimeSheet";
import PastHolidayComponent from "./components/PastHolidayComponent";
import CalendarComponent from "./components/CalendarComponent";
import DropDown from "./components/DropDown";
import ShiftTypeComponent from "./components/ShiftTypeComponent";
import MyActivityComponent from "./components/MyActivityComponent";
import MyActivityNotSubmittedComponent from "./components/MyActivityNotSubmittedComponent";
import timsheetCalendar from "../../themes/assets/images/timsheetCalendar.png";
import noTimeSheet from "../../themes/assets/images/timesheet/notimesheet.svg";
import CloneIcon from "../../themes/assets/images/timesheet/CloneIcon.svg";
import { RightArrow } from "../common/svg/rightArrow";
import ToastMessage from "../common/ToastMsg/toastMessage";
import ToastClose from "../../themes/assets/images/performance/svg/ToastClose.svg";
import ToastInfo from "../../themes/assets/images/performance/svg/ToastInfo.svg";
import ToastError from "../../themes/assets/images/performance/svg/ToastError.svg";
import moment from "moment";

import {
  List,
  Button,
  notification,
  Modal,
  Switch,
  Input,
  Tooltip,
  Spin,
} from "antd";
import {
  getDownloadTimesheet,
  getLandingTimeSheet,
  postExportTimesheet,
  deleteMyActivities,
  getDetailTimeSheet,
  getCloneTimeSheet,
} from "../../redux/actions/timesheet";
import {
  notificationAction,
  setSelectedWeekEnd,
  setSelectedWeekStart,
  setTimesheetClone,
} from "../../redux/reducers/timesheet";
import {
  GoalsErrorIcon,
  GoalsInfoIcon,
  ToastSuccessIcon,
} from "../common/svg/svg";
import MyActivitiesPreview from "./components/MyActivitiesPreview";
// import Delete_Timesheet from "../../themes/assets/images/Delete_bin.png";
// import ExportModal from "./components/Export";
import ExportModal from "./components/Export";
import Delete_Timesheet from "../../themes/assets/images/Delete_bin.png";
import TimesheetCloneComponent from "./components/TimesheetCloneComponent";
import { CalendarFilled, CalendarOutlined } from "@ant-design/icons";
import { cloneDeep } from "lodash";
interface Data {
  timesheetId: React.ReactNode;
  weekStartDate: React.ReactNode;
  weekEndDate: React.ReactNode;
}

const initialValues = {
  startDate: "",
  endDate: "",
  email: "",
};

const Timesheet = () => {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification({
    top: 0,
    maxCount: 1,
  });
  const dispatch = useAppDispatch();

  const {
    details,
    isCloneTimesheetEnabled,
    configuration,
    cloneSelectedWeek = {},
    disableCloneTimesheetBtn = false,
    loading,
    errorLandingDownload,
    loadingLandingDownload,
    errorLandingPagePost,
    loadingLandingPagePost,
    selectedWeekStart,
    selectedWeekEnd,
  } = useAppSelector((state: any) => state.timesheet);

  useEffect(() => {
    dispatch(setTimesheetClone());
  }, []);
  console.log("details", details);

  const handleChange = async () => {
    try {
      const week = selectedWeekStart ? selectedWeekStart : defaultWeekSelection;
      const types = shiftTypes.filter(
        (item: any, index) => index === shiftIndex
      );
      const shift = types.length > 0 ? types[0].name : "";
      const filterDropDown = dropDownSuggestions.filter(
        (item) => item.weekStartDate === defaultWeekSelection
      );
      const { cloneWeekStart, cloneWeekEnd } = cloneSelectedWeek || {};
      let single_row = cloneDeep(filterDropDown?.[0]) || {};
      if (selectedWeekStart) {
        single_row.weekStartDate = selectedWeekStart;
      }
      if (selectedWeekEnd) {
        single_row.weekEndDate = selectedWeekEnd;
      }
      if (selectedDates) {
        single_row.displayValue = selectedDates;
      }
      if (single_row) {
        const response = await dispatch(
          getDetailTimeSheet(
            isCloneTimesheetEnabled
              ? {
                  isClone: true,
                  cloneWeekStart,
                  cloneWeekEnd,
                  weekStartDate: single_row.weekStartDate,
                  weekEndDate: single_row.weekEndDate,
                }
              : single_row
          )
        );
        if (response?.payload?.data?.status === null) {
          navigate(
            `/web/${
              n.MY_SPACE
            }/timesheet/createtimesheet/${shift}/${week}?weekStartDate=${
              single_row.weekStartDate
            }&weekEndDate=${single_row.weekEndDate}&displayValue=${
              selectedDates ? selectedDates : selectedWeekDateObj?.displayValue
            }`
          );
        }
      }
      amplitude.getInstance().logEvent("Clicked Create TimeSheet");
    } catch (error) {
      return;
    }
  };
  const [shiftIndex, setShiftIndex] = useState(0);
  const {
    status = [],
    timeSheetInfo = {},
    holidayDetails,
    myActivities,
    myActivitiesNotSubmited,
  } = details || {};
  const { shiftTypes = [], dropDownSuggestions = [] } = timeSheetInfo;
  const [selectedWeekDateObj, setSelectedWeekDateObj] = useState(
    dropDownSuggestions[0] || {}
  );
  const [selectedDates, setSelectedDates] = useState(null);
  const [week = { weekStartDate: "" }] = dropDownSuggestions;

  const [defaultWeekSelection, setDefaultWeekSelection] = useState(
    week?.weekStartDate || ""
  );
  const [displayPreviewPopup, setDisplayPreviewPopup] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [previewSelectedItems, setPreviewSelectedItems] = useState<any>(null);
  const [exportPopUp, setExportPopUp] = useState(initialValues);
  const [visibleReminder, setVisibleReminder] = useState(false);
  const [visibleEmailReminder, setVisibleEmailReminder] = useState(false);
  const reminderSuccess = !errorLandingDownload;
  const reminderSuccessDownload = !errorLandingPagePost;
  const handleClose = () => {
    setVisibleReminder(false);
  };
  const handleClosePopup = () => {
    setVisibleEmailReminder(false);
  };

  const onCloseHandler = () => {
    dispatch(
      notificationAction({
        type: WARNING,
        message: "",
        isShowNotification: false,
        additionalInfo: "",
      })
    );
  };

  const onChange = (checked: boolean) => {
    dispatch(setTimesheetClone(checked));
    // document.getElementsByClassName('content-view')[0]?.scrollIntoView();
  };

  useEffect(() => {
    if (isCloneTimesheetEnabled && selectedWeekStart && selectedWeekEnd) {
      dispatch(
        getCloneTimeSheet({
          weekStartDate: selectedWeekStart,
          weekEndDate: selectedWeekEnd,
        })
      );
    }
  }, [selectedWeekStart, selectedWeekEnd, isCloneTimesheetEnabled]);

  useEffect(() => {
    if (configuration.isShowNotification) {
      // amplitude.getInstance().logEvent("TimeSheet Created");
      const type = configuration.alertMessage.type;
      api[type]({
        message: configuration.alertMessage.message,
        description: configuration.alertMessage.additionalInfo,
        placement: "topRight",
        duration: NOTIFICATION_DURATION,
        icon:
          type === SUCCESS ? (
            <ToastSuccessIcon />
          ) : type === FAILURE ? (
            <GoalsErrorIcon />
          ) : (
            <GoalsInfoIcon />
          ),
        className:
          type === SUCCESS
            ? `custom-toast-area custom-success-toast`
            : type === FAILURE
            ? `custom-toast-area custom-error-toast`
            : `custom-toast-area custom-info-toast`,

        onClose: onCloseHandler,
      });
    }
  }, [configuration.isShowNotification]);

  useEffect(() => {
    const [week = { weekStartDate: "" }] = dropDownSuggestions;
    setSelectedWeekDateObj(dropDownSuggestions[0] || {});
    setDefaultWeekSelection(week?.weekStartDate);
    dispatch(setSelectedWeekStart(dropDownSuggestions[0]?.weekStartDate));
    dispatch(setSelectedWeekEnd(dropDownSuggestions[0]?.weekEndDate));
  }, [dropDownSuggestions]);
  const items = [
    {
      title: (
        <Link
          to={`/${START}`}
          // onClick={(e) => redirection(e, TIMESHEET_BREADCRUMB)}
          className="link-highlight"
        >
          {"My Space"}
        </Link>
      ),
    },
    {
      title: TIMESHEET_BREADCRUMB,
    },
  ];

  const shiftHandler = (index) => {
    setShiftIndex(index);
  };

  useEffect(() => {
    dispatch(getLandingTimeSheet({}));
  }, []);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setExportPopUp({
      ...exportPopUp,
      [name]: value,
    });
  };

  useEffect(() => {}, []);

  const clickOnDownload = () => {
    dispatch(
      getDownloadTimesheet({
        startDate: moment(exportPopUp?.startDate, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        endDate: moment(exportPopUp?.endDate, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
      })
    )
      .unwrap()
      .then(() => {
        setVisibleReminder(true);
      })
      .catch((error) => {
        setVisibleReminder(true);
      });
  };

  const clickOnEmail = () => {
    dispatch(
      postExportTimesheet({
        startDate: moment(exportPopUp?.startDate, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        endDate: moment(exportPopUp?.endDate, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        // email: exportPopUp?.email,
      })
    )
      .unwrap()
      .then(() => {
        setVisibleEmailReminder(true);
      })
      .catch((error) => {
        setVisibleEmailReminder(true);
      });
  };

  const getCurrentDropDownObj = (obj) => {
    try {
      setSelectedWeekDateObj(obj);
    } catch (error) {}
  };
  const TimeSheetQuoteCard = () => {
    return (
      <div className="holiday-quote-card-container-timesheet">
        <div className="holiday-quote-card-background-timesheet">
          <div className="holiday-quote-card-image-timesheet">
            <img
              className="manholdinglapotop-timesheet"
              src={timsheetCalendar}
              alt=""
            />
          </div>
          <h1 className="holiday-quote-card-text-timesheet">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          </h1>
        </div>
      </div>
    );
  };
  useEffect(() => {
    if (previewSelectedItems) {
      setDisplayPreviewPopup(true);
    }
  }, [previewSelectedItems]);
  const weekHandler = (info) => {
    setDefaultWeekSelection(info);
  };
  const handlePreviewPopup = () => {
    setDisplayPreviewPopup(!displayPreviewPopup);
    setPreviewSelectedItems(null);
  };

  const deleteHandlerPreview = (record, item) => {
    setDisplayPreviewPopup(false);
    setDeletePopUp(true);
  };

  const editHandler = () => {
    const { weekStartDate, shiftType = "" } = previewSelectedItems || {};
    navigate(
      `/web/${MY_SPACE}/timesheet/createtimesheet/${shiftType}/${weekStartDate}`
    );
    amplitude.getInstance().logEvent("Clicked Create TimeSheet");
  };

  const clickOnMyActivityItem = (item) => {
    try {
      setPreviewSelectedItems(item);
    } catch (error) {}
  };

  const clickOnMyActivityNotSubmitted = (item) => {
    navigate(
      `/web/${n.MY_SPACE}/timesheet/createtimesheet/${"Regular Shift"}/${
        item?.startDate
      }?weekStartDate=${item?.startDate}&weekEndDate=${
        item?.endDate
      }&displayValue=${item?.notSubmittedWeek}`
    );
  };

  const myActivitiesList = myActivities?.slice(0, 3) || [];
  const myActivitiesNotSubmitedList =
    myActivitiesNotSubmited?.weekValuesList || [];
  const isViewAll = myActivities?.length >= 1 || myActivitiesNotSubmitedList?.length > 0;
  const isActivityVisible = myActivities?.length > 0 || myActivitiesNotSubmitedList?.length > 0;
  const handleDeleteHandler = () => {
    dispatch(
      deleteMyActivities({
        weekStartDate: previewSelectedItems?.weekStartDate || "",
        weekEndDate: previewSelectedItems?.weekEndDate || "",
      })
    )
      .unwrap()
      .then(() => {
        dispatch(getLandingTimeSheet({}));
      });
    setDisplayPreviewPopup(false);
    setDeletePopUp(false);
  };
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const handleInputClick = () => {
    setTooltipOpen(true);
  };
  return (
    <>
      {contextHolder}
      <div className="main_container">
        <div id="hidestricky_breadcrump" className="hidestricky_breadcrump">
          <BreadcrumbComponent items={items} />
          <div id="Seperation-page-title" className="Seperation-page-title">
            {TIMESHEET_BREADCRUMB}
            <div className="landing-page-footer">
              <ExportModal
                onChangeHandler={(e) => onChangeHandler(e)}
                startDate={exportPopUp?.startDate}
                endDate={exportPopUp?.endDate}
                email={exportPopUp?.email}
                clickOnDownload={() => clickOnDownload()}
                clickOnEmail={() => clickOnEmail()}
              />
            </div>
          </div>
        </div>
        <div className="main-sub-container top-row-view">
          <div className="main-sub-container column-view">
            <div className="main-sub-container sub-row-view">
              {status?.map((item, index) => {
                return (
                  <div
                    data-testid="timesheet"
                    id={`timesheet-box-otr_${index}`}
                    className="timesheet-box-otr"
                    style={{ marginLeft: index > 0 ? "25px" : "0px" }}
                  >
                    <TypesOfTimeSheet {...item} />
                  </div>
                );
              })}
            </div>
            <div
              id="caledar-main-view"
              className="main-sub-container caledar-main-view"
            >
              <div
                id="caledar-main-view-row-view"
                className="main-sub-container caledar-main-view-row-view"
              >
                <div id="create-timesheet-title-view">
                  <div
                    id="create-timesheet-title-text"
                    className="create-timesheet-title-text"
                  >
                    {TimeSheetStrings?.createTimesheetTitle}
                  </div>

                  <CalendarComponent
                    initialSelect={
                      selectedDates
                        ? selectedDates
                        : [
                            dropDownSuggestions[0]?.weekStartDate,
                            dropDownSuggestions[0]?.weekEndDate,
                          ]
                    }
                    selectedWeekDateObj={(week) => {
                      isCloneTimesheetEnabled && onChange(true);
                      setSelectedDates(week);
                    }}
                    selectedWeekStart={(startWeek) =>
                      dispatch(setSelectedWeekStart(startWeek))
                    }
                    selectedWeekEnd={(startWeek) =>
                      dispatch(setSelectedWeekEnd(startWeek))
                    }
                  />
                </div>
                <div className="landingpage-middle-content-scroll">
                  <div className="content-view">
                    <div id="txt-mandatory" className="txt-mandatory">
                      {TimeSheetStrings?.startEndDateText}{" "}
                      <span className="mandatory">*</span>
                    </div>
                    {/* {dropDownSuggestions.length > 0 && (
                      <DropDown
                        list={dropDownSuggestions}
                        getCurrentDropDownObj={(obj) =>
                          getCurrentDropDownObj(obj)
                        }
                        weekHandler={weekHandler}
                      />
                    )} */}
                    <Input
                      value={
                        selectedDates
                          ? selectedDates
                          : dropDownSuggestions[0]?.displayValue
                      }
                      suffix={
                        <Tooltip title={"Please select from the Calendar"}>
                          <CalendarOutlined />
                        </Tooltip>
                      }
                      className="tsm_input_create_timesheet"
                    />
                    <div className="shift-type-view">
                      <div id="txt-mandatory" className="txt-mandatory">
                        Shift Type{" "}
                        <span id="mandatory" className="mandatory">
                          *
                        </span>
                      </div>
                      <div
                        className="main-sub-container sub-row-view"
                        style={{ marginTop: 10 }}
                      >
                        <List
                          grid={{ gutter: 24, column: 2 }}
                          dataSource={shiftTypes}
                          renderItem={(item: any, index) => (
                            <List.Item>
                              <ShiftTypeComponent
                                {...item}
                                index={index}
                                activeIndex={shiftIndex}
                                shiftHandler={shiftHandler}
                              />
                            </List.Item>
                          )}
                        />
                      </div>
                    </div>
                    <div className="clone-main-container">
                      <div className="clone-sub-container">
                        <div className="clone-icon-title">
                          <img src={CloneIcon} alt="Clone icon" />
                          <p className="clone-title">
                            Clone data from previous week
                          </p>
                        </div>
                        <div className="clone-toggle-btn">
                          <Switch
                            onChange={onChange}
                            checked={isCloneTimesheetEnabled}
                          />
                        </div>
                      </div>
                      {isCloneTimesheetEnabled ? (
                        <TimesheetCloneComponent />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="devider-view" />
              <div className="button-view">
                <Button
                  id="create-timesheet-button"
                  type="primary"
                  onClick={handleChange}
                  style={{
                    height: 40,
                    fontFamily: "ProximaNova-Semibold",
                  }}
                  className={
                    disableCloneTimesheetBtn ? "disable-clone-timesheet" : ""
                  }
                  disabled={isCloneTimesheetEnabled && disableCloneTimesheetBtn}
                >
                  {loading ? (
                    <Spin spinning={loading}>
                      <div className="clone-create-spin-load"></div>
                    </Spin>
                  ) : isCloneTimesheetEnabled ? (
                    CLONE_TIMESHEET
                  ) : (
                    CREATE_TIMESHEET
                  )}
                </Button>
              </div>
            </div>
          </div>
          <div className="sub-row-right-view">
            {holidayDetails?.length > 0 && (
              <PastHolidayComponent holiday={holidayDetails} />
            )}
            <div className="my-activity-view">
              {isActivityVisible ? (
                <>
                  <div id="my-activity-text" className="my-activity-text">
                    {MY_ACTIVITIES}
                  </div>
                  {myActivitiesNotSubmitedList?.slice(0,1)?.map((item: any, index) => {
                    return (
                      <div
                        id={`my-activity-component_${index}`}
                        onClick={() => clickOnMyActivityNotSubmitted(item)}
                      >
                        <MyActivityNotSubmittedComponent
                          {...item}
                          status={myActivitiesNotSubmited?.status}
                          created={myActivitiesNotSubmited?.notCreated}
                        />
                      </div>
                    );
                  })}
                  {myActivitiesList?.map((item: any, index) => {
                    return (
                      <div
                        id={`my-activity-component_${index}`}
                        onClick={() => clickOnMyActivityItem(item)}
                      >
                        <MyActivityComponent {...item} />
                      </div>
                    );
                  })}
                  {isViewAll && (
                    <div className="my-activity-row-view-all">
                      <div
                        id="my-activity-view-all-txt"
                        className="my-activity-view-all-txt"
                        onClick={() => navigate(`myactivities`)}
                      >
                        {VIEW_ALL_LINK}
                      </div>
                      <div
                        style={{
                          marginLeft: 10,
                          marginTop: 20,
                        }}
                      >
                        <RightArrow />
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div id="my-activity-text-empty" className="my-activity-text">
                    {MY_ACTIVITIES}
                  </div>
                  <div
                    id="empty-activity-section"
                    className="empty-activity-section"
                  >
                    <img src={noTimeSheet} />
                    <div id="no-timesheet-text" className="no-timesheet-text">
                      {NO_TIMESHEET}
                    </div>
                    <div
                      id="no-timesheet-text-msg"
                      className="no-timesheet-text-msg"
                    >
                      {NO_TIMESHEET_SUBTITLE}
                    </div>
                  </div>
                </>
              )}
            </div>
            {/* <TimeSheetQuoteCard /> */}
          </div>
        </div>
      </div>
      {visibleReminder && (
        <ToastMessage
          closeAction={handleClose}
          customToastArea={
            reminderSuccess
              ? "custom-toast-area skills-extracted"
              : "custom-toast-area no-skills-extracted"
          }
          customToastLeftArea={"custom-left-area"}
          icon={reminderSuccess ? ToastInfo : ToastError}
          toastMsgTitle={
            reminderSuccess
              ? "Export File Successfully"
              : "Export File Unsuccessfull"
          }
          toastMsgPara={
            reminderSuccess
              ? "File has been successfully downloaded"
              : "Unable to download the file, please try later"
          }
          customToastRightArea={"close-btn-area"}
          closeIcon={ToastClose}
        />
      )}
      {visibleEmailReminder && (
        <ToastMessage
          closeAction={handleClosePopup}
          customToastArea={
            reminderSuccessDownload
              ? "custom-toast-area skills-extracted"
              : "custom-toast-area no-skills-extracted"
          }
          customToastLeftArea={"custom-left-area"}
          icon={reminderSuccessDownload ? ToastInfo : ToastError}
          toastMsgTitle={
            reminderSuccessDownload
              ? "Email Sent Successfully"
              : "File not Delivered"
          }
          toastMsgPara={
            reminderSuccessDownload
              ? "Email has been sent successfully"
              : "Unable to deliver the file, please try later"
          }
          customToastRightArea={"close-btn-area"}
          closeIcon={ToastClose}
        />
      )}
      {/* delete Modal */}
      <Modal
        open={deletePopUp}
        className="preview_delete-modal"
        onCancel={() => setDeletePopUp(false)}
        footer={null}
        centered
      >
        <div className="preview_delete">
          <img src={Delete_Timesheet} alt="Delete Icon" />
          <div className="preview_delete_title">{DELETE_TIMESHEET}</div>
          <div className="preview_delete_content">
            {DELETE_TIMESHEET_MESSAGE}
          </div>
          <div className="preview_buttons">
            <button
              onClick={() => setDeletePopUp(false)}
              className="add_manager_delete"
            >
              {CANCEL}
            </button>
            <button onClick={handleDeleteHandler} className="timesheet_submit">
              {DELETE}
            </button>
          </div>
        </div>
      </Modal>
      {displayPreviewPopup && (
        <MyActivitiesPreview
          openPreview={displayPreviewPopup}
          closePreview={handlePreviewPopup}
          pageName={MY_ACTIVITIES}
          deleteHandlerPreview={deleteHandlerPreview}
          editHandler={editHandler}
          previewRequest={previewSelectedItems}
        />
      )}
    </>
  );
};

export default Timesheet;
