import {
  START,
  APPROVALS_BREADCRUMB,
  APPLY_BUTTON,
  APPROVE_TIMESHEET,
  SEND_REMINDER,
  CANCEL,
} from "../../../../constants";
import "./FooterComponent.css";

const FooterComponent = (props) => {
  const ITEMS_SELECTED = "leaves selected";
  return (
    <div className="tsm_approval_footer_lm">
      <div className="tsm_footer_title_lm">{`${props?.seletedCount} ${ITEMS_SELECTED}`}</div>
      <div className="tsm_footer-buttons_lm">
        <>
          <div
            className="cancel-button-footer_lm"
            onClick={() => props?.handleCancel()}
          >
            {CANCEL}
          </div>
          <div
            className="timesheet_submit_lm"
            onClick={() => props?.handleApprove()}
          >
            {APPROVE_TIMESHEET}
          </div>
        </>
      </div>
    </div>
  );
};
export default FooterComponent;
