import { createAsyncThunk } from "@reduxjs/toolkit";
import timesheet from "../services/dex";

//get landing action
export const getLandingTimeSheet = createAsyncThunk(
  "getLandingTimeSheet",
  async (args: any, { rejectWithValue }) => {
    const url = `dex-timesheet/api/v2/timesheet/landingPage`;
    try {
      const response = await timesheet.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get clone timesheet
export const getCloneTimeSheet = createAsyncThunk(
  "getCloneTimesheet",
  async (params: any, { rejectWithValue }) => {
    const url = `dex-timesheet/api/v2/timesheet/clone-preview`;
    try {
      const response = await timesheet.get(url, { params });
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get detail action
export const getDetailTimeSheet = createAsyncThunk(
  "getDetailTimeSheet",
  async (params: any, { rejectWithValue }) => {
    const url = `dex-timesheet/api/v2/timesheet/details`;
    try {
      const response = await timesheet.get(url, {
        params,
      });
      const result = response.data;
      return { ...result, weekStartDate: params.weekStartDate };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//get detail action
export const getDetailTimeSheetWithEdit = createAsyncThunk(
  "getDetailTimeSheet",
  async (params: any, { rejectWithValue }) => {
    const url = `dex-timesheet/api/v2/timesheet/details?timesheetId=${params}`;
    try {
      const response = await timesheet.get(url);
      const result = response.data;
      return { ...result };
    } catch (error) {
      console.log("error", error);
      return rejectWithValue(error);
    }
  }
);

//getMyActivities
export const getMyActivities = createAsyncThunk(
  "getMyActivities",
  async (args: any, { rejectWithValue }) => {
    const { status, start, count } = args;
    const url = `dex-timesheet/api/v2/timesheet/my-activities?status=${status}&start=${start}&count=${count}`;
    try {
      const response = await timesheet.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//deleteMyActivities
export const deleteMyActivities = createAsyncThunk(
  "deleteMyActivities",
  async (
    args: { weekStartDate: string; weekEndDate: string },
    { rejectWithValue }
  ) => {
    const { weekStartDate, weekEndDate } = args;
    const url = `dex-timesheet/api/v2/timesheet/remove?weekStartDate=${weekStartDate}&weekEndDate=${weekEndDate}`;
    try {
      const response = await timesheet.delete(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//postCreateTimesheet
export const postCreateTimesheet = createAsyncThunk(
  "postCreateTimeSheet",
  async (args: any, { rejectWithValue }) => {
    const { payload } = args;
    const url = `dex-timesheet/api/v2/timesheet/create`;
    try {
      const response = await timesheet.post(url, payload);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//previewCreateTimesheet
export const previewTimesheet = createAsyncThunk(
  "previewTimesheet",
  async (args: any, { rejectWithValue }) => {
    const url = `dex-timesheet/api/v2/timesheet/preview`;
    const { payloadRequest } = args;
    try {
      const response = await timesheet.post(url, payloadRequest);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//approvals manager landing page tsm
export const getManagerTimesheet = createAsyncThunk(
  "getManagerTimesheet",
  async (args: any, { rejectWithValue }) => {
    const { status, start, count, selectedFeature } = args;
    let statusVal = selectedFeature === "LEAVE" ? status.toUpperCase() : status;
    let url = `dex-approval/api/v1/detail?start=${start}&count=${count}&isPagination=false&feature=${selectedFeature}&status=${statusVal}`;
    if (selectedFeature === "LEAVE") {
      url = `dex-approval/api/v1/detail?start=${start}&count=${count}&isPagination=false&feature=${selectedFeature}&status=${statusVal}&isWeb=TRUE`;
    }
    try {
      const response = await timesheet.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//getGroupByWeek
export const getGroupByWeek = createAsyncThunk(
  "getGroupByWeek",
  async (selectedStatus: any, { rejectWithValue }) => {
    const url = `dex-timesheet/api/v2/timesheet/manager/groupByWeek?status=${selectedStatus}`;
    try {
      const response = await timesheet.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//getPreviewManager
export const getPreviewManager = createAsyncThunk(
  "getPreviewManager",
  async (preview: any, { rejectWithValue }) => {
    const url = `dex-timesheet/api/v2/timesheet/my-activities-preview`;
    try {
      const response = await timesheet.post(url, preview);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//putApprove
export const putApproveManager = createAsyncThunk(
  "putApproveManager",
  async (approveBody: any, { rejectWithValue }) => {
    const url = `dex-timesheet/api/v2/timesheet/manager/approve`;
    try {
      const response = await timesheet.put(url, approveBody);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//putReject
export const putRejectManager = createAsyncThunk(
  "putRejectManager",
  async (rejectBody: any, { rejectWithValue }) => {
    let url = `dex-timesheet/api/v2/timesheet/manager/reject`;
    let body = rejectBody?.rejectBody;
    if (rejectBody?.isAdmin) {
      url = "dex-timesheet/api/v2/admin/admin-reject";
    }
    try {
      const response = await timesheet.put(url, body);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//getSearchManager
export const getSearchManager = createAsyncThunk(
  "getSearchManager",
  async (searchBody: any, { rejectWithValue }) => {
    const { searchKey, status, start, count } = searchBody;
    const url = `dex-timesheet/api/v2/timesheet/manager/search?searchKey=${searchKey}&status=${status}&start=${start}&count=${count}`;
    try {
      const response = await timesheet.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//approvals manager landing page detaisl
export const getManagerLanding = createAsyncThunk(
  "getManagerLanding",
  async (args: any, { rejectWithValue }) => {
    const url = `dex-approval/api/v1/landingPage?isPagination=false`;
    try {
      const response = await timesheet.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//draft Timesheet
export const saveTimesheet = createAsyncThunk(
  "saveTimesheet",
  async (args: any, { rejectWithValue }) => {
    const { payload } = args;
    const url = `dex-timesheet/api/v2/timesheet/save`;
    try {
      const response = await timesheet.post(url, payload);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//cleare approve Timesheet data
export const cleareApproveTimesheetData = createAsyncThunk(
  "cleareApproveTimesheetData",
  async (args: any, { rejectWithValue }) => {
    return {};
  }
);

//cleare reject Timesheet data
export const cleareRejectTimesheetData = createAsyncThunk(
  "cleareRejectTimesheetData",
  async (args: any, { rejectWithValue }) => {
    return {};
  }
);

//putBulkApproval Approvals
export const putBulkApproval = createAsyncThunk(
  "putBulkApproval",
  async (bulkApprovalBody: any, { rejectWithValue }) => {
    const url = `dex-timesheet/api/v2/timesheet/manager/bulk-approve`;
    try {
      const response = await timesheet.put(url, bulkApprovalBody);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//cleare bulkApproval Timesheet data
export const clearBulkApproveTimesheet = createAsyncThunk(
  "clearBulkApproveTimesheet",
  async (args: any, { rejectWithValue }) => {
    return {};
  }
);

//export Timesheet
export const getDownloadTimesheet = createAsyncThunk(
  "getDownloadTimesheet",
  async (args: any, { rejectWithValue }) => {
    const { startDate, endDate } = args;
    const url = `dex-timesheet/api/v1/timesheet/report/download?startDate=${startDate}&endDate=${endDate}`;
    try {
      const response = await timesheet.get(url, { responseType: "blob" });
      resolveAndDownloadBlob(response);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

function resolveAndDownloadBlob(response: any) {
  let filename = "timesheet.pdf";
  filename = decodeURI(filename);
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  link.remove();
}

//post export Timesheet
export const postExportTimesheet = createAsyncThunk(
  "postExportTimesheet",
  async (args: any, { rejectWithValue }) => {
    const { payload, startDate, endDate, email } = args;
    const url = `dex-timesheet/api/v1/timesheet/report/export?startDate=${startDate}&endDate=${endDate}`;
    try {
      const response = await timesheet.post(url, {});
      const result = response.data;

      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Sent Reminder
export const sendReminderNotification = createAsyncThunk(
  "sendReminder",
  async (args: any, { rejectWithValue }) => {
    const url = `/dex-timesheet/api/v1/notification/reminder/notifyUsers`;
    try {
      const response = await timesheet.post(url, args);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//cleare manager landing screen data
export const clearManagerLandingScreenData = createAsyncThunk(
  "clearManagerLandingScreenData",
  async (args: any, { rejectWithValue }) => {
    return {};
  }
);

//export manager Timesheet
export const getDownloadManagerTimesheet = createAsyncThunk(
  "getDownloadManagerTimesheet",
  async (args: any, { rejectWithValue }) => {
    const { startDate, endDate } = args;
    const url = `dex-timesheet/api/v2/timesheet/manager/download?startDate=${startDate}&endDate=${endDate}`;
    try {
      const response = await timesheet.get(url, { responseType: "blob" });
      resolveAndDownloadManagerBlob(response);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
//admin export data
export const getAdminExportData = createAsyncThunk(
  "getAdminExportData",
  async (args: any, { rejectWithValue }) => {
    // const { startDate, endDate } = args;
    // const url = `dex-timesheet/api/v2/admin/download-excel?startDate=${startDate}&endDate=${endDate}`;
    const url = `dex-timesheet/api/v2/admin/project-download`;

    try {
      const response = await timesheet.post(url, args, {
        responseType: "blob",
      });
      getExportDataAdminBlob(response);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

function getExportDataAdminBlob(response: any) {
  let filename = "adminExportData.xlsx";
  filename = decodeURI(filename);
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  link.remove();
}

//post export manager Timesheet
export const postExportManagerTimesheet = createAsyncThunk(
  "postExportManagerTimesheet",
  async (args: any, { rejectWithValue }) => {
    const { startDate, endDate } = args;
    const url = `dex-timesheet/api/v2/timesheet/manager/export?startDate=${startDate}&endDate=${endDate}`;
    try {
      const response = await timesheet.post(url, {});
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//post admin export data
export const postAdminExportData = createAsyncThunk(
  "postAdminExportData",
  async (args: any, { rejectWithValue }) => {
    // const { startDate, endDate } = args;
    // const url = `dex-timesheet/api/v2/admin/export-excel?startDate=${startDate}&endDate=${endDate}`;
    const url = `dex-timesheet/api/v2/admin/project-email`;

    try {
      const response = await timesheet.post(url, args);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getAdminFinanceLanding = createAsyncThunk(
  "getAdminFinanceLanding",
  async (adminSearch: any, { rejectWithValue }) => {
    const { searchKey, start, count } = adminSearch;
    const url = `/dex-timesheet/api/v2/admin/search?searchKey=${searchKey}&start=${start}&count=${count}`;
    try {
      const response = await timesheet.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//post admin persona export data
export const postEmployeeTimesheetData = createAsyncThunk(
  "postEmployeeTimesheetData",
  async (args: any, { rejectWithValue }) => {
    const { startDate, endDate, userId } = args;
    const url = `dex-timesheet/api/v2/admin/export?userId=${userId}&startDate=${startDate}&endDate=${endDate}`;
    try {
      const response = await timesheet.post(url, {});
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
//Admin persona employee preview
export const adminPersonaEmployeePreview = createAsyncThunk(
  "adminPersonaEmployeePreview",
  async (args: any, { rejectWithValue }) => {
    const url = `dex-timesheet/api/v2/admin/timesheet-preview `;
    try {
      const response = await timesheet.post(url, args);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Admin persona view employee timesheets list
export const adminPersonaEmployeeTimesheets = createAsyncThunk(
  "adminPersonaEmployeeTimesheets",
  async (args: any, { rejectWithValue }) => {
    const url = `dex-timesheet/api/v2/admin/view-employee?userId=${args?.empId}&start=${args?.start}&count=${args?.count}`;
    try {
      const response = await timesheet.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//post admin persona download data
export const adminPersonaDownloadData = createAsyncThunk(
  "adminPersonaDownloadData",
  async (args: any, { rejectWithValue }) => {
    const { startDate, endDate, userId } = args;
    const url = `dex-timesheet/api/v2/admin/download?userId=${userId}&startDate=${startDate}&endDate=${endDate}`;
    try {
      const response = await timesheet.get(url, { responseType: "blob" });
      getDownloadAdminBlob(response, args);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
function getDownloadAdminBlob(response: any, args: any) {
  const { userId, startDate, endDate } = args;
  let filename = `${userId}${startDate}${endDate}.pdf`;
  filename = decodeURI(filename);
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  link.remove();
}

function resolveAndDownloadManagerBlob(response: any) {
  let filename = "timesheetExcelreport.xlsx";
  filename = decodeURI(filename);
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  link.remove();
}

//Revert Timesheeet
export const revertTimesheetAdmin = createAsyncThunk(
  "revertTimesheetAdmin",
  async (args: any, { rejectWithValue }) => {
    const url = `dex-timesheet/api/v2/admin/admin-revert?timeSheetId=${args?.timesheetId}&startDate=${args?.startDate}&endDate=${args?.endDate}`;
    try {
      const response = await timesheet.put(url, {});
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Approve Timesheeet Admin
export const approveTimesheetAdmin = createAsyncThunk(
  "approveTimesheetAdmin",
  async (args: any, { rejectWithValue }) => {
    const url = `dex-timesheet/api/v2/admin/admin-approve?timeSheetId=${args?.timesheetId}&startDate=${args?.startDate}&endDate=${args?.endDate}`;
    try {
      const response = await timesheet.put(url, {});
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Search Project Admin
export const searchProjectAdmin = createAsyncThunk(
  "searchProjectAdmin",
  async (searchKey: any, { rejectWithValue }) => {
    // const { searchKey } = adminSearch;
    const url = `/dex-timesheet/api/v2/admin/load-project?searchKey=${searchKey}`;
    try {
      const response = await timesheet.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
