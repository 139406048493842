import { useState } from "react";
import { Input, Modal, Radio, Skeleton } from "antd";
import moment from "moment";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../../redux/store/redux-hooks";
import "./index.css";
import DelegateSearch from "../../../../themes/assets/images/svg/delegate_search-Icon.svg";
import DelegateSearchMember from "../../../../themes/assets/images/svg/delegate-intelligent-search.svg";
import AvatarProfile from "../../../Avatar/AvatarProfile";
import { DELEGATE_TEXT } from "../../../../constants";

const DelegateLeavePopup = (props) => {
  const [selectedItem, setSelectedItem] = useState({});
  const { getSearchUserListLMLoading, getSearchUserListLMData } =
    useAppSelector((state: any) => state.leavemanagement);
  const { Search } = Input;
  const RadioGroup = Radio.Group;
  const title = (
    <div className="delegate-header">
      <div className="modal-container-titlebar">
        <span>Delegate</span>
      </div>
      <Search
        addonBefore={<img src={DelegateSearch} />}
        placeholder="Search"
        className="delegate_search"
        onChange={(e) => props?.onSearchValue(e)}
        value={props?.value}
      />
    </div>
  );

  const SkeletonDelegate = () => (
    <Skeleton.Node
      style={{
        width: "100%",
        height: "72px",
        borderRadius: "8px",
        marginTop: 5,
      }}
      children={false}
      active={true}
    />
  );

  const delegateMemberOptions = getSearchUserListLMData?.data?.userList?.map(
    (item, index) => ({
      label: (
        <span className="member_title">
          <AvatarProfile
            name={item?.displayName}
            profilePic={item?.mediaResponse?.referenceUrl}
          />
          <div className="member_title-otr">
            <div className="member_title_text">{item?.displayName}</div>
            <div className="member_title_designation">{item?.desgination}</div>
          </div>
        </span>
      ),
      value: item,
    })
  );

  return (
    <Modal
      title={title}
      open={props?.isModalOpen}
      onCancel={props?.handleCancel}
      className={"modal-container preview-modal delegate-modal"}
      afterClose={() => setSelectedItem({})}
    >
      <div id="main-view_lm" className="main-view">
        {getSearchUserListLMLoading ? (
          <>
            {[1, 2, 3]?.map(() => (
              <SkeletonDelegate />
            ))}
          </>
        ) : Object?.keys(getSearchUserListLMData)?.length === 0 ? (
          <div className="delegate-main-view delegate_no-member">
            <div className="delegate_no-member-inn">
              <img className="SearchMember" src={DelegateSearchMember} />
              <span>Search for members</span>
            </div>
          </div>
        ) : (
          <>
            <RadioGroup
              className="delegate_radio-btn-inn"
              options={delegateMemberOptions}
              onChange={(data) => {
                setSelectedItem(data);
                props?.getSelectedManager(data?.target?.value);
              }}
            />
            <div className="delegate_otr">
              <button
                className="leave_approve delegate_text-btn"
                onClick={() => {
                  if (Object?.keys(selectedItem)?.length > 0) {
                    props?.handleDelegateClick();
                  }
                }}
                disabled={Object?.keys(selectedItem)?.length > 0 ? false : true}
              >
                {DELEGATE_TEXT}
              </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default DelegateLeavePopup;
