import { useNavigate } from "react-router-dom";
import "./absent-request.css";
import { Spin, Select, Table } from "antd";
import { useRef, useState, useEffect } from "react";
import SearchIcon from "../../../../themes/assets/images/search-icon.png";
import ClearIcon from "../../../../themes/assets/images/clear-icon.png";
import EditIcon from "../../../../themes/assets/images/svg/leave_management/PencilSimple.svg";
import { CancelLeaveModal } from "../Modals";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store/redux-hooks";
import {
  getAbsentRequestLanding,
  deleteLeave,
  clearLeaveSubmitData,
  submitEditLeaveRequest,
} from "../../../../redux/actions/leavemanagement";
import moment from "moment";
import { PreviewPopup } from "../Modals";
import { setUploadedFile } from "../../../../redux/reducers/leavemanagement";
import { classNames } from "primereact/utils";
import {
  paginationLeftIcon,
  paginationRightIcon,
} from "../../../common/svg/paginationIcons";
import EmptyCalendar from "../../../../themes/assets/images/calendar.png";
import { DesignTokenContext } from "antd/es/theme/context";
import {
  ABSENCE_REQUEST,
  LEAVE_MANAGEMENT,
  LM_APPROVED_TAB,
  LM_PENDING_TAB,
  LM_REJECTED_TAB,
} from "../../../../constants";
import {
  setEditData,
  setIsEditValue,
} from "../../../../redux/reducers/leavemanagement";
import { getLoggedInUserData } from "../../../utils/util";
import ToastMessage from "../../../common/ToastMsg/toastMessage";
import ToastInfo from "../../../../themes/assets/images/performance/svg/ToastInfo.svg";
import ToastError from "../../../../themes/assets/images/performance/svg/ToastError.svg";
import ToastClose from "../../../../themes/assets/images/performance/svg/ToastClose.svg";

const AbsentRequest = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState("PENDING");
  const [preview, setPreview] = useState(false);
  const [start, setStart] = useState(0);
  const [count, setCount] = useState(10);
  const [previewData, setPreviewData] = useState<any>({});
  const [cancelOpen, setCancelOpen] = useState(false);
  const [isPageStart, setIsPageStart] = useState(0);
  const [openCancelToast, setOpenCancelToast] = useState(false);
  const searchRef = useRef<HTMLInputElement | null>(null);
  const {
    errorAbsentLanding,
    absentLandingData,
    loadingAbsentLanding,
    deleteLeaveLoading,
    deleteLeaveData,
    editLeaveData,
    submitLeaveLoadnig,
    submitLeaveObj,
  } = useAppSelector((state: any) => state.leavemanagement);
  const userData = getLoggedInUserData();
  const [isToastVisible, setIsToastVisible] = useState(false);
  const isDeleteLeaveSuccess = deleteLeaveData?.status !== "ERROR";
  const cancelLeaveSuccess = submitLeaveObj?.status !== "ERROR";

  useEffect(() => {
    window.onpopstate = () => {
      dispatch(setEditData({}));
      dispatch(setIsEditValue(false));
    };
  }, []);
  useEffect(() => {
    const landingData = {
      status: activeTab,
      start: start,
      count: 10,
    };
    dispatch(getAbsentRequestLanding(landingData));
  }, [activeTab, start, deleteLeaveLoading]);

  useEffect(() => {
    setStart(0);
  }, [activeTab]);

  useEffect(() => {
    if (Object.keys(submitLeaveObj)?.length > 0) {
      setOpenCancelToast(true);
      const landingData = {
        status: activeTab,
        start: start,
        count: 10,
      };
      dispatch(getAbsentRequestLanding(landingData));
    }
  }, [submitLeaveObj]);

  const totalItems = absentLandingData?.searchCount;
  const handleCancelClose = () => {
    setCancelOpen(false);
  };

  const handleCancelConfirm = () => {
    setCancelOpen(false);
    if (previewData?.leaveApprovalStatus === "APPROVED") {
      const leaveToCancelObj = [
        previewData,
        { leaveApprovalStatus: "CANCELLED" },
      ];
      dispatch(submitEditLeaveRequest(Object.assign({}, ...leaveToCancelObj)));
    } else {
      const leaveId = previewData?.leaveId;
      dispatch(deleteLeave(leaveId));
    }
  };

  const handleEditable = () => {
    navigate("/web/my_space/leave");
    setPreview(false);
    dispatch(setEditData(previewData));
    dispatch(setIsEditValue(true));
  };

  const handleStatusRender = (status: any) => {
    return (
      <span className={`absent-request-status-${status}`}>
        <span className="absent-request-status-text">{status}</span>
      </span>
    );
  };

  const handleFormatDate = (date: any) => {
    return moment(date).format("DD MMM YYYY");
  };

  const PaginationActivities = () => {
    const ITEMS_PER_PAGE = "Items per page:";
    const total = absentLandingData?.searchCount || 0;

    const goToPreviousPage = () => {
      if (start > 0) {
        const newStart = Math.max(0, start - count);
        setStart(newStart);
        setIsPageStart(newStart);
      }
    };

    const goToNextPage = () => {
      if (start + count < total) {
        const newStart = Math.min(start + count, total);
        setStart(newStart);
        setIsPageStart(newStart);
      }
    };

    const handleValueChange = (value) => {
      setCount(value);
      setStart(0);
      setIsPageStart(0);
    };

    return (
      <div className="pagination-timesheet-content-container">
        <div className="pagination-timesheet">
          {ITEMS_PER_PAGE}{" "}
          <Select
            defaultValue={10}
            options={[{ value: 10, label: 10 }]}
            onChange={handleValueChange}
          />
          <div className="pagination-timesheet-text">
            {`${start + 1} - ${Math.min(
              start + count,
              total
            )} of ${total} items`}{" "}
          </div>
          <span className="parent_pagination_icons">
            <span
              className={`pagination_left_icon ${
                start === 0 ? "disabled" : ""
              }`}
              onClick={goToPreviousPage}
            >
              {paginationLeftIcon()}
            </span>
            <span
              className={`pagination_right_icon ${
                start + count >= total ? "disabled" : ""
              }`}
              onClick={goToNextPage}
            >
              {paginationRightIcon()}
            </span>
          </span>
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
      className: "absence-request-date-column",
      render: (_, dateArray: any) => {
        return (
          <>
            <span>
              <strong>
                {handleFormatDate(dateArray["startDate"])}
                {" - "}
                {handleFormatDate(dateArray["endDate"])}
              </strong>
            </span>
            <br />
            <span>{dateArray["displayRequesting"]}</span>
          </>
        );
      },
    },
    {
      title: "Leave Type",
      dataIndex: "leaveTypeDisplayName",
      key: "leaveTypeDisplayName",

      className: "absence-request-leave-type",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      className: "absence-request-reason-column",
    },
    {
      title: "Comments",
      dataIndex: "comment",
      key: "comment",
      className: "absence-request-comment-column",
    },
    {
      title: "Status",
      dataIndex: "leaveApprovalStatus",
      key: "leaveApprovalStatus",
      className: "absence-request-status-column",
      render: (_, leaveStatus) => {
        const leaveStatusData =
          leaveStatus?.workflowSteps?.displayProperties?.displayNameForEmployee;
        return handleStatusRender(leaveStatusData);
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      className: "absence-request-action-column",
      render: () => (
        <span className="absent-request-action-buttons-container">
          <img
            className="absent-request-action-button"
            src={EditIcon}
            alt="Edit"
            onClick={() => {
              setPreview((prev) => !prev);
            }}
          />
        </span>
      ),
    },
  ];

  const RejectedColumns = [
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
      className: "absence-request-date-column",
      render: (_, dateArray: any) => {
        return (
          <>
            <span>
              <strong>
                {handleFormatDate(dateArray["startDate"])}
                {" - "}
                {handleFormatDate(dateArray["endDate"])}
              </strong>
            </span>
            <br />
            <span>{dateArray["displayRequesting"]}</span>
          </>
        );
      },
    },
    {
      title: "Leave Type",
      dataIndex: "leaveTypeDisplayName",
      key: "leaveTypeDisplayName",

      className: "absence-request-leave-type",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      className: "absence-request-reason-column",
    },
    {
      title: "Comments",
      dataIndex: "comment",
      key: "comment",
      className: "absence-request-comment-column",
    },
    {
      title: "Status",
      dataIndex: "leaveApprovalStatus",
      key: "leaveApprovalStatus",
      className: "absence-request-status-column",
      render: (_, leaveStatus) => {
        const leaveStatusData =
          leaveStatus?.workflowSteps?.displayProperties?.displayNameForEmployee;
        return handleStatusRender(leaveStatusData);
      },
    },
  ];

  const ErrorTable = () => {
    return (
      <div className="error_table_tsm">
        <img className="error_table_img" src={EmptyCalendar} alt="" />
        <div className="error_table_header">No Leave Requests</div>
      </div>
    );
  };

  const handleAbsenceClose = () => {
    setPreview(false);
    setCancelOpen(true);
  };

  const handleToastClose = () => {
    setIsToastVisible(false);
    dispatch(clearLeaveSubmitData({}));
  };

  useEffect(() => {
    if (Object.keys(deleteLeaveData)?.length > 0) {
      const landingData = {
        status: activeTab,
        start: start,
        count: 10,
      };
      dispatch(getAbsentRequestLanding(landingData));
      setIsToastVisible(true);
    }
  }, [deleteLeaveData]);

  const handleClose = () => {
    setOpenCancelToast(false);
    dispatch(clearLeaveSubmitData({}));
  };

  return (
    <div
      className="absent-request-top-container"
      id="absent-request-top-container"
    >
      <span className="absent-request-page-navigation">
        <span
          className="absent-request-previous-page"
          onClick={() => {
            navigate(-1);
          }}
        >
          {LEAVE_MANAGEMENT}
        </span>
        {" " + "/ Absence Request"}
      </span>
      <div className="absent-request-header" id="absent-request-header">
        {ABSENCE_REQUEST}
      </div>
      <div
        className="absent-request-table-container"
        id="absent-request-table-container"
      >
        <div
          className="absent-request-table-filter-container"
          id="absent-request-table-filter-container"
        >
          {/* <div
            className="absent-request-table-search-container"
            id="absent-request-table-search-container"
          >
            <img
              src={SearchIcon}
              alt="Search"
              className="absent-request-table-search-icon"
            />
            <input
              ref={searchRef}
              className="absent-request-table-search"
              placeholder="Search"
            />
            <img
              src={ClearIcon}
              onClick={() => {
                return searchRef.current ? (searchRef.current.value = "") : "";
              }}
              alt="Clear"
              className="absent-request-table-search-icon"
            />
          </div> */}
          <div
            className="absent-request-table-button-parent"
            id="absent-request-table-button-parent"
          >
            <div
              onClick={() => {
                setActiveTab("PENDING");
              }}
              className={`absent-request-button-pending ${activeTab}`}
              id={`absent-request-button-pending ${activeTab}`}
            >
              {LM_PENDING_TAB}
            </div>
            <div
              id={`absent-request-button-approved ${activeTab}`}
              onClick={() => {
                setActiveTab("APPROVED");
              }}
              className={`absent-request-button-approved ${activeTab}`}
            >
              {LM_APPROVED_TAB}
            </div>
            <div
              id={`absent-request-button-rejected ${activeTab}`}
              onClick={() => {
                setActiveTab("REJECTED");
              }}
              className={`absent-request-button-rejected ${activeTab}`}
            >
              {LM_REJECTED_TAB}
            </div>
          </div>
        </div>
        <Spin
          spinning={
            loadingAbsentLanding || deleteLeaveLoading || submitLeaveLoadnig
          }
        >
          {absentLandingData?.leaveDeatilList?.length > 0 ? (
            <Table
              className="absent-request-table"
              onRow={(data: any) => ({
                onClick: () => {
                  const tempQuestionListArray =
                    data?.preview?.questionList?.map((questionItem) => {
                      if (
                        questionItem?.type === "DROPDOWN" ||
                        questionItem?.type === "RADIO_DROPDOWN" ||
                        questionItem?.type === "RADIO"
                      ) {
                        return {
                          ...questionItem,
                          answer:
                            data?.floaterName !== null
                              ? data?.floaterName
                              : questionItem?.jsonKey ===
                                "fractionQuantityTimeSpan"
                              ? data[`fractionQuantityName`]
                              : data[`${questionItem?.jsonKey}DisplayName`],
                        };
                      } else {
                        return {
                          ...questionItem,
                          answer: data[questionItem?.jsonKey],
                        };
                      }
                    });
                  const leaveData = {
                    ...data,
                    questionListArray: tempQuestionListArray,
                  };
                  const sizeInKB = (data?.attachment?.fileSize / 1024).toFixed(
                    2
                  );
                  const sizeInMB: any = (
                    data?.attachment?.fileSize /
                    (1024 * 1024)
                  ).toFixed(2);
                  const fileType = data?.attachment?.mimeType?.split("/"[1]);
                  const payload = {
                    userId: userData?.userId,
                    fileName: data?.attachment?.fileName,
                    fileContent: data?.attachment?.fileContent?.split(",")[1],
                  };
                  const uploadFile = {
                    ...payload,
                    name: data?.attachment?.fileName,
                    size: `${sizeInKB} kb`,
                    type: fileType,
                  };
                  dispatch(setUploadedFile(uploadFile));
                  setPreviewData(leaveData);
                  if (activeTab === "REJECTED") {
                    setPreview(true);
                  }
                },
              })}
              dataSource={absentLandingData["leaveDeatilList"]}
              columns={activeTab !== "REJECTED" ? columns : RejectedColumns}
              rowHoverable={true}
              rowClassName={"absent-request-table-row"}
              bordered={false}
            />
          ) : (
            <div className="error-leave-empty">
              <ErrorTable />
            </div>
          )}
          {totalItems > 0 ? <PaginationActivities /> : <></>}
        </Spin>
      </div>
      <CancelLeaveModal
        openCancel={cancelOpen}
        closeCancel={handleCancelClose}
        closeCancelConfirm={handleCancelConfirm}
      />
      <PreviewPopup
        isVisible={preview}
        clickOnCloseIcon={() => {
          setPreview(false);
        }}
        editable={handleEditable}
        clickOnClose={handleAbsenceClose}
        templateObj={previewData}
        previewData={previewData}
      />
      {isToastVisible && (
        <ToastMessage
          closeAction={handleToastClose}
          customToastArea={
            isDeleteLeaveSuccess
              ? "custom-toast-area skills-extracted"
              : "custom-toast-area no-skills-extracted"
          }
          customToastLeftArea={"custom-left-area"}
          icon={isDeleteLeaveSuccess ? ToastInfo : ToastError}
          toastMsgTitle={
            !isDeleteLeaveSuccess ? "FAILED" : deleteLeaveData?.message
          }
          toastMsgPara={deleteLeaveData?.genericMessage}
          customToastRightArea={"close-btn-area"}
          closeIcon={ToastClose}
        />
      )}
      {openCancelToast && (
        <ToastMessage
          closeAction={handleClose}
          customToastArea={
            cancelLeaveSuccess
              ? "custom-toast-area skills-extracted"
              : "custom-toast-area no-skills-extracted"
          }
          customToastLeftArea={"custom-left-area"}
          icon={cancelLeaveSuccess ? ToastInfo : ToastError}
          toastMsgTitle={
            cancelLeaveSuccess ? submitLeaveObj?.title : submitLeaveObj?.title
          }
          toastMsgPara={submitLeaveObj?.genericMessage}
          customToastRightArea={"close-btn-area"}
          closeIcon={ToastClose}
        />
      )}
    </div>
  );
};

export default AbsentRequest;
