import { CANCEL, TIMESHEET_POPUPS, CONFIRM } from "../../../../constants";
import TIMESHEET_IMG from "../../../../themes/assets/images/timesheet/Timemanagement.png";
import Modal from "antd/es/modal/Modal";
import "./ApproveConfirmPopup.css";
const ApproveConfirmPopup = (props) => {
  return (
    <>
      <Modal
        className="approval-tsm_lm"
        open={props?.open}
        footer={false}
        closeIcon={false}
        centered={true}
      >
        <div id="approval-popup-modal_lm" className="approval-popup-modal_lm">
          <img
            id="timesheetimg_lm"
            className="timesheetimg_lm"
            src={TIMESHEET_IMG}
          />

          <div id="timesheet-head_lm" className="timesheet-head_lm">
            {TIMESHEET_POPUPS?.APPROVE_TITLE_LM}
          </div>
          <div id="timesheet-body_lm" className="timesheet-body_lm">
            Are you sure you want to approve {props?.selectedUsers} leaves?
          </div>

          <div id="tsm-buttons_lm" className="tsm-buttons_lm">
            <button
              id="cancel-button_lm"
              className="cancel-button_lm"
              onClick={props?.handlePopupCancel}
            >
              {CANCEL}
            </button>
            <button
              id="confirm-button_lm"
              className="confirm-button_lm"
              onClick={props?.clickOnConfirm}
            >
              {CONFIRM}
            </button>
          </div>
        </div>
      </Modal>
      {/* {visible && !loadingBulkApproval && (
        <ToastMessage
          closeAction={handleClose}
          customToastArea={
            approveSuccess
              ? "custom-toast-area skills-extracted"
              : "custom-toast-area no-skills-extracted"
          }
          customToastLeftArea={"custom-left-area"}
          icon={approveSuccess ? ToastInfo : ToastError}
          toastMsgTitle={
            approveSuccess
              ? bulkApproval?.headerMsg
              : bulkApproval?.error?.errorHeaderMsg
          }
          toastMsgPara={
            approveSuccess
              ? bulkApproval?.subHeadermsg
              : bulkApproval?.error?.errorMessage
          }
          customToastRightArea={"close-btn-area"}
          closeIcon={ToastClose}
        />
      )} */}
    </>
  );
};

export default ApproveConfirmPopup;
