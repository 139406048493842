import "./index.css";
import {
  ConfigProvider,
  Descriptions,
  Input,
  Radio,
  RadioChangeEvent,
} from "antd";
import { Checkbox } from "antd";
import { useEffect, useState } from "react";
import CalendarIcon from "../../../themes/assets/images/svg/leave_management/CalendarBlank.svg";
import Right from "../../../themes/assets/images/svg/leave_management/ChevronRight.svg";
import { CalendarPopUp, DropDownPopup, SingleDatePopUp } from "./Modals";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store/redux-hooks";
import {
  dynamicApiCall,
  getLeaveTemplate,
  getLeaveTypes,
  dynamicApiCallReason,
  dynamicApiCallLeave,
  dynamicApiCallLeaveClear,
} from "../../../redux/actions/leavemanagement";
import moment from "moment";
import {
  setPreviewData,
  setTemplateObj,
  setCheckedHolidays,
  setUploadedFile,
  setEditData,
} from "../../../redux/reducers/leavemanagement";
import warningIcon from "../../../themes/assets/images/svg/leave_management/WarningIcon.svg";
import warningIconBlue from "../../../themes/assets/images/svg/leave_management/WarningIconBlue.svg";
import { getLoggedInUserData } from "../../utils/util";
import RelationsDropdown from "./RelationsDropdown";
import { FileUpload } from ".";
import { getOperatorType } from "../../utils/LMValidations";
import LeaveRequestErrorComponent from "./LeaveRequestErrorComponent";
import { useParams } from "react-router-dom";
const { TextArea } = Input;

export const TextFieldCard = ({
  placeholder,
  showCount = false,
  maxLength = 120,
  title,
  mandatory,
  onCommentText,
  leaveTypeId,
  editValue,
  isEdit,
}) => {
  const { editLeaveData } = useAppSelector(
    (state: any) => state.leavemanagement
  );
  const [changeComment, onChangeComment] = useState(editValue);
  useEffect(() => {
    if (Object.keys(editLeaveData)?.length === 0) {
      onChangeComment("");
    }
  }, [leaveTypeId]);
  return (
    <div id="form-item-container" className="form-item-container">
      <div id="form-item-text" className="form-item-text">
        {title}{" "}
        {mandatory ? (
          <span className="mandatory">*</span>
        ) : (
          <span>(Optional)</span>
        )}
      </div>
      <TextArea
        id="select-type-input-box"
        className="select-type-input-box"
        placeholder={placeholder}
        style={{ resize: "none", height: "87px" }}
        maxLength={maxLength}
        value={changeComment}
        onChange={(e) => {
          onChangeComment(e.target.value);
          onCommentText(e.target.value ? e.target.value : null);
        }}
        showCount={showCount}
      />
    </div>
  );
};

export const DropDownCard = (props: any) => {
  const {
    title,
    url,
    type,
    placeholder,
    selectedCardItem,
    mandatory,
    jsonKey,
    infoText,
    componentStatus,
    editValue,
    leaveTypeURL,
    leaveTypeId,
    leaveTypeCheck,
    setLeaveBalaneId,
  } = props;
  const [dropdownValue, setDropdownValue] = useState(editValue);
  const [optionPopup, setOptionPopup] = useState(false);
  const [validationInfo, setValidationInfo] = useState(null);
  const [leaveBalanceID, setLeaveBalanceID] = useState("");
  const dispatch = useAppDispatch();
  const { warningLeaveData, warningLeaveLoading, leaveTypesData } =
    useAppSelector((state: any) => state.leavemanagement);

  const OnClickCard = () => {
    if (url) {
      dispatch(dynamicApiCallReason({ url: url }));
    }
    setOptionPopup(true);
  };

  const OnClickSelectedCard = (data: any) => {
    const { displayName, remarks, validationMessage, order, leaveBalanceId } =
      data || "";
    dispatch(setCheckedHolidays({}));
    setLeaveBalanceID(leaveBalanceId);
    if (setLeaveBalaneId) {
      setLeaveBalaneId(leaveBalanceId);
    }

    if (
      displayName &&
      dropdownValue === displayName
      // || placeholder === displayName
    ) {
      setOptionPopup(false);
      setValidationInfo(null);
    } else if (validationMessage && validationMessage?.error) {
      setValidationInfo(validationMessage?.error);
    } else if (componentStatus === "static" || componentStatus === "dynamic") {
      setDropdownValue(displayName || remarks?.title);
      const value =
        jsonKey === "startEndDate"
          ? `${moment(remarks?.description)?.format("YYYY-MM-DD")},${moment(
              remarks?.description
            )?.format("YYYY-MM-DD")} `
          : type === "DROPDOWN"
          ? displayName || remarks?.title
          : order;
      setValidationInfo(null);
      selectedCardItem(data, value);
      setOptionPopup(false);
    } else {
      setValidationInfo(null);
    }
  };

  const handleClosePopup = () => {
    setOptionPopup(false);
    setValidationInfo(null);
  };

  const leaveTypeSelected = leaveTypesData?.find(
    (leaveType) => leaveType.leaveTypeId === leaveTypeId
  );
  return (
    <div id="form-item-container" className="form-item-container">
      <div id="form-item-text" className="form-item-text">
        {title} {mandatory && <span className="mandatory">*</span>}
      </div>
      <div
        onClick={() => OnClickCard()}
        id="select-type-input-box"
        className="select-type-input-box"
      >
        <div
          id="input-text"
          className="input-text"
          style={{ color: dropdownValue ? "#141414" : "#8c8c8c" }}
        >
          {dropdownValue ? dropdownValue : placeholder}
        </div>
        <img src={Right} />
      </div>
      {infoText || validationInfo ? <></> : null}
      {leaveBalanceID &&
      !warningLeaveLoading &&
      warningLeaveData?.leaveBalance === "0" ? (
        <div
          id="qa-floater-left"
          className="quick-apply-floater-landing"
          style={{ background: "#FAE6E6" }}
        >
          <span className="quick-apply-floater-left-img">
            <img src={warningIcon} alt="Warning" />
          </span>
          <span
            className="quick-apply-floater-left-txt"
            style={{ color: "#000000" }}
          >
            {warningLeaveData?.leaveTypeDisplayName}
          </span>
        </div>
      ) : null}
      {type === "DROPDOWN" ? (
        <DropDownPopup
          key={"dropdwon-modal"}
          isVisible={optionPopup}
          title={title}
          validation={validationInfo}
          onClose={handleClosePopup}
          OnClickSelectedCard={OnClickSelectedCard}
        />
      ) : null}
      {leaveTypeSelected?.validationMessage?.info && (
        <div className="maternity-leave-warning short-term-warning">
          <img src={warningIconBlue} alt="warningIconBlue" />
          {leaveTypeSelected?.validationMessage?.info}
        </div>
      )}
    </div>
  );
};

const CalendarCard = (props: any) => {
  const {
    title,
    placeholder,
    singleDatePick,
    weekendEnabled,
    mandatory,
    OnSelectedDates,
    url,
    leaveTypeId,
    validationCheck,
    questionId,
    editStartDate,
    editEndDate,
    ansCallBack,
    questionItemList,
    validations,
    leaveDaysCount,
    templateRuleValidation,
  } = props;
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [startDate, setStartDate] = useState(editStartDate);
  const [endDate, setEndDate] = useState(editEndDate);
  const [calendarClose, setCalendarClose] = useState(0);
  const [noOfRequestingdays, setNoOfRequestingDays] = useState("");
  const [editLeaveDays, setEditLeaveDays] = useState("");
  const dispatch = useAppDispatch();
  const {
    requestingdays,
    requestingdaysLoading,
    checkedHolidays,
    editLeaveData,
    isEdit,
  } = useAppSelector((state: any) => state.leavemanagement);
  useEffect(() => {
    if (Object.keys(editLeaveData)?.length === 0) {
      setStartDate("");
      setEndDate("");
    }
  }, [leaveTypeId, calendarClose]);

  useEffect(() => {
    if (isEdit) {
      dispatch(getLeaveTemplate({ templateId: leaveTypeId }));
    }
  }, [isEdit, leaveTypeId]);

  const displayDate = () => {
    if (startDate && endDate) {
      if (singleDatePick) {
        return startDate;
      } else {
        return `${startDate} - ${endDate}`;
      }
    } else {
      return placeholder;
    }
  };

  const [startEditCount, setStartEditCount] = useState(
    editLeaveData?.displayRequesting
  );

  const { isFloaterHolidays = [] } = requestingdays || [];
  // useEffect(() => {
  //   const itemData = questionItemList?.filter((item) => item?.type === "TEXT");

  //   if (itemData?.[0]?.type === "TEXT" && requestingdays?.result?.requesting) {
  //     console.log("questionItemList", questionItemList);
  //     ansCallBack(
  //       itemData?.[0]?.questionId,
  //       requestingdays?.result?.requesting
  //     );
  //   }
  // }, [requestingdays]);

  useEffect(() => {
    const calculateRequestingDays = () => {
      if (isEdit) {
        if (editLeaveDays) {
          if (requestingdays?.result?.requesting === editLeaveDays) {
            setStartEditCount(editLeaveDays);
            return editLeaveDays;
          }
          return startEditCount;
        }
        return startEditCount;
      }
      if (startDate && endDate && requestingdays?.result?.requesting) {
        if (requestingdays?.result?.requesting === editLeaveDays) {
          setStartEditCount(editLeaveDays);
          return editLeaveDays;
        }
        return startEditCount;
      }
      if (isEdit) {
        return editLeaveData?.displayRequesting;
      }
      return "-";
    };
    setNoOfRequestingDays(calculateRequestingDays());
  }, [
    isEdit,
    editLeaveDays,
    requestingdays,
    startEditCount,
    startDate,
    endDate,
    editLeaveData,
  ]);

  const setDatesSelectedInCalendar = (
    selectedStartDate,
    selectedEndDate,
    daysCount
  ) => {
    if (selectedStartDate !== "" && selectedEndDate !== "") {
      setStartDate(moment(selectedStartDate).format("DD MMM yyyy"));
      setEndDate(moment(selectedEndDate).format("DD MMM yyyy"));
      OnSelectedDates(selectedStartDate, selectedEndDate, daysCount);
    } else {
      setStartDate("");
      setEndDate("");
      OnSelectedDates(selectedStartDate, selectedEndDate, daysCount);
    }
    // setCalendarVisible(false);
  };

  const getFloaterHolidayConatiner = (floaterHoliday) => {
    const { holidayCode, displayName, holidayDate } = floaterHoliday || {};

    const handleChecked = (e) => {
      dispatch(
        setCheckedHolidays({
          ...checkedHolidays,
          [holidayCode]: { ...floaterHoliday, checked: e.target.checked },
        })
      );
    };
    return (
      <div className="qa-floater-lb-check" key={holidayCode}>
        <Checkbox
          onChange={handleChecked}
          checked={checkedHolidays?.[holidayCode]?.checked || false}
        >
          <span className="qa-floater-lb-check-date">
            {moment(holidayDate)?.format("DD MMM YYYY")}
          </span>
          <span className="qa-floater-lb-check-type-name">({displayName})</span>
        </Checkbox>
      </div>
    );
  };

  return (
    <div id="calendar-form-item-parent" className="calendar-form-item-parent">
      <div
        id="calendar-form-item-container"
        className="calendar-form-item-container"
      >
        <div
          id="form-item-container"
          className="form-item-container"
          style={{ flex: 0.85 }}
        >
          <div id="form-item-text" className="form-item-text">
            Start Date and End Date <span className="mandatory">*</span>
          </div>
          <div
            onClick={() => setCalendarVisible(true)}
            id="select-type-input-box"
            className="select-type-input-box "
          >
            <div id="input-text" className="input-text">
              {displayDate()}
            </div>
            <img src={CalendarIcon} />
          </div>
        </div>
        <div
          id="form-item-container"
          className="form-item-container"
          style={{ flex: 0.15 }}
        >
          <div id="form-item-text" className="form-item-text">
            Days
          </div>
          <div id="requesting-days-box" className="requesting-days-box">
            <div id="input-text" className="input-text">
              {noOfRequestingdays}
            </div>
          </div>
        </div>
        <CalendarPopUp
          isVisible={calendarVisible}
          weekendEnabled={weekendEnabled}
          onClose={() => {
            setCalendarVisible(false);
          }}
          // hardwareBackClose={() => setCalendarVisible(false)}
          createAbsence={true}
          singleDatePick={singleDatePick}
          selectedDates={(selectedStartDate, selectedEndDate, daysCount) =>
            setDatesSelectedInCalendar(
              selectedStartDate,
              selectedEndDate,
              daysCount
            )
          }
          url={url}
          leaveTypeId={leaveTypeId}
          questionId={questionId}
          validationCheck={validationCheck}
          error={true}
          validations={validations}
          leaveDaysCount={leaveDaysCount}
          editLeaveRequested={(days) => setEditLeaveDays(days)}
        />
      </div>
      {isFloaterHolidays?.length ? (
        <div id="qa-floater-lb-check" className="qa-floater-lb-checkbox">
          <div className="qa-floater-lb-check-txt">
            Do you want to opt for the floater holiday?
          </div>
          {isFloaterHolidays.map((floaterHoliday) =>
            getFloaterHolidayConatiner(floaterHoliday)
          )}{" "}
        </div>
      ) : (
        ""
      )}
      {templateRuleValidation && (
        <div style={{ marginTop: "8px" }}>
          <LeaveRequestErrorComponent
            errorTitle={templateRuleValidation?.errorMessage?.title}
            errorDescription={templateRuleValidation?.errorMessage?.description}
          />
        </div>
      )}
    </div>
  );
};

export function SingleDateCalendarCard(props: any) {
  const {
    leaveType,
    placeholder,
    mandatory,
    title,
    onDateChange,
    questionId,
    validations,
    editStartDate,
    editEndDate,
    isEdit,
  } = props;
  const { editLeaveData } = useAppSelector(
    (state: any) => state.leavemanagement
  );
  const [visible, setVisible] = useState(false);

  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState<String>(
    editStartDate ? editStartDate : ""
  );
  const [formatedDate, setFormatedDate] = useState<String>(
    editStartDate ? moment(editStartDate).format("DD MMM YYYY") : ""
  );

  const displayDate = () => {
    if (formatedDate !== "") {
      return formatedDate;
    } else {
      return placeholder;
    }
  };

  const onChange = (date: any) => {
    setExpectedDeliveryDate(date);
    setFormatedDate(moment(date).format("DD MMM YYYY"));
  };

  useEffect(() => {
    if (Object.keys(editLeaveData)?.length === 0) {
      setFormatedDate("");
      setExpectedDeliveryDate("");
    }
  }, [leaveType]);

  return (
    <div className="expected-delivery-date-container">
      <div id="form-item-text" className="form-item-text">
        {title} {mandatory && <span className="mandatory">*</span>}
      </div>
      <div
        className="expected-delivery-date-input"
        onClick={() => {
          setVisible(() => true);
        }}
      >
        <span className="expected-delivery-date-text">{displayDate()}</span>
        <img
          src={CalendarIcon}
          alt="calanderIcon"
          className="expected-delivery-date-icon"
        />
      </div>
      <SingleDatePopUp
        leaveType={leaveType}
        visible={visible}
        onChange={onChange}
        onClose={() => {
          setVisible(() => false);
        }}
        onDateSelect={onDateChange}
        expectedDeliveryDate={expectedDeliveryDate}
        selectedDate={formatedDate}
        questionId={questionId}
        validations={validations}
      />
    </div>
  );
}

export function RadioButtonCard(props: any) {
  const {
    title,
    mandatory,
    url,
    onClickRadioValue,
    jsonKey,
    ansCallBack,
    leaveUnit,
    editRadioData,
  } = props;

  const [selectedValue, setSelectedValue] = useState(null);
  // const [radioIndex, setRadioIndex] = useState(-1);
  const dispatch = useAppDispatch();
  const { dynamicApiData, dynamicApiLoading } = useAppSelector(
    (state: any) => state.leavemanagement
  );

  useEffect(() => {
    if (url) {
      dispatch(dynamicApiCall({ url: url }));
    }
  }, [url]);

  useEffect(() => {
    if (!dynamicApiLoading) {
      if (editRadioData) {
        const selectedIndex = dynamicApiData
          ?.map((item, index) =>
            item?.value === editRadioData ||
            item?.externalCode === editRadioData
              ? item
              : ""
          )
          ?.filter((i) => i != "");
        setSelectedValue(selectedIndex?.[0]);
      } else {
        if (jsonKey === "fractionQuantityTimeSpan") {
          setSelectedValue(dynamicApiData?.[0]);
          ansCallBack(dynamicApiData?.[0]?.value, dynamicApiData?.[0]?.key);
        }
      }
    }
  }, [dynamicApiData, dynamicApiLoading]);

  const onChange = (e: RadioChangeEvent) => {
    const item = e.target.value;
    setSelectedValue(item);
    let day = "";
    const leaveUnitData = leaveUnit === "DAYS" ? "day" : "Hours";
    if (item?.value && item?.value?.includes(",")) {
      day = `${item?.value?.split(",")?.[0]} ${leaveUnitData}`;
    }

    onClickRadioValue(
      item?.value || item?.externalCode,
      item?.key || item?.uiLabelValue,
      day
    );
  };

  return (
    <div id="custom-radio" className="custom-radio">
      <div className="form-item-text">
        {title}
        {mandatory && <span className="mandatory">*</span>}
      </div>
      <Radio.Group
        onChange={onChange}
        value={selectedValue}
        id="leave-request-fractionality-container"
        className="leave-request-fractionality-container"
      >
        {dynamicApiData?.map((item: any, index: any) => (
          <Radio value={item} className="leave-request-fractionality-option">
            {title === "Number of Children" ? item?.uiLabelValue : item?.key}
          </Radio>
        ))}
      </Radio.Group>
    </div>
  );
}

export const LeaveForm = ({
  leavetypeId,
  route,
  mandatoryCheck,
  setMandatoryCheck,
  setPreviewObjData,
  setPreviewCommonObjData,
  setRuleCheck,
}) => {
  const dispatch = useAppDispatch();
  const {
    leaveTypesData,
    leaveTypesError,
    requestingdays,
    requestingdaysLoading,
    leaveTemplateLoading,
    warningLeaveLoading,
    leaveTemplate,
    templateObj,
    previewData,
    editLeaveData,
    isEdit,
  } = useAppSelector((state: any) => state.leavemanagement);
  // const { isEdit } = useParams();
  const userData = getLoggedInUserData();
  const [dropdownModal, setDropdownModal] = useState<boolean>(false);
  const [dropdownModalTitle, setDropdownModalTitle] = useState("");
  const [leaveTypeId, setLeaveTypeId] = useState(leavetypeId);
  const [selectedLeaveType, setSelectedLeaveType] = useState<any>({});
  const [leaveTypeBalanceId, setLeaveTypeBalanceId] = useState(leavetypeId);
  const [previewPopup, setPreviewPopup] = useState(false);
  const [startDate, setStartDate] = useState(
    isEdit ? editLeaveData?.startDate : ""
  );
  const [endDate, setEndDate] = useState(isEdit ? editLeaveData?.endDate : "");
  const [previewObj, setPreviewObj] = useState<any>({
    leaveTypeDisplayName: null,
    startDate: isEdit ? editLeaveData?.startDate : null,
    endDate: isEdit ? editLeaveData?.endDate : null,
    displayRequesting: isEdit ? editLeaveData?.displayRequesting : null,
    questionListArray: [],
  });
  const [commonData, setCommonData] = useState({
    userId: null, //userData?.userId,
    leaveTypeId: isEdit ? editLeaveData?.leaveTypeId : null,
    oldAttachmentId: null,
    leaveId: isEdit ? editLeaveData?.leaveId : null,
  });
  const [weekendEnabled, setWeekendEnabled] = useState(false);
  const [leaveDaysCount, setLeaveDaysCount] = useState(0);
  const [datesCountData, setDatesCountData] = useState({
    count: isEdit ? editLeaveData?.displayRequesting : null,
    floaterList: [],
  });
  const [leaveTemplateData, setLeaveTemplateData] = useState<
    Record<string, any>
  >({});
  const leaveTypeQuestion = "Leave Type";
  // const leaveTypeURL =
  //   leaveTemplate?.pagesList?.[0]?.sectionList?.[0]?.questionsList?.[0]?.api;
  // const leaveTypeCheck =
  //   leaveTemplate?.pagesList?.[0]?.sectionList?.[0]?.questionsList?.[0]?.type;

  const [rules, setRules] = useState<{ questionId: string; value: string }[]>(
    []
  );
  const [ruleValidationMsg, setRuleValidationMsg] = useState<any>({});
  const [leaveBalanceID, setLeaveBalaneId] = useState("");

  useEffect(() => {
    if (
      leaveBalanceID !== "" &&
      leaveTemplateData?.pagesList?.[0]?.sectionList?.[0]?.questionsList?.[0]
        ?.type === "DYNAMIC"
    ) {
      const leaveBalanceCheck = {
        link: leaveTemplateData?.pagesList?.[0]?.sectionList?.[0]
          ?.questionsList?.[0]?.api,
        templateID: leaveBalanceID,
      };
      dispatch(dynamicApiCallLeave({ leaveBalanceCheck }));
    } else {
      dispatch(dynamicApiCallLeaveClear({}));
    }
  }, [leaveBalanceID, leaveTemplateData]);

  useEffect(() => {
    dispatch(getLeaveTypes({}));
  }, []);

  useEffect(() => {});

  useEffect(() => {
    setLeaveDaysCount(0);
  }, [leaveTypeId]);

  useEffect(() => {
    if (leaveTypesError === false) {
      const leaveTypeSelected = isEdit
        ? leaveTypesData
            ?.filter((item) => item?.leaveTypeId === editLeaveData?.leaveTypeId)
            .shift()
        : leaveTypesData?.[0];
      setLeaveTypeId(leaveTypeSelected?.leaveTypeId);
      setLeaveTypeBalanceId(leaveTypeSelected?.leaveBalanceId);
      setSelectedLeaveType(leaveTypeSelected);
      // setLeaveTypes(getLeaveTypesData);
      setWeekendEnabled(leaveTypeSelected?.weekEndInclusionEnabled);
      setPreviewObj({
        ...previewObj,
        leaveTypeDisplayName: leaveTypeSelected?.displayName,
      });
      setPreviewObjData({
        ...previewObj,
        leaveTypeDisplayName: leaveTypeSelected?.displayName,
      });
      setCommonData({
        ...commonData,
        leaveTypeId: leaveTypeSelected?.leaveTypeId,
      });
      setPreviewCommonObjData({
        ...commonData,
        leaveTypeId: leaveTypeSelected?.leaveTypeId,
      });
    }
  }, [leaveTypesError]);

  // useEffect(() => {
  //   const args = {
  //     link: leaveTypeURL,
  //     templateId: null,
  //   };
  //   dispatch(dynamicApiCallLeave({}));
  // }, []);

  useEffect(() => {
    if (!mandatoryCheck?.isMandatory) {
      const selectedDate = previewObj?.questionListArray
        ?.map((item: any) =>
          item?.jsonKey === "startEndDate" ? item?.answerList : null
        )
        .filter((i) => i !== null);
      let start = "";
      let end = "";
      if (selectedDate?.[0]) {
        [start, end] = selectedDate[0].split(",");
      }
      setPreviewObjData({
        ...previewObj,
        startDate: start || startDate,
        endDate: end || endDate,
        displayRequesting: datesCountData?.count || "1 day",
      });
    }
  }, [!mandatoryCheck?.isMandatory, previewObj]);

  useEffect(() => {
    setRules([]);
    setRuleValidationMsg({});
    if (selectedLeaveType?.validationMessage?.error === null) {
      setLeaveTemplateData(leaveTemplate);
      const tempTemplateData = JSON.parse(JSON.stringify(leaveTemplate));
      const validations =
        tempTemplateData?.validations?.frontEndValidations || "";
      if (validations) {
        // dispatch(setValidationRulesData(validations));
      }

      const tempQuestionListArray = leaveTemplate?.pagesList
        ?.map((pageListItem) =>
          pageListItem?.sectionList?.map((sectionItem) =>
            sectionItem?.questionsList?.map((questionItem) => {
              return questionItem;
            })
          )
        )
        .shift()
        ?.reduce((acc, val) => acc.concat(val), []);

      if (isEdit && editLeaveData?.leaveTypeId === leaveTypeId) {
        const editQuestionListArray = tempQuestionListArray?.map((item) => {
          if (item?.jsonKey && item?.jsonKey !== null) {
            if (
              item?.type === "DROPDOWN" ||
              item?.type === "RADIO_DROPDOWN" ||
              item?.type === "RADIO"
            ) {
              return {
                ...item,
                answer:
                  item?.jsonKey === "fractionQuantityTimeSpan"
                    ? editLeaveData?.[`fractionQuantityName`]
                    : editLeaveData?.[`${item?.jsonKey}DisplayName`],
                answerList: editLeaveData?.[item?.jsonKey],
              };
            } else {
              return {
                ...item,
                answer: editLeaveData?.[item?.jsonKey],
                answerList: editLeaveData?.[item?.jsonKey],
              };
            }
          } else {
            return item;
          }
        });

        dispatch(setTemplateObj(editQuestionListArray));
        setPreviewObj({
          ...previewObj,
          questionListArray: editQuestionListArray,
        });

        setPreviewObjData({
          ...previewObj,
          questionListArray: editQuestionListArray,
        });

        const tempMandatoryArray = editQuestionListArray
          ?.map((item) => {
            if (
              item?.mandatory === true &&
              item?.answer !== "" &&
              item?.answer !== null &&
              item?.jsonKey !== "fractionQuantityTimeSpan"
            )
              return item;
            else return null;
          })
          .filter((i) => i !== null);

        setMandatoryCheck({
          ...mandatoryCheck,
          initialMandatoryList: tempMandatoryArray,
        });
      } else {
        dispatch(setTemplateObj(tempQuestionListArray));
        setPreviewObj({
          ...previewObj,
          questionListArray: tempQuestionListArray,
        });
        setPreviewObjData({
          ...previewObj,
          questionListArray: tempQuestionListArray,
        });

        const tempMandatoryArray = tempQuestionListArray
          ?.map((item) => {
            if (
              item?.mandatory === true &&
              (item?.answer === "" || item?.answer === null) &&
              item?.jsonKey !== "fractionQuantityTimeSpan"
            )
              return item;
            else return null;
          })
          .filter((i) => i !== null);

        setMandatoryCheck({
          ...mandatoryCheck,
          initialMandatoryList: tempMandatoryArray,
        });
      }
    }
  }, [leaveTypeId, leaveTemplate, selectedLeaveType]);

  function ansCallBack(questionId, previewAPIVal, previewUIVal, questionItem) {
    const updateQuestionItem = { ...questionItem };
    const tempQuestionListArray: any = templateObj?.map((item: any) => {
      if (item?.questionId === questionId) {
        updateQuestionItem.answer = previewUIVal;
        return {
          ...item,
          answer: previewUIVal,
          answerList: previewAPIVal,
        };
      } else {
        return item;
      }
    });
    dispatch(
      setPreviewData({ ...previewData, [questionId]: updateQuestionItem })
    );
    dispatch(setTemplateObj(tempQuestionListArray));

    setPreviewObj({ ...previewObj, questionListArray: tempQuestionListArray });
    setPreviewObjData({
      ...previewObj,
      questionListArray: tempQuestionListArray,
    });

    const mandatoryCheckAnswered = tempQuestionListArray
      ?.map((item) => {
        if (
          item?.mandatory === true &&
          item?.answer !== "" &&
          item?.answer !== null &&
          item?.jsonKey !== "fractionQuantityTimeSpan"
        )
          return item;
        else return null;
      })
      .filter((i) => i != null);

    if (isEdit && editLeaveData?.leaveTypeId === commonData?.leaveTypeId) {
      const isParamEdited = tempQuestionListArray
        ?.map((item) => {
          if (
            item?.answerList === editLeaveData?.[item?.jsonKey] ||
            item?.jsonKey === null
          ) {
            return true;
          } else {
            return false;
          }
        })
        ?.filter((i) => i !== true);

      if (
        mandatoryCheckAnswered?.length !==
          mandatoryCheck?.initialMandatoryList?.length ||
        isParamEdited?.length === 0
      ) {
        setMandatoryCheck({
          ...mandatoryCheck,
          isMandatory: true,
        });
      } else {
        setMandatoryCheck({
          ...mandatoryCheck,
          isMandatory: false,
        });
      }
    } else {
      if (
        mandatoryCheckAnswered?.length !==
        mandatoryCheck?.initialMandatoryList?.length
      ) {
        setMandatoryCheck({
          ...mandatoryCheck,
          isMandatory: true,
        });
      } else {
        setMandatoryCheck({
          ...mandatoryCheck,
          isMandatory: false,
        });
      }
    }
  }
  const OnClickSelectedLeave = (item: any) => {
    // const isEditedLeavetype =
    //   isEdit && editLeaveData?.previewData?.leaveTypeId === item?.leaveTypeId;
    setStartDate(
      // isEditedLeavetype ? editLeaveData?.previewData?.startDate :
      ""
    );
    setEndDate(
      // isEditedLeavetype ? editLeaveData?.previewData?.endDate :
      ""
    );
    dispatch(
      setPreviewData({
        ...previewData,
        leaveType: { ...item, question: leaveTypeQuestion },
      })
    );
    dispatch(setEditData({}));
    setSelectedLeaveType(item);
    setLeaveTemplateData({});
    setLeaveTypeId(item?.leaveTypeId);
    setWeekendEnabled(item?.weekEndInclusionEnabled);
    setDatesCountData({
      count:
        // isEditedLeavetype
        //   ? editLeaveData?.previewData?.displayRequesting
        //   :
        null,
      floaterList: [],
    });
    setCommonData({
      ...commonData,
      leaveTypeId: item?.leaveTypeId,
    });
    setPreviewCommonObjData({
      ...commonData,
      leaveTypeId: item?.leaveTypeId,
    });
    setMandatoryCheck({
      ...mandatoryCheck,
      isMandatory: true,
    });
    setPreviewObj({
      ...previewObj,
      leaveTypeDisplayName: item?.displayName,
    });
    setPreviewObjData({
      ...previewObj,
      leaveTypeDisplayName: item?.displayName,
    });
    dispatch(getLeaveTemplate({ templateId: item?.leaveTypeId }));
  };
  const OnClickPreview = () => {
    const selectedDate = previewObj?.questionListArray
      ?.map((item: any) =>
        item?.jsonKey === "startEndDate" ? item?.answerList : null
      )
      .filter((i) => i !== null);
    let start = "";
    let end = "";
    if (selectedDate?.[0]) {
      [start, end] = selectedDate[0].split(",");
    }
    setPreviewObj({
      ...previewObj,
      startDate: start || startDate,
      endDate: end || endDate,
      displayRequesting: datesCountData?.count || "1 day",
    });
    setPreviewPopup(true);
  };

  function getEditTemplateAnswers(jsonKey) {
    if (isEdit && editLeaveData?.leaveTypeId === commonData?.leaveTypeId) {
      return editLeaveData?.[jsonKey];
    } else {
      return null;
    }
  }

  const getMLError = () => {
    const { value, errorMessage } =
      leaveTemplate?.validations?.backEndValidations?.[0] || {};
    if (value == "MALE") {
      return <span className="gender-validations">{errorMessage}</span>;
    }
    return "";
  };

  useEffect(() => {
    const concatenatedRule = rules.reduce(
      (acc, curr) => {
        acc.questionId += (acc?.questionId ? "," : "") + curr?.questionId;
        acc.value += (acc.value ? "," : "") + curr.value;

        return acc;
      },
      { questionId: "", value: "" }
    );

    const templateValidations = leaveTemplate?.validations?.frontEndValidations;
    let matchingValidations = templateValidations?.filter(
      (item) =>
        item.questionId === concatenatedRule?.questionId &&
        item.rule === concatenatedRule?.value
    );

    const condition = matchingValidations?.[0]?.valueArr?.[0];
    const match = condition ? condition.match(/([><=]+)(\d+)/) : [];

    if (!match) setRuleValidationMsg(null);
    else {
      const threshold = parseInt(match[2], 10);
      const requestingValue = parseInt(
        requestingdays?.result?.requesting?.match(/\d+/)?.[0] || "0",
        10
      );
      let isValidationFailed = requestingValue > threshold;

      if (isValidationFailed) setRuleValidationMsg(matchingValidations[0]);
      else setRuleValidationMsg(null);
    }
    setRuleCheck(ruleValidationMsg);
  }, [rules, requestingdays, ruleValidationMsg]);

  return (
    <div id="form-container" className="form-container">
      <DropDownCard
        title={leaveTypeQuestion}
        placeholder={selectedLeaveType?.displayName}
        type={"DROPDOWN"}
        selectedCardItem={(item: any) => OnClickSelectedLeave(item)}
        mandatory={true}
        leaveTypeId={leaveTypeId}
        // infoText={selectedLeaveType?.validationMessage?.info}
        componentStatus={"static"}
        dropdownModal={dropdownModal}
        leaveTypeURL={
          leaveTemplate?.pagesList?.[0]?.sectionList?.[0]?.questionsList?.[0]
            ?.api
        }
        leaveTypeCheck={
          leaveTemplate?.pagesList?.[0]?.sectionList?.[0]?.questionsList?.[0]
            ?.type
        }
        editValue={isEdit ? editLeaveData?.leaveTypeDisplayName : ""}
        setLeaveBalaneId={(value) => setLeaveBalaneId(value)}
      />
      {leaveTemplate?.templateId == "MS_IND_RV" ? getMLError() : ""}
      {leaveTemplate?.pagesList?.map((pageItem: any, pageIndex: number) =>
        pageItem?.sectionList?.map((sectionItem: any, sectionIndex: number) =>
          sectionItem?.questionsList?.map(
            (questionItem: any, questionIndex: number) => (
              <>
                {questionItem?.type === "SINGLE_DATE" && (
                  <SingleDateCalendarCard
                    leaveType={leaveTypeId}
                    placeholder={questionItem?.description}
                    mandatory={questionItem?.mandatory}
                    title={questionItem?.question}
                    questionId={questionItem?.questionId}
                    validations={
                      leaveTemplateData?.validations?.frontEndValidations
                    }
                    onDateChange={(value: any) => {
                      ansCallBack(
                        questionItem?.questionId,
                        value,
                        value,
                        questionItem
                      );
                    }}
                    editStartDate={
                      isEdit &&
                      editLeaveData?.leaveTypeId === commonData?.leaveTypeId
                        ? moment(
                            editLeaveData?.[`${questionItem?.jsonKey}`]
                          ).format("DD MMM yyyy")
                        : null
                    }
                    editEndDate={
                      isEdit &&
                      editLeaveData?.leaveTypeId === commonData?.leaveTypeId
                        ? moment(
                            editLeaveData?.[`${questionItem?.jsonKey}`]
                          ).format("DD MMM yyyy")
                        : null
                    }
                    isEdit={isEdit}
                  />
                )}
                {questionItem?.type === "TEXTAREA" && (
                  <TextFieldCard
                    placeholder={questionItem?.description}
                    mandatory={questionItem?.mandatory}
                    title={questionItem?.question}
                    editValue={getEditTemplateAnswers(questionItem?.jsonKey)}
                    maxLength={200}
                    showCount={true}
                    onCommentText={(value: any) =>
                      ansCallBack(
                        questionItem?.questionId,
                        value,
                        value,
                        questionItem
                      )
                    }
                    leaveTypeId={leaveTypeId}
                    isEdit={isEdit}
                  />
                )}
                {questionItem?.type === "DROPDOWN" && (
                  <DropDownCard
                    // onClickCard={() => {
                    //   setDropdownModalTitle(questionItem?.question);
                    //   dispatch(
                    //     dynamicApiCallReason({ url: questionItem?.api })
                    //   );
                    //   setDropdownModal(true);
                    // }}
                    value={""}
                    ansCallBack={(data: any, value: any) => {
                      ansCallBack(
                        questionItem?.questionId,
                        value,
                        "",
                        questionItem
                      );
                    }}
                    title={questionItem?.question}
                    type={questionItem?.type}
                    editValue={getEditTemplateAnswers(
                      `${questionItem?.jsonKey}DisplayName`
                    )}
                    placeholder={questionItem?.description}
                    url={questionItem?.api}
                    jsonKey={questionItem?.jsonKey}
                    selectedCardItem={(data: any, value: any) =>
                      ansCallBack(
                        questionItem?.questionId,
                        value,
                        data?.remarks?.title,
                        questionItem
                      )
                    }
                    mandatory={questionItem?.mandatory}
                    componentStatus={"dynamic"}
                  />
                )}

                {questionItem?.type === "DATE" && (
                  <CalendarCard
                    weekendEnabled={weekendEnabled}
                    title={questionItem?.question}
                    questionId={questionItem?.questionId}
                    validations={
                      leaveTemplateData?.validations?.frontEndValidations
                    }
                    editStartDate={
                      isEdit &&
                      editLeaveData?.leaveTypeId === commonData?.leaveTypeId
                        ? moment(
                            editLeaveData?.[`${questionItem?.jsonKey}`]?.split(
                              ","
                            )[0]
                          ).format("DD MMM yyyy")
                        : null
                    }
                    editEndDate={
                      isEdit &&
                      editLeaveData?.leaveTypeId === commonData?.leaveTypeId
                        ? moment(
                            editLeaveData?.[`${questionItem?.jsonKey}`]?.split(
                              ","
                            )[1]
                          ).format("DD MMM yyyy")
                        : null
                    }
                    OnSelectedDates={(
                      selectedStartDate,
                      selectedEndDate,
                      daysCount
                    ) => {
                      if (selectedStartDate && selectedEndDate) {
                        const startDateFormatted =
                          moment(selectedStartDate)?.format("YYYY-MM-DD");
                        const endDateFormatted =
                          moment(selectedEndDate)?.format("YYYY-MM-DD");
                        setDatesCountData({
                          count: daysCount?.result?.requesting,
                          floaterList: daysCount?.isFloaterHolidays,
                        });
                        setLeaveDaysCount(
                          daysCount?.result?.requesting.split(" days")[0]
                        );
                        setStartDate(startDateFormatted);
                        setEndDate(endDateFormatted);
                        ansCallBack(
                          questionItem?.questionId,
                          `${startDateFormatted},${endDateFormatted}`,
                          `${startDateFormatted},${endDateFormatted}`,
                          questionItem
                        );
                      }
                    }}
                    placeholder={questionItem?.description}
                    mandatory={questionItem?.mandatory}
                    url={questionItem?.api}
                    leaveTypeId={leaveTypeId}
                    templateID={leaveTemplateData?.templateId}
                    leaveDaysCount={leaveDaysCount}
                    templateRuleValidation={ruleValidationMsg}
                    isEdit={isEdit}
                    // ansCallBack={(data: any, value: any) => {
                    //   console.log("data", data, value);
                    //   ansCallBack(data, value, value, questionItem);
                    // }}
                    // questionItemList={sectionItem?.questionsList}
                  />
                )}

                {questionItem?.type === "RADIO_DROPDOWN" && (
                  <RelationsDropdown
                    value={""}
                    ansCallBack={(data: any, value: any) => {
                      ansCallBack(
                        questionItem?.questionId,
                        value,
                        "",
                        questionItem
                      );
                    }}
                    title={questionItem?.question}
                    type={questionItem?.type}
                    editValue={getEditTemplateAnswers(
                      `${questionItem?.jsonKey}DisplayName`
                    )}
                    placeholder={questionItem?.description}
                    url={questionItem?.api}
                    jsonKey={questionItem?.jsonKey}
                    selectedCardItem={(data: any, value: any) => {
                      if (leaveTypesData?.[0]?.countryCode === "POL")
                        // Only for Poland
                        setRules((prev) => {
                          const existingIdx = prev.findIndex(
                            (obj) => obj.questionId === questionItem.questionId
                          );

                          if (existingIdx !== -1) {
                            const updatedRules = [...prev];
                            updatedRules[existingIdx] = {
                              ...updatedRules[existingIdx],
                              value: `=${data.order}`,
                            };
                            return updatedRules;
                          }
                          return [
                            ...prev,
                            {
                              questionId: questionItem.questionId,
                              value: `=${data.order}`,
                            },
                          ];
                        });
                      ansCallBack(
                        questionItem?.questionId,
                        value,
                        data?.remarks?.title,
                        questionItem
                      );
                    }}
                    mandatory={questionItem?.mandatory}
                    componentStatus={"dynamic"}
                  />
                )}

                {questionItem?.type === "RADIO" &&
                  ((questionItem?.jsonKey === "fractionQuantityTimeSpan" &&
                    (datesCountData?.count === "1 day" ||
                      datesCountData?.count === "0.5 day" ||
                      datesCountData?.count === "8 Hours" ||
                      datesCountData?.count === "4 Hours")) ||
                    questionItem?.jsonKey !== "fractionQuantityTimeSpan") && (
                    <RadioButtonCard
                      title={questionItem?.question}
                      editRadioData={getEditTemplateAnswers(
                        questionItem?.jsonKey
                      )}
                      mandatory={questionItem?.mandatory}
                      url={questionItem?.api}
                      jsonKey={questionItem?.jsonKey}
                      leaveUnit={selectedLeaveType?.unit}
                      onClickRadioValue={(value: any, key: any, day: any) => {
                        ansCallBack(
                          questionItem?.questionId,
                          value,
                          key,
                          questionItem
                        );
                        if (
                          questionItem?.jsonKey === "fractionQuantityTimeSpan"
                        ) {
                          setDatesCountData({
                            ...datesCountData,
                            count: day,
                          });
                        }
                      }}
                      ansCallBack={(value: any, key: any) =>
                        ansCallBack(
                          questionItem?.questionId,
                          value,
                          key,
                          questionItem
                        )
                      }
                    />
                  )}

                {questionItem?.type === "UPLOAD" && (
                  <FileUpload
                    title={questionItem?.question}
                    {...questionItem}
                    selectedLeaveType={selectedLeaveType}
                    onUpload={(value: any, uploadFile) => {
                      ansCallBack(
                        questionItem?.questionId,
                        value,
                        "attachments",
                        questionItem
                      );
                      dispatch(setUploadedFile(uploadFile));
                    }}
                    onClickCloseAttachment={() => {
                      ansCallBack(questionItem?.questionId, null, null, null);
                      setCommonData({
                        ...commonData,
                        oldAttachmentId: isEdit
                          ? editLeaveData?.attachment?.attachmentId
                          : null,
                      });
                      setPreviewCommonObjData({
                        ...commonData,
                        oldAttachmentId: isEdit
                          ? editLeaveData?.attachment?.attachmentId
                          : null,
                      });
                      dispatch(setUploadedFile({}));
                    }}
                    editFile={getEditTemplateAnswers(questionItem?.jsonKey)}
                  />
                )}
              </>
            )
          )
        )
      )}
    </div>
  );
};
