import { Button, Modal } from "antd";
import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Left from "../../../themes/assets/images/svg/leave_management/ChevronLeft.svg";
import Right from "../../../themes/assets/images/svg/leave_management/ChevronRight.svg";
import moment from "moment";
import "./MyActivityComponent.css";
import {
  ACTIVITY_COLOR,
  HOURS_TEXT,
  TimeSheetStrings,
} from "../../../constants";

const MyActivityNotSubmittedComponent = (props) => {
  const { startDate, endDate, notSubmittedWeek, status, created } = props;
  //   const colors = ACTIVITY_COLOR[status];
  return (
    <div className="activity-container">
      <div id="txt-activity-date-notsubmitted" className="txt-activity-date-notsubmitted">
        {notSubmittedWeek}
      </div>
      <div className="activity-row-view">
        <div id="txt-activity-working-hr" className="txt-activity-working-hr">
          {created}
        </div>
        <img src={Right} />
      </div>
      <div className="status-view" style={{ background: "#E8F1FB" }}>
        <div
          id="txt-activity-status"
          className="txt-activity-status"
          style={{ color: "#1976D2" }}
        >
          {status}
        </div>
      </div>
    </div>
  );
};

export default MyActivityNotSubmittedComponent;
