import { useState, useEffect } from "react";
import { Button, Modal, Radio, RadioChangeEvent, Spin } from "antd";
import "./modal.css";
import Calendar from "react-calendar";
import Left from "../../../themes/assets/images/svg/leave_management/ChevronLeft.svg";
import Right from "../../../themes/assets/images/svg/leave_management/ChevronRight.svg";
import SuccessImg from "../../../themes/assets/images/svg/leave_management/GreatNewsDrums.svg";
import warningIcon from "../../../themes/assets/images/svg/leave_management/WarningIcon.svg";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store/redux-hooks";
import moment from "moment";
import { getRequestingDays } from "../../../redux/actions/leavemanagement";
import { ComponentLevelValidate } from "../../utils/LMValidations";
import LeaveRequestErrorComponent from "./LeaveRequestErrorComponent";
import CancelIcon from "../../../themes/assets/images/Warning_Icon.png";
import { getFileIcon } from ".";

export const CustomPopupItemCard = ({
  onClick,
  title,
  image,
  description = "",
}) => {
  return (
    <div
      onClick={onClick}
      id="custom-modal-card-container"
      className="custom-modal-card-container"
    >
      <img height={24} width={24} src={image} alt="" />
      <div id="modal-card-title" className="modal-card-title">
        {title}
      </div>
      <div id="modal-card-title" className="modal-card-title">
        {description}
      </div>
    </div>
  );
};

export const SingleDatePopUp = (props) => {
  const [hasError, setHasError] = useState(false);
  const [calendarKey, setCalendarKey] = useState(0);

  const {
    leaveType,
    onClose,
    visible,
    onChange,
    expectedDeliveryDate,
    selectedDate,
    onDateSelect,
    validations,
    questionId,
  } = props || {};

  const { rule = "", errorMessage = {} } =
    validations?.find((item) => item?.questionId === questionId) || {};

  const isDateValid = (inputDate) => {
    if (!inputDate || !rule) {
      setHasError(false);
      return;
    }

    const birthDate = new Date(inputDate);
    const now = new Date();
    const [_, op, value, unit] =
      rule.match(/(>|<|>=|<=|==)\s*(\d+)\s*(M|Y|D)/) || [];

    if (isNaN(birthDate.getTime()) || !op || isNaN(Number(value))) {
      setHasError(true);
      return;
    }

    let diff = 0;

    if (unit === "Y") {
      diff = now.getFullYear() - birthDate.getFullYear();
      if (
        now.getMonth() < birthDate.getMonth() ||
        (now.getMonth() === birthDate.getMonth() &&
          now.getDate() < birthDate.getDate())
      ) {
        diff--;
      }
    } else if (unit === "M") {
      diff =
        (now.getFullYear() - birthDate.getFullYear()) * 12 +
        now.getMonth() -
        birthDate.getMonth();
      if (now.getDate() < birthDate.getDate()) {
        diff--;
      }
    } else if (unit === "D") {
      diff = Math.floor(
        (now.getTime() - birthDate.getTime()) / (1000 * 60 * 60 * 24)
      );
    } else {
      setHasError(true);
      return;
    }
    const comparisonValue = Number(value);
    const isError =
      (op === ">" && diff > comparisonValue) ||
      (op === "<" && diff < comparisonValue) ||
      (op === ">=" && diff >= comparisonValue) ||
      (op === "<=" && diff <= comparisonValue) ||
      (op === "==" && diff === comparisonValue);

    setHasError(isError);
  };

  useEffect(() => {
    if (selectedDate) {
      isDateValid(selectedDate);
    }
  }, [selectedDate]);

  useEffect(() => {
    setCalendarKey((prev) => prev + 1);
    setHasError(false);
  }, [leaveType]);

  const ValidationErrorComponent = () => {
    return hasError && errorMessage?.description ? (
      <div id="paternity-error-container" className="paternity-error-container">
        <span>
          <img src={warningIcon} alt="Warning" />
        </span>
        <span className="paternity-error-text">
          {errorMessage?.description}
        </span>
      </div>
    ) : null;
  };

  return (
    <Modal
      className="modal-container select-dates-modal"
      title="Select Dates"
      open={visible || false}
      onCancel={onClose || (() => {})}
    >
      <Calendar
        key={calendarKey}
        onChange={onChange || (() => {})}
        next2Label={<img src={Right || ""} alt="Next Year" />}
        nextLabel={<img src={Right || ""} alt="Next" />}
        prevLabel={<img src={Left || ""} alt="Previous" />}
        prev2Label={<img src={Left || ""} alt="Previous Year" />}
        className="singleDatePopUp-calendar"
        value={expectedDeliveryDate || null}
        formatShortWeekday={(locale, date) =>
          ["S", "M", "T", "W", "T", "F", "S"]?.[date?.getDay()] || ""
        }
        showNeighboringMonth={false}
      />
      <div id="select-dates-modal-footer" className="select-dates-modal-footer">
        <div
          id="select-dates-modal-footer-divider"
          className="select-dates-modal-footer-divider"
        ></div>
        {selectedDate && (
          <div
            id="select-dates-modal-dates-text"
            className="select-dates-modal-dates-text"
          >
            {selectedDate || ""}
          </div>
        )}
        <ValidationErrorComponent />
        <button
          disabled={!selectedDate || hasError}
          onClick={() => {
            if (onClose) onClose();
            if (onDateSelect) {
              onDateSelect(moment(expectedDeliveryDate).format("YYYY-MM-DD"));
            }
          }}
          id="select-dates-modal-button"
          className={
            selectedDate && !hasError
              ? "select-dates-modal-button"
              : "select-dates-modal-button-disabled"
          }
        >
          Select Dates
        </button>
      </div>
    </Modal>
  );
};

export const CalendarPopUp = (props: any) => {
  const {
    isVisible,
    onClose,
    url,
    leaveTypeId,
    onClick,
    questionId,
    validationCheck,
    selectedDates,
    validations,
    leaveDaysCount,
    editLeaveRequested,
  } = props;
  const [dates, setDates] = useState([]);
  const [selectedDatesVal, setSelectedDatesVal] = useState([]);
  const [markedDates, setMarkedDates] = useState(null);
  const [weekendDayArr, setWeekendDayArr] = useState({});
  const eventsData = props?.eventsData || [];
  const [calendarEvents, setCalendarEvents] = useState({});
  const [hasError, setHasError] = useState(false);
  const [daysCount, setDaysCount] = useState({});
  const [endpoint, setEndPoint] = useState(null);
  const [disabledArrowLeft, setDisabledArrowLeft] = useState(false);
  const [disabledArrowRight, setDisabledArrowRight] = useState(false);
  const [currentMonthYear, setCurrentMonthYear] = useState("");
  const [errorToast, setErrorToast] = useState("");
  const [calenderKey, setCalenderKey] = useState(0);

  const dispatch = useAppDispatch();
  const { requestingdays, requestingdaysLoading } = useAppSelector(
    (state: any) => state.leavemanagement
  );

  function onClickSelectDates() {
    // if (dates) {
    //   setDates([]);
    //   onClose();
    //   onClick();
    // }`
    onClose();
    setSelectedDatesVal(dates);
    editLeaveRequested(requestingdays?.result?.requesting);
  }

  useEffect(() => {
    if (endpoint && dates) {
      dispatch(
        getRequestingDays({
          url: url,
          startDate: dates && moment(dates[0]).format("YYYY-MM-DD"),
          leaveTypeId: leaveTypeId,
          endDate: dates && moment(dates[1]).format("YYYY-MM-DD"),
        })
      );
      setDaysCount(requestingdays);
      if (props?.validationCheck && requestingdays) {
        let validation = ComponentLevelValidate(
          requestingdays?.result?.requesting,
          props?.questionId,
          requestingdays?.result?.requesting,
          props?.validationCheck
        );
        setErrorToast(validation?.errMsg);
      }
    }
  }, [dates]);

  useEffect(() => {
    setDates([]);
    setSelectedDatesVal([]);
    setCalenderKey((prevKey) => prevKey + 1);
  }, [leaveTypeId]);

  useEffect(() => {
    if (!requestingdaysLoading && selectedDatesVal?.length > 0) {
      selectedDates(
        selectedDatesVal[0],
        selectedDatesVal[1],
        requestingdays,
        leaveTypeId
      );
    }
  }, [selectedDatesVal, requestingdays, requestingdaysLoading]);
  useEffect(() => {}, [hasError, requestingdays]);
  const ValidationErrorComponent = () => {
    const compareValues = (left, operator, right) => {
      switch (operator) {
        case ">":
          return left > right;
        case "<":
          return left < right;
        case ">=":
          return left >= right;
        case "<=":
          return left <= right;
        case "==":
          return left == right;
        case "===":
          return left === right;
        case "!=":
          return left != right;
        default:
          return false;
      }
    };

    const updatedLeaveDayCount =
      parseInt(
        requestingdays?.result?.requesting?.toString().match(/\d+/)?.[0],
        10
      ) || 0;

    const questionValidations = validations?.filter(
      (validation) => validation?.questionId === questionId
    );
    const conflictingRules = questionValidations?.filter(
      (validation) => validation?.type === "NUMBER_CHECK"
    );

    let displayedError = null;

    if (conflictingRules?.length > 1) {
      const greaterRule = conflictingRules.find((rule) =>
        rule.rule.includes(">")
      );
      const lessRule = conflictingRules.find((rule) => rule.rule.includes("<"));

      const greaterCount = parseInt(greaterRule?.rule.match(/\d+/)?.[0], 10);
      const lessCount = parseInt(lessRule?.rule.match(/\d+/)?.[0], 10);

      if (updatedLeaveDayCount < greaterCount) {
        displayedError = lessRule;
      } else if (updatedLeaveDayCount > lessCount) {
        displayedError = greaterRule;
      }
    }

    const validationErrors = questionValidations?.map((validation, index) => {
      const ruleMatch = validation?.rule.match(/(>=|<=|===|==|!=|>|<)/);
      const operator = ruleMatch?.[0];
      const ruleCount = parseInt(validation?.rule.match(/\d+/)?.[0], 10);

      if (operator && ruleCount !== undefined) {
        const error = compareValues(updatedLeaveDayCount, operator, ruleCount);
        if (
          validation === displayedError ||
          (conflictingRules.length <= 1 && error)
        ) {
          setHasError(true);
          return (
            <div
              key={index}
              id="paternity-error-container"
              className="paternity-error-container"
            >
              <span>
                <img src={warningIcon} alt="Warning" />
              </span>
              <div className="paternity-error-text-container">
                <span className="paternity-error-title">
                  {validation?.errorMessage?.title}
                </span>
                <span className="paternity-error-text">
                  {validation?.errorMessage?.description}
                </span>
              </div>
            </div>
          );
        }
      }
      return null;
    });
    useEffect(() => {
      if (!validationErrors?.some((error) => error !== null)) {
        setHasError(false);
      }
    }, [validationErrors]);

    return <>{validationErrors}</>;
  };

  return (
    <Modal
      className="modal-container select-dates-modal"
      title="Select Dates"
      open={isVisible}
      onCancel={() => {
        setDates([]);
        setSelectedDatesVal([]);
        setCalenderKey((prevKey) => prevKey + 1);
        onClose(calenderKey);
      }}
    >
      <Calendar
        key={calenderKey}
        maxDetail="month"
        selectRange
        minDetail="month"
        next2Label={null}
        prev2Label={null}
        nextLabel={<img src={Right} />}
        prevLabel={<img src={Left} />}
        className="select-range-calendar"
        formatShortWeekday={(locale, date) =>
          ["S", "M", "T", "W", "T", "F", "S"][date.getDay()]
        }
        showNeighboringMonth={false}
        onChange={(val: any) => {
          setDates(val);
          dispatch(
            getRequestingDays({
              url: url,
              startDate: val && moment(val[0]).format("YYYY-MM-DD"),
              leaveTypeId: leaveTypeId,
              endDate: val && moment(val[1]).format("YYYY-MM-DD"),
            })
          );
        }}
      />

      <ValidationErrorComponent />
      <div id="select-dates-modal-footer" className="select-dates-modal-footer">
        <div
          id="select-dates-modal-footer-divider"
          className="select-dates-modal-footer-divider"
        ></div>
        {dates.length != 0 && (
          <div
            id="select-dates-modal-dates-text"
            className="select-dates-modal-dates-text"
          >
            {`${moment(dates[0]).format("DD MMM YYYY")} - 
            ${moment(dates[1]).format("DD MMM YYYY")} `}
            {requestingdaysLoading === false && (
              <span
                id="select-dates-modal-no-of-dates"
                className="select-dates-modal-no-of-dates"
              >
                {`(${requestingdays?.result?.requesting})`}
              </span>
            )}
          </div>
        )}

        {requestingdaysLoading ? (
          <div
            className="select-dates-modal-button"
            style={{ backgroundColor: "#0642B5" }}
          >
            <Spin spinning={requestingdaysLoading} />
          </div>
        ) : (
          <button
            disabled={
              hasError ||
              dates.length === 0 ||
              requestingdays?.result?.requesting === "0 days"
            }
            onClick={onClickSelectDates}
            id="select-dates-modal-button"
            className={
              hasError ||
              dates?.length === 0 ||
              requestingdays?.result?.requesting === "0 days" ||
              (leaveTypeId === "PA_CAN" &&
                Number(requestingdays?.result?.requesting.match(/\d+/g)?.[0]) >
                  5)
                ? "select-dates-modal-button-disabled"
                : "select-dates-modal-button"
            }
          >
            Select Dates
          </button>
        )}
      </div>
    </Modal>
  );
};

export const DropDownPopup = (props: any) => {
  const { isVisible, onClose, OnClickSelectedCard, title, validation } = props;
  const {
    dynamicApiData,
    reasonLeavedataLoading,
    leavesTypesDataLoading,
    leaveTypesData,
    reasonLeavedata,
  } = useAppSelector((state: any) => state.leavemanagement);

  const loading =
    title === "Leave Type" ? leavesTypesDataLoading : reasonLeavedataLoading;
  const data = title === "Leave Type" ? leaveTypesData : reasonLeavedata;

  return (
    <Modal
      title={title}
      open={isVisible}
      onCancel={onClose}
      maskTransitionName="leave-type-bg"
      className={`${
        title === "Leave Type" || title === "Select Floater"
          ? "modal-container leave-type-modal"
          : "modal-container reason-type-modal"
      }`}
    >
      <div
        className={`${
          title === "Leave Type" || title === "Select Floater"
            ? "modal-body-container leave-type-body-container"
            : "modal-body-container reason-type-body-container"
        }`}
      >
        {loading === false &&
          data?.map((item: any) => (
            <CustomPopupItemCard
              title={
                title === "Leave Type"
                  ? item?.displayName
                  : item?.remarks?.title
              }
              image={item?.value}
              description={item?.remarks?.description}
              onClick={() => OnClickSelectedCard(item)}
            />
          ))}
      </div>
      {validation ? (
        <div className="leave-type-error-wrapper">
          <LeaveRequestErrorComponent
            errorTitle={validation.title}
            errorDescription={validation.description}
          />
        </div>
      ) : null}
    </Modal>
  );
};

export const PreviewPopup = (props: any) => {
  const { previewData = {}, requestingdays } = props;
  const { uploadedFile } = useAppSelector(
    (state: any) => state.leavemanagement
  );
  const {
    leaveType = {},
    FLDSF_RV = {},
    questionListArray = [],
    hideContentView = false,
    showFloater = false,
  } = previewData || {};
  const checkHolidayValues = props?.checkedHolidays
    ? Object.values(props?.checkedHolidays)
    : [];
  let checkVal = checkHolidayValues?.map(({ checked }: any) => {
    return checked;
  });

  const DISPLAY_TYPES = {
    FLOATER_HOLIDAY: "Floater",
  };
  const displayLeaveType = DISPLAY_TYPES?.[leaveType?.leaveTypeName] || "";
  const isFloater = displayLeaveType === DISPLAY_TYPES.FLOATER_HOLIDAY;
  const showQuestions = ["FLDSF_RV", "FH_IND_CD"];
  const holiday = questionListArray.find((item) =>
    showQuestions?.includes(item?.questionId)
  );
  const holidayDate = props?.previewData?.quickApply
    ? props?.previewData?.startDate
    : holiday?.answerList?.split(",")?.[0] ||
      props?.previewData?.startDate ||
      "";

  const title = props?.previewData?.leaveApprovalStatus ? (
    <div className="modal-container-titlebar">
      <span>Preview Leave</span>
      <span
        className={`modal-container-status-${props?.previewData?.leaveApprovalStatus}`}
      >
        {
          props?.previewData?.workflowSteps?.displayProperties
            ?.displayNameForEmployee
        }
      </span>
    </div>
  ) : (
    "Preview Leave"
  );

  return (
    <Modal
      title={title}
      open={props?.isVisible}
      onCancel={props?.clickOnCloseIcon}
      className={"modal-container preview-modal"}
    >
      <div id="main-view" className="main-view">
        {showFloater && (
          <div id="qa-floater-left" className="quick-apply-floater-left">
            <span className="quick-apply-floater-left-img">
              <img src={warningIcon} alt="Warning" />
            </span>
            <span className="quick-apply-floater-left-txt">
              All available floaters have been utilised. No pending floaters
              left.
            </span>
          </div>
        )}
        <div id="date-range-view" className="date-range-view">
          <div id="date-range-view-sub" className="date-range-view-sub">
            <div id="left-column-view" className="left-column-view">
              <div id="left-title-text" className="left-title-text">
                From
              </div>
              <div id="left-date-title-text" className="left-date-title-text">
                {moment(props?.previewData?.startDate, "YYYY-MM-DD").format(
                  "DD MMM YYYY"
                )}
              </div>
            </div>

            <div id="middle-view" className="middle-view">
              <div id="middle-view-divider" className="middle-view-divider" />
              <div id="middle-days-view" className="middle-days-view">
                <div id="middle-days-text" className="middle-days-text">
                  {props?.previewData?.displayRequesting}
                </div>
              </div>
              <div className="middle-view-divider" />
            </div>

            <div className="left-column-view">
              <div className="left-title-text">To</div>
              <div id="left-date-title-text" className="left-date-title-text">
                {moment(props?.previewData?.endDate, "YYYY-MM-DD").format(
                  "DD MMM YYYY"
                )}
              </div>
            </div>
          </div>
          {checkHolidayValues?.length
            ? checkHolidayValues.map(
                ({ displayName, holidayDate, checked }: any) =>
                  checked ? (
                    <div className="selected-floater-type">
                      <div className="selected-floater-type-sub">
                        <span className="selected-floater-type-day">
                          {displayName}
                        </span>
                        <span className="selected-floater-type-date">
                          {moment(holidayDate, "YYYY-MM-DD").format(
                            "DD MMM YYYY"
                          )}
                        </span>
                      </div>
                      <div className="selected-floater-type-btn">
                        <div className="selected-floater-type-txt">
                          {"Floater Holiday"}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
              )
            : ""}
          {props?.previewCommonObjData?.leaveTypeId === "FH_IND" ||
          props?.previewData?.leaveTypeId === "FH_IND" ? (
            <div className="selected-floater-type">
              <div className="selected-floater-type-sub">
                <span className="selected-floater-type-day">
                  {props?.previewData?.quickApply
                    ? props?.previewData?.displayName
                    : holiday?.answer}
                </span>
                <span className="selected-floater-type-date">
                  {moment(holidayDate, "YYYY-MM-DD").format("DD MMM YYYY")}
                </span>
              </div>
              <div className="selected-floater-type-btn">
                <div className="selected-floater-type-txt">
                  {displayLeaveType || "Floater Holiday"}
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {props?.previewData?.quickApply ? null : (
          <div id="content-view" className="content-view">
            <div
              id="left-column-view-content"
              className="left-column-view-content"
            >
              <div className="left-title-text">{"Leave Type"}</div>
              <div id="content-description" className="content-description">
                {checkHolidayValues?.length > 0 &&
                requestingdays?.isFloaterHolidays?.length > 0 &&
                checkVal?.[0]
                  ? `${props?.previewData?.leaveTypeDisplayName}, Floater Holiday`
                  : props?.previewData?.leaveTypeDisplayName || ""}
              </div>
            </div>
            {props?.previewData?.questionListArray?.map((item, index) => {
              if (item?.questionId === "FLDSF_RV") {
                return;
              }
              return (
                <>
                  {item?.type === "UPLOAD" ? (
                    <div className="left-column-view-content">
                      {item?.answerList?.fileName || item?.answer?.fileName ? (
                        <>
                          <div className="left-title-text">
                            {item?.question}
                          </div>
                          <div className="after-attachment-sub">
                            <div className="attachment-file-icon-div">
                              <img
                                className="attachment-file-icon"
                                src={getFileIcon(uploadedFile?.type)}
                                alt="file"
                              />
                            </div>
                            <div className="attached-file-main">
                              <div className="attached-file-name">
                                {uploadedFile?.fileName}
                              </div>
                              <div className="attached-file-size">
                                {uploadedFile?.size}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : item?.type !== "DATE" &&
                    item?.type !== "TEXT" &&
                    item?.type !== "DYNAMIC" &&
                    item?.answer !== null &&
                    item?.answer !== "" ? (
                    <div className="left-column-view-content">
                      <div className="left-title-text">{item.question}</div>
                      <div className="content-description">
                        {" "}
                        {item?.type == "SINGLE_DATE"
                          ? moment(item.answer).format("DD MMM YYYY")
                          : item.answer}
                      </div>
                    </div>
                  ) : null}
                </>
              );
            })}
          </div>
        )}

        <div id="buttons-row-view" className="buttons-row-view">
          {/* <div className="button-row-right-view"> */}
          {props?.previewData?.leaveApprovalStatus !== "REJECTED" ? (
            props?.previewData?.leaveApprovalStatus ? (
              props?.previewData?.editable ? (
                <div
                  className="cancel-button-view"
                  onClick={() => props?.clickOnClose()}
                >
                  <div className="text-cancel">Cancel Leave</div>
                </div>
              ) : (
                <div className="psuedo_button"></div>
              )
            ) : (
              <div
                className="cancel-button-view"
                onClick={() => props?.clickOnCloseIcon()}
              >
                <div className="text-cancel">Cancel</div>
              </div>
            )
          ) : (
            <div className="psuedo_button"></div>
          )}
            {props?.previewData?.leaveApprovalStatus ? (
              props?.previewData?.editable &&
              props?.previewData?.leaveApprovalStatus !== "REJECTED" ? (
                <div
                  id="cancel-button-view"
                  className={`cancel-button-view button-row-right-view-margin text-submit-view ${
                    showFloater ? "disabled" : ""
                  }`}
                  onClick={props?.editable}
                >
                  <div
                    id="text-submit"
                    className="text-submit"
                   
                  >
                    Edit
                  </div>
                </div>
              ) : (
                <div
                  id="cancel-button-view"
                  className={`cancel-button-view button-row-right-view-margin text-submit-view ${
                    showFloater ? "disabled" : ""
                  }`}
                  onClick={() => props?.clickOnCloseIcon()}
                >
                  <div
                    id="text-submit"
                    className="text-submit"
                   
                  >
                    Got it
                  </div>
                </div>
              )
            ) : (
              <div
                id="cancel-button-view"
                className={`cancel-button-view button-row-right-view-margin text-submit-view ${
                  showFloater ? "disabled" : ""
                }`}
                onClick={() => {
                  if (!showFloater) {
                    props?.clickOnSubmit();
                  }
                }}
              >
                <div
                  id="text-submit"
                  className="text-submit"
                 
                >
                  Submit
                </div>
              </div>
            )}
          </div>
          {/* </div> */}
        </div>
    </Modal>
  );
};

const SENSITIVE_LEAVE_TYPES = [
  "BRVT_IND",
  "MS_IND",
  "STD_USA",
  "FMLA_USA",
  "BRVMNT_USA",
  "BL_CAN",
  "SL_CAN",
  "STD_CAN",
  "GD_URY",
  "SL_URY",
  "PAPME_URY",
  "SL_POL",
  "SL_MEX",
  "SLIMSS_MEX",
  "FNL_MEX",
  "FNLR_MEX",
];
export const GamificationSuccessPopup = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const { submitLeaveObj, leaveType } = props;

  return (
    <>
      <Modal
        open={props?.isVisible}
        // onOk={handleOk}
        onCancel={props?.handleClose}
        className={"modal-success-container modal-success-preview"}
        centered
      >
        {submitLeaveObj?.thresholdPopup || submitLeaveObj?.intervalPopup ? (
          <div className="success-popup-right-top">
            +{`${submitLeaveObj?.points}`}pts
          </div>
        ) : null}

        <div className="success-modal-middle-content">
          {/* {!SENSITIVE_LEAVE_TYPES.includes(leaveType) ? (
            <img src={SuccessImg} alt="drums" />
          ) : null} */}
          <img src={submitLeaveObj?.image} className="drum_image" />
          <p className="success-modal-text">{submitLeaveObj?.title}</p>
          <p className="success-modal-text1">
            {submitLeaveObj?.genericMessage}
          </p>
        </div>
        <div className="success-popup-btn" onClick={props?.handleClose}>
          <div className="text-close-btn">Continue</div>
        </div>
      </Modal>
    </>
  );
};

export const RelationsPopup = (props: any) => {
  const { isVisible, onClose, OnClickSelectedCard, title } = props;
  const { relationLeaveDataLoading, relationLeaveData } = useAppSelector(
    (state: any) => state.leavemanagement
  );

  const onChange = (e: RadioChangeEvent) => {
    OnClickSelectedCard(e.target.value);
  };
  return (
    <Modal
      title={title}
      open={isVisible}
      onCancel={onClose}
      className="modal-container relations-modal"
    >
      <Radio.Group
        className="relations-container"
        onChange={onChange}
        // value={selectedValue}
      >
        {!relationLeaveDataLoading &&
          relationLeaveData?.map((item: any) => (
            <Radio value={item} className="relation custom-radio">
              {item?.remarks?.title}
            </Radio>
          ))}
      </Radio.Group>
    </Modal>
  );
};

export const CancelLeaveModal = (props: any) => {
  return (
    <Modal
      className="cancel-leave-modal-main"
      open={props?.openCancel}
      onCancel={props?.closeCancel}
      footer={false}
      centered={true}
    >
      <div className="cancel-leave-modal">
        <img className="cancel-leave-image" src={CancelIcon} alt="" />
        <div className="cancel-leave-title">Cancel Leave?</div>
        <div className="cancel-leave-content">
          Are you sure you want to cancel this leave?
        </div>
        <div className="cancel-leave-buttons">
          <div
            className="cancel-leave-back-button"
            onClick={props?.closeCancel}
          >
            Back
          </div>
          <div
            className="cancel-leave-confirm"
            onClick={props?.closeCancelConfirm}
          >
            Confirm
          </div>
        </div>
      </div>
    </Modal>
  );
};
