import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  DatePicker,
  Space,
  Select,
  SelectProps,
  Input,
  Checkbox,
} from "antd";
import { useAppSelector, useAppDispatch } from "../../redux/store/redux-hooks";
import exportclose from "../../themes/assets/images/svg/ExportClose.svg";
import downloadicon from "../../themes/assets/images/svg/Downloadicon.svg";
import exportcalendericon from "../../themes/assets/images/svg/ExportCalendarBlank.svg";
import "./ManagerExport.css";
import { SearchOutlined } from "@ant-design/icons";
import { searchProjectAdmin } from "../../redux/actions/timesheet";
import DownArrow from "../../themes/assets/images/ChevronDown.svg";
import { debounce } from "lodash";

const AdminEmployeeDownload = (props) => {
  const dispatch = useAppDispatch();
  const { searchAdminProjectData } = useAppSelector(
    (state: any) => state.timesheet
  );
  const searchedAdminProjectData = searchAdminProjectData?.data?.projectNames;
  const { RangePicker } = DatePicker;
  const isRangeSelected = props?.dates[0] && props?.dates[1];
  const [projectDropdown, setProjectDropdown] = useState(false);
  interface ItemProps {
    label: string;
    value: string;
  }

  const toggleOptions = () => {
    setProjectDropdown((prevState) => !prevState);
  };

  const handleClickOutside = (e) => {
    if (e.target.closest(".select-project-options-container") === null) {
      setProjectDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getProjectSearchVal = (searchKey) => {
    dispatch(searchProjectAdmin(searchKey));
  };
  const debounceProjectValue = debounce(getProjectSearchVal, 1000);

  const options: ItemProps[] = [];

  for (let index = 0; index < props?.projectList?.length; index++) {
    const value = props?.projectList[index];
    options.push({
      label: value,
      value,
    });
  }

  const handleCheckboxChange = (item: string) => {
    props?.selectDeselectOption(item);
  };

  return (
    <div>
      <Button
        onClick={props?.showModal}
        className="export-button manager_export-btn admin_export-btn"
      >
        <img
          src={downloadicon}
          alt="Download Icon"
          className="export-button-icon"
        />
        Export Excel
      </Button>

      <Modal
        title={props?.title}
        className="exporttimesheet_modal admin-export-data"
        open={props?.isModalVisible}
        onCancel={() => {
          props?.handleCancel();
          props?.form.resetFields();
        }}
        closeIcon={
          <img
            src={exportclose}
            alt="Close Icon"
            className="custom-close-icon"
          />
        }
        footer={[
          <div className="export-footer-button">
            <div className="footer-download-button">
              <Button
                key="download"
                onClick={props?.clickOnDownload}
                disabled={
                  !props?.isDownloadEnabled &&
                  props?.selectedProjectList?.length > 0
                }
                style={{
                  backgroundColor:
                    props?.isDownloadEnabled &&
                    props?.selectedProjectList?.length > 0
                      ? "#0642B5"
                      : "#E2E2E2",
                  color:
                    props?.isDownloadEnabled &&
                    props?.selectedProjectList?.length > 0
                      ? "#FFFFFF"
                      : "#858585",
                }}
              >
                Download
              </Button>
            </div>
            <div className="footer-email-button">
              <Button
                key="email"
                onClick={props?.clickOnEmail}
                disabled={
                  !props?.isDownloadEnabled &&
                  props?.selectedProjectList?.length > 0
                }
                style={{
                  backgroundColor:
                    props?.isDownloadEnabled &&
                    props?.selectedProjectList?.length > 0
                      ? "#0642B5"
                      : "#E2E2E2",
                  color:
                    props?.isDownloadEnabled &&
                    props?.selectedProjectList?.length > 0
                      ? "#FFFFFF"
                      : "#858585",
                }}
              >
                E-mail
              </Button>
            </div>
          </div>,
        ]}
      >
        <div className="modal-subtext" id="modal-subtext">
          Timesheet will export based on the selected date.
        </div>

        <Form
          form={props?.form}
          layout="vertical"
          className="export-popup-form-otr"
        >
          <Form.Item
            label={
              <div className="start-date-label" id="start-date-label">
                Start & End Date
              </div>
            }
            name="startEndDate"
            rules={[{ required: true, message: "Start Date is required" }]}
            className="export-form-field_otr"
          >
            <Space direction="vertical" size={8} className="range_sec-otr">
              <RangePicker
                className="custom-input"
                picker="date"
                format={"DD/MM/YYYY"}
                allowClear
                popupClassName="custom-input-popup"
                placeholder={
                  isRangeSelected ? ["Start Date", "End Date"] : ["Select", ""]
                }
                separator={isRangeSelected ? "-" : ""}
                onChange={props?.onDateChange} // Update dates when range is selected
                suffixIcon={
                  <img
                    src={exportcalendericon}
                    alt="Calendar Icon"
                    className="Calender-icon"
                  />
                }
              />
            </Space>
          </Form.Item>
          <Form.Item
            label="Project"
            name="Project"
            className="export-form-field_otr"
            rules={[{ required: true, message: "Project is required" }]}
          >
            <div className="select-project-options-container">
              <div className="selected_value-otr" onClick={toggleOptions}>
                {props?.selectedProjectList?.map((item, index) => {
                  return (
                    <>
                      {index <= 2 ? (
                        <div className="selected_value">
                          {item}{" "}
                          <img
                            src={exportclose}
                            onClick={() => props?.handleRemoveSelect(item)}
                          />
                        </div>
                      ) : index === 3 ? (
                        <div className="selected_value select_overflow_item">
                          {`+${props?.selectedProjectList?.length - 3} more`}
                        </div>
                      ) : null}
                    </>
                  );
                })}
                {props?.selectedProjectList?.length === 0 && (
                  <div className="select-placeholder-project-select-container">
                    <div className="select-placeholder-project-select">
                      Select
                    </div>
                    <img src={DownArrow} alt="" />
                  </div>
                )}
              </div>
              {projectDropdown && (
                <div className="select-project-options">
                  <div className="export_custom-fields">
                    <div
                      className="export-search-field"
                      style={{ padding: "8px" }}
                    >
                      <Input
                        placeholder="Search"
                        prefix={<SearchOutlined />}
                        onChange={(e) => debounceProjectValue(e?.target?.value)}
                      />
                    </div>
                    <span className="search-txt">Search Result</span>
                    <div className="main-projects-container">
                      {searchedAdminProjectData?.map((item) => (
                        <label className="select-project-individual" key={item}>
                          <input
                            className="checkbox-input-project-search"
                            type="checkbox"
                            value={searchedAdminProjectData}
                            checked={props?.selectedProjectList?.includes(item)}
                            onChange={() => handleCheckboxChange(item)}
                          />
                          {item}
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AdminEmployeeDownload;
