import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useState } from "react";
import { getLoggedInUserData } from "../../../utils/util";
import AvatarProfile from "../../../Avatar/AvatarProfile";

const DelegatePreviewPopup = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userData = getLoggedInUserData();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const title = (
    <div className="modal-container-titlebar">
      <span>Preview Leave</span>
      <div
        className={`timesheet_status_lm ${props?.previewData?.workflowSteps?.displayProperties?.displayNameForEmployee}`}
      >
        {
          props?.previewData?.workflowSteps?.displayProperties
            ?.displayNameForEmployee
        }
      </div>
    </div>
  );
  return (
    <Modal
      title={title}
      open={props?.isModalOpen}
      onCancel={props?.handleCancel}
      className={"modal-container preview-modal delegate_prev-otr"}
    >
      <div id="main-view_lm" className="main-view">
        <div className="manager_tsm_container_lm">
          <AvatarProfile
            name={props?.previewData?.teamUserInfo?.displayName}
            profilePic={
              props?.previewData?.teamUserInfo?.mediaResponse?.referenceUrl
            }
          />
          <div className="manager_tsm_titles_lm">
            <div className="timesheet_manager_titles_lm">
              {props?.previewData?.teamUserInfo?.displayName}
            </div>
            <div className="timesheet_regular_lm">
              {props?.previewData?.teamUserInfo?.desgination}
            </div>
          </div>
        </div>
        <div id="date-range-view_lm" className="date-range-view">
          <div id="date-range-view-sub_lm" className="date-range-view-sub">
            <div id="left-column-view_lm" className="left-column-view">
              <div id="left-title-text_lm" className="left-title-text">
                From
              </div>
              <div
                id="left-date-title-text_lm"
                className="left-date-title-text"
              >
                {userData?.displayName}
              </div>
            </div>

            <div id="middle-view_lm" className="middle-view">
              <div
                id="middle-view-divider_lm"
                className="middle-view-divider"
              />
              <div id="middle-days-view_lm" className="middle-days-view">
                <div id="middle-days-text_lm" className="middle-days-text">
                  {"Delegate"}
                </div>
              </div>
              <div className="middle-view-divider" />
            </div>

            <div className="left-column-view">
              <div className="left-title-text">To</div>
              <div
                id="left-date-title-text_lm"
                className="left-date-title-text"
              >
                {props?.selectedManager?.displayName}
              </div>
            </div>
          </div>
        </div>
        <div className="preview_buttons_lm">
          <div className="preview_conform-btns">
            <button className="add_manager_lm" onClick={props?.handleCancel}>
              {"Cancel"}
            </button>
            <button
              className="leave_approve"
              onClick={() => {
                props?.handleCancel();
                props?.clickonApproveReject("DELEGATE", "", props?.previewData);
              }}
            >
              {"Confirm"}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default DelegatePreviewPopup;
