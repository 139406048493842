import { Button, Modal, Radio, RadioChangeEvent, Spin } from "antd";
import moment from "moment";
import "./PreviewPopup.css";
import {
  APPROVE_TIMESHEET,
  DELEGATE_LEAVE,
  REJECT_TIMESHEET,
} from "../../../../constants";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import ExcelAttachmentIcon from "../../../../themes/assets/images/svg/leave_management/excel.png";
import PdfAttachmentIcon from "../../../../themes/assets/images/svg/leave_management/PdfIcon.svg";
import PptAttachmentIcon from "../../../../themes/assets/images/svg/leave_management/ppt.png";
import DocAttachmentIcon from "../../../../themes/assets/images/svg/leave_management/doc.png";
import ImageAttachmentIcon from "../../../../themes/assets/images/svg/leave_management/jpg-png-gif.png";
import FileAttachmentIcon from "../../../../themes/assets/images/svg/leave_management/FileAttachmentIcon.svg";
import DelegateIcon from "../../../../themes/assets/images/svg/leave_management/delegate-icon.svg";
import DelegateLeavePopup from "../DelegateLeavePopup";
import AvatarProfile from "../../../Avatar/AvatarProfile";

const PreviewPopup = (props) => {
  const { previewData, clickonApproveReject, clickOnReject } = props;
  const [comment, setComment] = useState("");
  const title = (
    <div className="modal-container-titlebar">
      <span>Preview Leave</span>
      <span
        className={`modal-container-status-${previewData?.leaveApprovalStatus}`}
      >
        {previewData?.workflowSteps?.displayProperties?.displayNameForEmployee}
      </span>
    </div>
  );
  const getFileIcon = (type: any) => {
    if (type?.[0]?.includes("pdf")) return PdfAttachmentIcon;
    if (type?.[0]?.includes("text/plain")) return FileAttachmentIcon;
    if (type?.[0]?.includes("spreadsheet")) return ExcelAttachmentIcon;
    if (type?.[0]?.includes("presentation")) return PptAttachmentIcon;
    if (
      ["image/jpeg", "image/jpg", "image/png", "image/gif"]?.includes(type?.[0])
    )
      return ImageAttachmentIcon;
    if (
      type?.[0]?.includes(".document") ||
      type?.[0]?.includes("application/ms-word")
    )
      return DocAttachmentIcon;
    return "";
  };

  // const openAttachmentFile = (data) => {
  //   <a href={}
  //    onclick="window.open(this.href); return false;"
  //    onkeypress="window.open(this.href); return false;">
  //      This link will open in new window/tab
  //   </a>
  // };

  return (
    <Modal
      title={title}
      open={props?.visiblePopup}
      onCancel={props?.clickOnCloseIcon}
      className={"modal-container preview-modal"}
    >
      <div id="main-view_lm" className="main-view">
        <div className="manager_tsm_container_lm">
          <AvatarProfile
            name={props?.previewData?.teamUserInfo?.displayName}
            profilePic={
              props?.previewData?.teamUserInfo?.mediaResponse?.referenceUrl
            }
          />
          <div className="manager_tsm_titles_lm">
            <div className="timesheet_manager_titles_lm">
              {props?.previewData?.teamUserInfo?.displayName}
            </div>
            <div className="timesheet_regular_lm">
              {props?.previewData?.teamUserInfo?.desgination}
            </div>
          </div>
        </div>
        <div id="date-range-view_lm" className="date-range-view">
          <div id="date-range-view-sub_lm" className="date-range-view-sub">
            <div id="left-column-view_lm" className="left-column-view">
              <div id="left-title-text_lm" className="left-title-text">
                From
              </div>
              <div
                id="left-date-title-text_lm"
                className="left-date-title-text"
              >
                {moment(props?.previewData?.startDate, "YYYY-MM-DD").format(
                  "DD MMM YYYY"
                )}
              </div>
            </div>

            <div id="middle-view_lm" className="middle-view">
              <div
                id="middle-view-divider_lm"
                className="middle-view-divider"
              />
              <div id="middle-days-view_lm" className="middle-days-view">
                <div id="middle-days-text_lm" className="middle-days-text">
                  {props?.previewData?.displayRequesting}
                </div>
              </div>
              <div className="middle-view-divider" />
            </div>

            <div className="left-column-view">
              <div className="left-title-text">To</div>
              <div
                id="left-date-title-text_lm"
                className="left-date-title-text"
              >
                {moment(props?.previewData?.endDate, "YYYY-MM-DD").format(
                  "DD MMM YYYY"
                )}
              </div>
            </div>
          </div>
        </div>
        {props?.previewData?.quickApply ? null : (
          <div id="content-view_lm" className="content-view">
            <div
              id="left-column-view-content_lm"
              className="left-column-view-content"
            >
              <div className="left-title-text">{"Leave Type"}</div>
              <div id="content-description_lm" className="content-description">
                {props?.previewData?.leaveTypeDisplayName || ""}
              </div>
            </div>
            {previewData?.questionListArray?.map((item, index) => {
              if (item?.questionId === "FLDSF_RV") {
                return;
              }
              return (
                <>
                  {item?.type === "UPLOAD" ? (
                    <div
                      className="left-column-view-content_lm"
                      onClick={() =>
                        window.open(
                          "data:application/octet-stream;base64," +
                            item?.answer?.fileContent,
                          "_blank"
                        )
                      }
                    >
                      {item?.answer?.fileName ? (
                        <>
                          <div className="left-title-text">
                            {item?.question}
                          </div>
                          <div className="after-attachment-sub">
                            <div className="attachment-file-icon-div">
                              <img
                                className="attachment-file-icon"
                                src={getFileIcon([
                                  item?.answer?.mimeType || item?.answer?.type,
                                ])}
                                alt="file"
                              />
                            </div>
                            <div className="attached-file-main">
                              <div className="attached-file-name">
                                {item?.answer?.fileName}
                              </div>
                              <div className="attached-file-size">
                                {`${(
                                  item?.answer?.fileSize /
                                  (1024 * 1024)
                                ).toFixed(2)} MB`}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : item?.type !== "DATE" &&
                    item?.type !== "TEXT" &&
                    item?.type !== "DYNAMIC" &&
                    item?.answer !== null &&
                    item?.answer !== "" ? (
                    <div className="left-column-view-content">
                      <div className="left-title-text">{item.question}</div>
                      <div className="content-description">
                        {" "}
                        {item?.type == "SINGLE_DATE"
                          ? moment(item.answer).format("DD MMM YYYY")
                          : item.answer}
                      </div>
                    </div>
                  ) : null}
                </>
              );
            })}
          </div>
        )}
        <div>
          {previewData?.workflowSteps?.displayProperties
            ?.displayNameForEmployee === "Pending" ||
          previewData?.workflowSteps?.displayProperties
            ?.displayNameForEmployee === "Pending Cancellation" ? (
            <div className="preview_buttons_lm">
              <div className="preview_delegate-member">
                <button
                  className="delegate_manager_leave add_manager_lm"
                  onClick={props?.handleClick}
                >
                  <img src={DelegateIcon} alt="delegateicon" /> {DELEGATE_LEAVE}
                </button>
              </div>
              <div className="preview_conform-btns">
                <button
                  className="add_manager_lm"
                  onClick={() => clickOnReject()}
                >
                  <CloseOutlined /> {REJECT_TIMESHEET}
                </button>
                <button
                  className="leave_approve"
                  onClick={() =>
                    clickonApproveReject("APPROVE", comment, previewData)
                  }
                >
                  <CheckOutlined /> {APPROVE_TIMESHEET}
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

export default PreviewPopup;
