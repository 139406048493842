import { useAppSelector, useAppDispatch } from "../../redux/store/redux-hooks";
import { START } from "../../constants";
import n from "../navigation/Route.Names";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./AdminPanel.css";
import BreadcrumbComponent from "../common/breadcrumb/breadcrumb";
import { useCallback, useEffect, useState, useMemo } from "react";
import {
  Input,
  Switch,
  Select,
  Table,
  Modal,
  TableProps,
  Tabs,
  TabsProps,
  Spin,
  Space,
  Button,
  Form,
  DatePicker,
} from "antd";
import ToastMessage from "../common/ToastMsg/toastMessage";
import ToastClose from "../../themes/assets/images/performance/svg/ToastClose.svg";
import ToastInfo from "../../themes/assets/images/performance/svg/ToastInfo.svg";
import ToastError from "../../themes/assets/images/performance/svg/ToastError.svg";
import AdminProfileOne from "../../themes/assets/images/svg/admin-profile-one.svg";
import ProfileDownload from "../../themes/assets/images/svg/Downloadicon.svg";
import ProfileDownloadIconBlue from "../../themes/assets/images/svg/Downloadicon_blue.svg";
import exportclose from "../../themes/assets/images/svg/ExportClose.svg";
import exportcalendericon from "../../themes/assets/images/svg/ExportCalendarBlank.svg";
import {
  adminPersonaDownloadData,
  postEmployeeTimesheetData,
} from "../../redux/actions/timesheet";
import moment from "moment";
import { adminPersonaEmployeeTimesheets } from "../../redux/actions/timesheet";
import AvatarProfile from "../Avatar/AvatarProfile";
interface DataType {
  key: string;
  date: string;
  hours: string;
  status: JSX.Element;
  action: JSX.Element;
}

const AdminEmployeeTimesheet = () => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const { RangePicker } = DatePicker;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    loadingLanding,
    sendReminder,
    adminPersonaEmployeeTimesheetsData,
    adminPersonaEmployeeTimesheetsLoading,
    adminPersonaDownloadDataLoading,
    errorPostLanding,
    errorDownloadLanding,
  } = useAppSelector((state: any) => state.timesheet);
  const reminderSuccess = !errorPostLanding;
  const reminderSuccessDownload = !errorDownloadLanding;
  const [visibleReminder, setVisibleReminder] = useState(false);
  const [visibleEmailReminder, setVisibleEmailReminder] = useState(false);
  const adminEmployeeData = adminPersonaEmployeeTimesheetsData?.data;
  useEffect(() => {
    const args = {
      empId: id,
      start: 0,
      count: 10,
    };
    dispatch(adminPersonaEmployeeTimesheets(args));
  }, [id]);
  const items = [
    {
      title: (
        <Link
          to={`/${START}`}
          // onClick={(e) => redirection(e, TIMESHEET_BREADCRUMB)}
          className="link-highlight"
        >
          {"My Work"}
        </Link>
      ),
    },
    {
      title: (
        <Link
          to={`../${n.MYWORK_URL}/AdminPanel`}
          // onClick={(e) => redirection(e, TIMESHEET_BREADCRUMB)}
          className="link-highlight"
        >
          Admin
        </Link>
      ),
    },
    {
      title: "My Team",
    },
  ];

  const handleClose = () => {
    setVisibleReminder(false);
  };
  const handleClosePopup = () => {
    setVisibleEmailReminder(false);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDownloadEnabled, setIsDownloadEnabled] = useState(false);
  const [dates, setDates] = useState([null, null]); // Store start and end dates
  const handleDateChange = (value) => {
    setDates(value);
  };
  const isRangeSelected = dates[0] && dates[1];
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleModalCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    setIsDownloadEnabled(false);
    setDates([null, null]); // Reset the date range when modal closes
  };
  const onDateChange = (value, dateString) => {
    setDates(dateString); // Set the selected date range
    // If both start and end dates are selected, enable the download button
    setIsDownloadEnabled(value && value.length === 2 && value[0] && value[1]);
  };

  const clickOnEmail = () => {
    setIsModalVisible(false);
    dispatch(
      postEmployeeTimesheetData({
        userId: id,
        startDate: moment(dates[0], "DD/MM/YYYY").format("YYYY-MM-DD"),
        endDate: moment(dates[1], "DD/MM/YYYY").format("YYYY-MM-DD"),
      })
    )
      .unwrap()
      .then(() => {
        setVisibleEmailReminder(true);
      })
      .catch((error) => {
        setVisibleEmailReminder(true);
      });
  };

  const clickOnDownloadFile = () => {
    setIsModalVisible(false);
    dispatch(
      adminPersonaDownloadData({
        userId: id,
        startDate: moment(dates[0], "DD/MM/YYYY").format("YYYY-MM-DD"),
        endDate: moment(dates[1], "DD/MM/YYYY").format("YYYY-MM-DD"),
      })
    )
      .unwrap()
      .then(() => {
        setVisibleReminder(true);
      })
      .catch((error) => {
        setVisibleReminder(true);
      });
  };

  const clickOnDownloadFileIcon = (item, index) => {
    setLoadingIndex(index);
    dispatch(
      adminPersonaDownloadData({
        userId: id,
        startDate: item?.weekStartDate,
        endDate: item?.weekEndDate,
      })
    )
      .unwrap()
      .then(() => {
        setIsModalVisible(false);
        setVisibleReminder(true);
      });
  };

  const otherPageColumns = [
    {
      title: (
        <span className="activities_title">
          <div className="activities_title_text">Timesheet Date</div>
        </span>
      ),
      dataIndex: "date",
      key: "date",
    },
    {
      title: (
        <span className="activities_title">
          <div className="activities_title_text">Worked Hours</div>
        </span>
      ),
      dataIndex: "hours",
      key: "hours",
    },
    {
      title: (
        <span className="activities_title">
          <div className="activities_title_text">Status</div>
        </span>
      ),
      dataIndex: "status",
      key: "status",
    },
    {
      title: (
        <span className="activities_title">
          <div className="activities_title_text">Action</div>
        </span>
      ),
      dataIndex: "action",
      key: "action",
    },
  ];
  const adminProfileList2 = adminEmployeeData;
  const [loadingIndex, setLoadingIndex] = useState(null);

  const adminProfileList: DataType[] = Array.isArray(
    adminEmployeeData?.tsManagementDetails
  )
    ? adminEmployeeData.tsManagementDetails?.map((tsm, index) => ({
        key: tsm?.id,
        date: tsm?.weekDisplayName,
        hours: `${tsm?.totalTime?.hours
          .toString()
          .padStart(2, "0")}:${tsm?.totalTime?.minutes
          .toString()
          .padStart(2, "0")} hrs`,
        timesheetId: tsm?.id || tsm?.timesheetId,
        weekStartDate: tsm?.weekStartDate,
        weekEndDate: tsm?.weekEndDate,
        status: (
          <div className={`timesheet_status ${tsm?.status}`}>{tsm?.status}</div>
        ),
        action:
          adminPersonaDownloadDataLoading &&
          index === loadingIndex &&
          !isModalVisible ? (
            <button className="download-icon" type="button">
              <Spin spinning={adminPersonaDownloadDataLoading} />
            </button>
          ) : (
            <button
              className="download-icon"
              type="button"
              onClick={() => clickOnDownloadFileIcon(tsm, index)}
            >
              <img src={ProfileDownload} alt="Button Image" />
            </button>
          ),
      }))
    : [];

  const handleProfileRowClick = (event, e) => {
    const isActionButtonClick = event?.target?.closest(".download-icon");
    console.log("Action", event);
    if (!isActionButtonClick) {
      navigate(
        `../${n.MYWORK_URL}/AdminPanel/EmployeePreview/${e?.timesheetId}/${e?.weekStartDate}/${e?.weekEndDate}`
      );
    } else {
      event.stopPropagation();
    }
  };

  return (
    <Spin spinning={adminPersonaEmployeeTimesheetsLoading}>
      <div className="main_container_approval">
        <div className="hidestricky_breadcrump">
          <BreadcrumbComponent items={items} />
          <div className="Seperation-page-title">
            <div className="admin_page-title">Employee Timesheets</div>
          </div>
        </div>
        <div className="apprval_tab_parent">
          <div
            id="timesheet_panel"
            className="timesheet_panel timesheet_admin-panel"
          >
            <div id="all_manager_activities" className="all_manager_activities">
              <div className="emp_profile-detail">
                <span className="profile_sec">
                  <AvatarProfile
                    className="avatar-admin-profile"
                    name={adminProfileList2?.userName}
                    profilePic={adminProfileList2?.imgUrl}
                  />
                  <div className="profile_details">
                    <div className="profile_text">
                      {adminProfileList2?.userName}
                    </div>
                    <div className="profile_desc">
                      {adminProfileList2?.designation}
                    </div>
                  </div>
                </span>
                <button type="button" onClick={showModal}>
                  <img src={ProfileDownloadIconBlue} alt="Button Image" />
                  Download
                </button>
              </div>
              <Table
                className="manager_activities_table"
                columns={otherPageColumns}
                dataSource={adminProfileList}
                locale={{
                  emptyText: (
                    <div className="no-admin-total-container">
                      <img
                        className="no-data-admin-img"
                        src={
                          adminEmployeeData?.tsManagementDetails?.emptyDataPage
                        }
                        alt=""
                      />
                      <div className="no-tsm-admin-title">
                        {
                          adminEmployeeData?.tsManagementDetails
                            ?.noDataHeaderMessage
                        }
                      </div>
                      <div className="no-tsm-admin-content">
                        {adminEmployeeData?.tsManagementDetails?.noDataMessage}
                      </div>
                    </div>
                  ),
                }}
                onRow={(e) => ({
                  onClick: (event) => {
                    handleProfileRowClick(event, e);
                  },
                })}
              />
            </div>
          </div>
        </div>
      </div>
      {visibleReminder && (
        <ToastMessage
          closeAction={handleClose}
          customToastArea={
            reminderSuccessDownload
              ? "custom-toast-area skills-extracted"
              : "custom-toast-area no-skills-extracted"
          }
          customToastLeftArea={"custom-left-area"}
          icon={reminderSuccessDownload ? ToastInfo : ToastError}
          toastMsgTitle={
            reminderSuccessDownload
              ? "Downloaded Successfully"
              : "Download Unsuccessfull"
          }
          toastMsgPara={
            reminderSuccessDownload
              ? "File has been successfully downloaded"
              : "No records found. Please check the input and try again."
          }
          customToastRightArea={"close-btn-area"}
          closeIcon={ToastClose}
        />
      )}
      {visibleEmailReminder && (
        <ToastMessage
          closeAction={handleClosePopup}
          customToastArea={
            reminderSuccess
              ? "custom-toast-area skills-extracted"
              : "custom-toast-area no-skills-extracted"
          }
          customToastLeftArea={"custom-left-area"}
          icon={reminderSuccess ? ToastInfo : ToastError}
          toastMsgTitle={
            reminderSuccess ? "Email Sent Successfully" : "Records Unavailable"
          }
          toastMsgPara={
            reminderSuccess
              ? "Email has been sent successfully"
              : "No records found. Please check the input and try again."
          }
          customToastRightArea={"close-btn-area"}
          closeIcon={ToastClose}
        />
      )}
      <Modal
        title="Download Timesheet"
        className="exporttimesheet_modal admin-export-data"
        open={isModalVisible}
        onCancel={() => {
          handleModalCancel();
          form.resetFields();
        }}
        closeIcon={
          <img
            src={exportclose}
            alt="Close Icon"
            className="custom-close-icon"
          />
        }
        footer={[
          <div className="export-footer-button">
            <div className="footer-download-button">
              <Button
                key="download"
                onClick={clickOnDownloadFile}
                disabled={!isDownloadEnabled}
                style={{
                  backgroundColor: isDownloadEnabled ? "#0642B5" : "#E2E2E2",
                  color: isDownloadEnabled ? "#FFFFFF" : "#858585",
                }}
              >
                Download
              </Button>
            </div>
            <div className="footer-email-button">
              <Button
                key="email"
                onClick={clickOnEmail}
                disabled={!isDownloadEnabled}
                style={{
                  backgroundColor: isDownloadEnabled ? "#0642B5" : "#E2E2E2",
                  color: isDownloadEnabled ? "#FFFFFF" : "#858585",
                }}
              >
                E-mail
              </Button>
            </div>
          </div>,
        ]}
      >
        <div className="modal-subtext" id="modal-subtext">
          Timesheet will export based on the selected date.
        </div>

        <Form form={form} layout="vertical" className="export-popup-form-otr">
          <Form.Item
            label={
              <div className="start-date-label" id="start-date-label">
                Start & End Date
              </div>
            }
            name="startEndDate"
            rules={[{ required: true, message: "Start Date is required" }]}
            className="export-form-field_otr"
          >
            <Space direction="vertical" size={8} className="range_sec-otr">
              <RangePicker
                className="custom-input"
                picker="date"
                format={"DD/MM/YYYY"}
                allowClear
                popupClassName="custom-input-popup"
                placeholder={
                  isRangeSelected ? ["Start Date", "End Date"] : ["Select", ""]
                }
                separator={isRangeSelected ? "-" : ""}
                onChange={onDateChange} // Update dates when range is selected
                suffixIcon={
                  <img
                    src={exportcalendericon}
                    alt="Calendar Icon"
                    className="Calender-icon"
                  />
                }
              />
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </Spin>
  );
};

export default AdminEmployeeTimesheet;
