import { useEffect, useState } from "react";
import Modal from "antd/es/modal/Modal";
import "./RejectLeavePopup.css";
import { Input } from "antd";
import { CANCEL, TIMESHEET_POPUPS, CONFIRM } from "../../../../constants";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store/redux-hooks";

const RejectLeavePopup = (props) => {
  const { TextArea } = Input;
  const [rejectComment, setRejectComment] = useState("");

  const handleClose = () => {
    setRejectComment("");
    props?.close();
  };
  const maxLength = 200;
  return (
    <div>
      <Modal
        className="reject-tsm_lm"
        open={props?.openRejectPopup}
        footer={false}
        closeIcon={false}
        centered={true}
      >
        <div className="reject-popup_lm">
          <div id="timesheet-head_lm" className="timesheet-head_lm">
            {TIMESHEET_POPUPS?.REJECT_TITLE_LM}
          </div>
          <div id="timesheet-body_lm" className="timesheet-body_lm">
            {TIMESHEET_POPUPS?.REJECT_CONTENT_LM}
          </div>
          <div className="give_reason_lm">
            {TIMESHEET_POPUPS?.TSM_GIVE_REASON}
            <span style={{ color: "#CC0000" }}>{"*"}</span>
          </div>
          <TextArea
            placeholder="Enter Here"
            autoSize={{ minRows: 4 }}
            value={rejectComment}
            onChange={(e) => setRejectComment(e.target.value)}
            maxLength={maxLength}
          />
          {rejectComment.length > maxLength - 1 && (
            <div style={{ color: "red" }}>Maximum length exceeded!</div>
          )}
          <div id="tsm-buttons_lm" className="tsm-buttons_lm">
            <button
              id="cancel-button_lm_lm"
              className="cancel-button_lm"
              onClick={handleClose}
            >
              {CANCEL}
            </button>
            <button
              id="confirm-button_lm"
              className="confirm-button_lm"
              onClick={() => {
                props?.clickonApproveReject(
                  "REJECT",
                  rejectComment,
                  props?.selectedLeaveItem
                );
                setRejectComment("");
              }}
              disabled={rejectComment ? false : true}
            >
              {CONFIRM}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RejectLeavePopup;
