import {
  useAppSelector,
  useAppDispatch,
} from "../../../redux/store/redux-hooks";
import { SearchIcon } from "../../common/svg/SearchIcon";
import { Table, Spin, Select } from "antd";
import { useCallback, useEffect, useState, useMemo } from "react";
import "./index.css";
import AvatarProfile from "../../Avatar/AvatarProfile";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { getManagerTimesheet } from "../../../redux/actions/timesheet";
import {
  approveRejectLeave,
  clearedToastMsgPopup,
  getSearchUserListLM,
  approveRejectLeaveGroup,
  clearedDelegateUserData,
} from "../../../redux/actions/leavemanagement";
import PreviewPopup from "../LeaveManagement/PreviewPopup";
import ToastMessage from "../../common/ToastMsg/toastMessage";
import ToastClose from "../../../themes/assets/images/performance/svg/ToastClose.svg";
import ToastInfo from "../../../themes/assets/images/performance/svg/ToastInfo.svg";
import ToastError from "../../../themes/assets/images/performance/svg/ToastError.svg";
import NoLeavePending from "../../../themes/assets/images/svg/no-leave.svg";
import DelegateIcon from "../../../themes/assets/images/svg/leave_management/delegate-icon.svg";
import { debounce } from "lodash";
import type { TableRowSelection } from "antd/es/table/interface";
import FooterComponent from "../LeaveManagement/FooterComponent";
import ApproveConfirmPopup from "../LeaveManagement/ApproveConfirmPopup";
import RejectLeavePopup from "./RejectLeavePopup";
import { GamificationSuccessPopup } from "../../leave-management/components/Modals";
import {
  paginationLeftIcon,
  paginationRightIcon,
} from "../../common/svg/paginationIcons";
import moment from "moment";
import { No_PENDING_LEAVE_TEXT } from "../../../constants";
import DelegateLeavePopup from "./DelegateLeavePopup";
import DelegatePreviewPopup from "./DelegateLeavePopup/DelegatePreviewPopup";
import amplitude from "amplitude-js";
interface DataType {
  title: React.ReactNode;
  date: React.ReactNode;
  type: React.ReactNode;
  status: React.ReactNode;
  action: React.ReactNode;
}
interface Reject {
  wfRequestId: React.ReactNode;
  wfRequestStepId: React.ReactNode;
  ownerId: React.ReactNode;
}

const LeaveManagement = (props) => {
  const dispatch = useAppDispatch();
  const { managerLoading, managerLanding, managerTimesheet } = useAppSelector(
    (state: any) => state.timesheet
  );
  const {
    approveRejectLeaveLoading,
    approveRejectLeaveObj,
    isApproveRejectLeaveError,
    getSearchUserListLMData,
  } = useAppSelector((state: any) => state.leavemanagement);

  const [isStatus, setIsStatus] = useState("Pending");
  const [selectionState, setSelectionState] = useState("Pending");
  const [start, setStart] = useState(0);
  const [count, setCount] = useState(10);
  const [isPageStart, setIsPageStart] = useState(1);
  const [leaveDetailsList, setLeaveDetailsList] = useState(
    managerTimesheet?.data?.leaveDeatilList || []
  );
  const [selectedLeaveItem, setSelectedLeaveItem] = useState<any>(null);
  const [visiblePopup, setVisiblePopup] = useState(false);
  const [toastMsgVisible, setToastMsgVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRowData, setSelectedRowData] = useState<Reject[]>([]);
  const [confrimPopupVisible, setConfrimPopupVisible] = useState(false);
  const [openRejectPopup, setOpenRejectPopup] = useState(false);
  const [showGamificationPopup, setShowGamificationPopup] = useState(false);

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [delegatePopupVisible, setDelegatePopupVisible] = useState(false);
  const [selectedManager, setSelectedManager] = useState<any>(null);
  const [selectedActionType, setSelectedActionType] = useState("");

  const handleOpenDelegateClick = () => {
    setVisiblePopup(false);
    setIsPopupVisible(true);
    dispatch(clearedDelegateUserData({}));
    setSearchValue("");
  };

  const handleDelegateClick = () => {
    setIsPopupVisible(false);
    setDelegatePopupVisible(true);
  };
  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: DataType[]
  ) => {
    const transformedRowsData: Reject[] = selectedRows?.map((row: any) => ({
      wfRequestId:
        row?.object?.leaveApprovalStatus === "PENDING_CANCELLATION"
          ? row?.object?.cancellationWorkflowRequestId
          : row?.object?.workflowRequestId,
      wfRequestStepId: row?.object?.workflowRequestStepId,
      ownerId: row?.object?.ownerId,
    }));
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRowData(transformedRowsData);
  };

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
    type: "checkbox",
  };

  useEffect(() => {
    const args = {
      selectedFeature: props?.selectedFeature,
      status: selectionState || "Pending",
      start: start,
      count: 10,
    };
    dispatch(getManagerTimesheet(args));
  }, [props?.selectedFeature, selectionState, start]);

  useEffect(() => {
    if (managerTimesheet?.data?.leaveDeatilList) {
      setLeaveDetailsList(managerTimesheet?.data?.leaveDeatilList);
    }
  }, [managerTimesheet?.data?.leaveDeatilList]);

  useEffect(() => {
    if (Object.keys(approveRejectLeaveObj)?.length > 0) {
      if (
        selectedActionType === "REJECT" ||
        selectedActionType === "DELEGATE"
      ) {
        setToastMsgVisible(true);
      } else {
        setShowGamificationPopup(true);
      }
      // if (
      //   approveRejectLeaveObj?.data?.intervalPopup ||
      //   approveRejectLeaveObj?.data?.thresholdPopup
      // ) {
      //   setShowGamificationPopup(true);
      // } else {
      //   setToastMsgVisible(true);
      // }
    }
  }, [approveRejectLeaveObj]);

  const leaveOptionsColumns = [
    {
      title: (
        <span className="activities_title">
          <div className="activities_title_text">Employee Name</div>{" "}
        </span>
      ),
      dataIndex: "title",
      key: "title",
    },
    {
      title: (
        <span className="activities_title">
          <div className="activities_title_text">Date</div>{" "}
        </span>
      ),
      dataIndex: "date",
      key: "date",
    },
    {
      title: (
        <span className="activities_title">
          <div className="activities_title_text">Leave Type</div>
        </span>
      ),
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      //width: 300,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];
  const leaveOptionsColumnsOther = [
    {
      title: (
        <span className="activities_title">
          <div className="activities_title_text">Employee Name</div>{" "}
        </span>
      ),
      dataIndex: "title",
      key: "title",
    },
    {
      title: (
        <span className="activities_title">
          <div className="activities_title_text">Date</div>{" "}
        </span>
      ),
      dataIndex: "date",
      key: "date",
    },
    {
      title: (
        <span className="activities_title">
          <div className="activities_title_text">Leave Type</div>
        </span>
      ),
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 200,
    },
  ];

  console.log("manager reject", leaveDetailsList);

  const managerLeaveData = leaveDetailsList?.map((manager, index) => ({
    object: manager,
    key: manager?.leaveId,
    title: (
      <div className="manager_tsm_container">
        <AvatarProfile
          name={manager?.teamUserInfo?.displayName}
          profilePic={manager?.teamUserInfo?.mediaResponse?.referenceUrl}
        />
        <div className="manager_tsm_titles">
          <div className="timesheet_manager_titles">
            {manager?.teamUserInfo?.displayName}
          </div>
          <div className="timesheet_regular">
            {manager?.teamUserInfo?.desgination}
          </div>
        </div>
      </div>
    ),
    date: (
      <div className="timesheet_regular">
        {`${moment(manager?.startDate).format("DD MMM YYYY")} - ${moment(
          manager?.endDate
        ).format("DD MMM YYYY")}`}
        <div>{`(${manager?.displayRequesting})`}</div>
      </div>
    ),
    type: (
      <div className="timesheet_regular">{manager?.leaveTypeDisplayName}</div>
    ),
    status: (
      <div
        className={`timesheet_status ${manager?.workflowSteps?.displayProperties?.displayNameForEmployee}`}
      >
        {manager?.workflowSteps?.displayProperties?.displayNameForEmployee}
      </div>
    ),
    comment:
      selectionState !== "Pending" ? (
        <div className="leave_management_comment">{manager?.comment}</div>
      ) : null,
    action:
      selectionState === "Pending" && selectedRowData?.length <= 1 ? (
        <div className="check_buttons_tsm">
          <button
            className="delegate_manager_leave check_delegate-icon"
            onClick={props?.handleClick}
          >
            <img src={DelegateIcon} alt="delegateicon" />
          </button>
          <button
            className="check_true_button"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              clickonApproveReject("APPROVE", "", manager);
            }}
          >
            <CheckOutlined style={{ color: "#008000" }} />
          </button>
          <button
            className="check_false_button"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setOpenRejectPopup(true);
              setSelectedLeaveItem(manager);
            }}
          >
            <CloseOutlined style={{ color: "#CC0000" }} />
          </button>
        </div>
      ) : null,
  }));

  const EmptyLeavePending = (props) => {
    let selectionState = "No Pending Leaves";
    if (props?.selectionState === "Approved") {
      selectionState = "No Approved Leaves";
    } else if (props?.selectionState === "Rejected") {
      selectionState = "No Rejected Leaves";
    }
    return (
      <div className="noleave_data">
        <img className="noleave-prnding" src={NoLeavePending} alt="" />
        <p>{selectionState}</p>
      </div>
    );
  };

  const handleStatusChange = (item) => {
    try {
      setSelectionState(item);
      setIsStatus(item);
      setSelectedRowKeys([]);
      setSelectedRowData([]);
      setLeaveDetailsList([]);
      setStart(0);
      setIsPageStart(1);
    } catch (error) {}
  };

  const clickonApproveReject = (value, comment, item) => {
    try {
      setSelectedActionType(value);
      setVisiblePopup(false);
      setOpenRejectPopup(false);
      let obj = {
        type: value,
        wfRequestId:
          item?.leaveApprovalStatus === "PENDING_CANCELLATION"
            ? item?.cancellationWorkflowRequestId
            : item?.workflowRequestId,
        wfRequestStepId: item?.workflowRequestStepId,
        ownerId: item?.ownerId,
        delegateUserId: value === "DELEGATE" ? selectedManager?.userId : null,
        comment: comment,
      };

      dispatch(approveRejectLeave(obj))
        .unwrap()
        .then(() => {
          let leaveList = [...leaveDetailsList];
          leaveList?.map((itemData, index) => {
            if (item?.leaveId === itemData?.leaveId) {
              leaveList?.splice(index, 1);
            }
          });
          setLeaveDetailsList(leaveList);
          {value ==="APPROVE"?amplitude.getInstance().logEvent("Successful Leave Approve") : 
            amplitude.getInstance().logEvent("Successful Leave Reject")}
          {value==="DELEGATE"&&amplitude.getInstance().logEvent("Successful Delegate Leave")}
        })
        .catch((error) => {
          setToastMsgVisible(true);
          {value ==="APPROVE"?amplitude.getInstance().logEvent("Unsuccessful Leave Approve") : 
            amplitude.getInstance().logEvent("Unsuccessful Leave Reject")}
          {value==="DELEGATE"&&amplitude.getInstance().logEvent("Unsuccessful Delegate Leave")}
        });
    } catch (error) {}
  };

  const handleCloseToastMsg = () => {
    try {
      dispatch(clearedToastMsgPopup({}));
      setToastMsgVisible(false);
    } catch (error) {}
  };

  const handleSearchChange = (e) => {
    try {
      const searchValueChange = e.target?.value;
      if (searchValueChange?.length >= 3 || searchValueChange === "") {
        dispatch(getSearchUserListLM(searchValueChange));
      }
    } catch (error) {}
  };

  const debounceValue = debounce(handleSearchChange, 1000);

  const handleCancel = () => {
    setSelectedRowKeys([]);
    setSelectedRowData([]);
  };

  const handlePopupCancel = () => {
    setConfrimPopupVisible(false);
  };

  const PaginationActivities = () => {
    const ITEMS_PER_PAGE = "Items per page:";
    const total = managerTimesheet?.data?.searchCount;
    const goToPreviousPage = () => {
      if (start > 0) {
        const newStart = Math.max(0, start - count);
        setStart(newStart);
        setIsPageStart(newStart + 1);
        setSelectedRowKeys([]);
        setSelectedRowData([]);
      }
    };
    const goToNextPage = () => {
      if (start + count < total) {
        const newStart = Math.min(start + count, total);
        setStart(newStart);
        setIsPageStart(newStart + 1);
        setSelectedRowKeys([]);
        setSelectedRowData([]);
      }
    };
    const handleValueChange = (value) => {
      setCount(value);
      setStart(0);
      setIsPageStart(1);
    };
    const displayEnd = Math.min(start + count, total);

    return (
      <div className="pagination-leave-management">
        <div className="pagination-timesheet">
          {ITEMS_PER_PAGE}{" "}
          <Select
            defaultValue={10}
            options={[{ value: 10, label: 10 }]}
            onChange={handleValueChange}
          />
          <div className="pagination-timesheet-text">
            {`${isPageStart} - ${displayEnd} of ${total}`}{" "}
          </div>
          <span className="parent_pagination_icons">
            <span
              className={`pagination_left_icon ${
                start === 0 ? "disabled" : ""
              }`}
              onClick={goToPreviousPage}
            >
              {paginationLeftIcon()}
            </span>
            <span
              className={`pagination_right_icon ${
                start + count >= total ? "disabled" : ""
              }`}
              onClick={goToNextPage}
            >
              {paginationRightIcon()}
            </span>
          </span>
        </div>
      </div>
    );
  };

  const clickOnConfirm = () => {
    try {
      setConfrimPopupVisible(false);
      let payLoad = {
        type: "APPROVE",
        delegateUserId: null,
        workflowRequestList: selectedRowData,
      };
      dispatch(approveRejectLeaveGroup(payLoad))
        .unwrap()
        .then(() => {
          const args = {
            selectedFeature: props?.selectedFeature,
            status: selectionState || "Pending",
            start: start,
            count: 10,
          };
          dispatch(getManagerTimesheet(args));
          setSelectedRowData([]);
          setSelectedRowKeys([]);
        })
        .catch((error) => {
          setToastMsgVisible(true);
        });
    } catch (error) {}
  };

  const handleClose = () => {
    dispatch(clearedToastMsgPopup({}));
    setShowGamificationPopup(false);
  };

  const clickOnRequestCard = (item) => {
    try {
      const tempQuestionListArray = item?.preview?.questionList?.map(
        (questionItem) => {
          if (
            questionItem?.type === "DROPDOWN" ||
            questionItem?.type === "RADIO_DROPDOWN" ||
            questionItem?.type === "RADIO"
          ) {
            return {
              ...questionItem,
              answer:
                item?.floaterName !== null
                  ? item?.floaterName
                  : questionItem?.jsonKey === "fractionQuantityTimeSpan"
                  ? item[`fractionQuantityName`]
                  : item[`${questionItem?.jsonKey}DisplayName`],
            };
          } else {
            return {
              ...questionItem,
              answer: item[questionItem?.jsonKey],
            };
          }
        }
      );
      const leaveData = {
        ...item,
        questionListArray: tempQuestionListArray,
      };
      setSelectedLeaveItem(leaveData);
      setVisiblePopup(true);
    } catch (error) {}
  };

  return (
    <Spin spinning={managerLoading || approveRejectLeaveLoading}>
      <div id="tab-panel-container_lm" className="tab-panel-container">
        <div id="tab-panel-row-view_lm" className="tab-panel-row-view_lm">
          {/* <Input
            type={"Search"}
            size="large"
            placeholder="Search"
            prefix={<SearchIcon />}
            value={searchValue}
            // defaultValue={searchDataCheck}
            onChange={(e) => {
              setSearchValue(e.target?.value);
              debounceValue(e);
            }}
          /> */}
          <div id="right-group-view_lm" className="right-group-view">
            <div id="status-row-view_lm" className="status-row-view">
              {managerLanding?.data?.statusList?.map((item, index) => {
                return (
                  <button
                    key={item}
                    defaultValue={item?.[0]}
                    className={`status-sub-view ${item}  ${
                      item === isStatus ? "selected" : ""
                    } leave`}
                    onClick={() => handleStatusChange(item)}
                  >
                    {item}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
        <div className="manager_week_tsm">
          {leaveDetailsList?.length > 0 ? (
            <Table<DataType>
              key={selectionState}
              className="manager_activities_table"
              onRow={(record: any, index) => ({
                onClick: () => {
                  if (selectedRowData?.length <= 1) {
                    clickOnRequestCard(record?.object);
                  }
                },
              })}
              rowSelection={
                selectionState === "Pending" ? rowSelection : undefined
              }
              columns={
                selectionState === "Pending"
                  ? leaveOptionsColumns
                  : leaveOptionsColumnsOther
              }
              dataSource={managerLeaveData}
            />
          ) : (
            !managerLoading && (
              <div className="empty_view_lm">
                <EmptyLeavePending selectionState={selectionState} />
              </div>
            )
          )}
        </div>
        {selectedRowData?.length > 1 && (
          <FooterComponent
            seletedCount={selectedRowData?.length}
            handleCancel={handleCancel}
            handleApprove={() => setConfrimPopupVisible(true)}
          />
        )}
        {leaveDetailsList?.length > 0 && <PaginationActivities />}

        <PreviewPopup
          visiblePopup={visiblePopup}
          handleClick={handleOpenDelegateClick}
          clickOnCloseIcon={() => setVisiblePopup(false)}
          previewData={selectedLeaveItem}
          clickonApproveReject={(type, comment, item) =>
            clickonApproveReject(type, comment, item)
          }
          clickOnReject={() => {
            setVisiblePopup(false);
            setOpenRejectPopup(true);
          }}
        />

        <DelegateLeavePopup
          handleDelegateClick={handleDelegateClick}
          isModalOpen={isPopupVisible}
          handleCancel={() => setIsPopupVisible(false)}
          onSearchValue={(e) => {
            setSearchValue(e.target?.value);
            debounceValue(e);
          }}
          value={searchValue}
          getSelectedManager={(data) => setSelectedManager(data)}
        />

        <DelegatePreviewPopup
          isModalOpen={delegatePopupVisible}
          handleCancel={() => setDelegatePopupVisible(false)}
          selectedManager={selectedManager}
          previewData={selectedLeaveItem}
          clickonApproveReject={(type, comment, item) =>
            clickonApproveReject(type, comment, item)
          }
        />

        <ApproveConfirmPopup
          open={confrimPopupVisible}
          handlePopupCancel={handlePopupCancel}
          clickOnConfirm={clickOnConfirm}
          selectedUsers={selectedRowData?.length}
        />
        <RejectLeavePopup
          openRejectPopup={openRejectPopup}
          close={() => setOpenRejectPopup(false)}
          selectedLeaveItem={selectedLeaveItem}
          clickonApproveReject={(type, comment, item) =>
            clickonApproveReject(type, comment, item)
          }
        />
        {toastMsgVisible && (
          <ToastMessage
            closeAction={handleCloseToastMsg}
            customToastArea={
              !isApproveRejectLeaveError
                ? "custom-toast-area skills-extracted"
                : "custom-toast-area no-skills-extracted"
            }
            customToastLeftArea={"custom-left-area"}
            icon={!isApproveRejectLeaveError ? ToastInfo : ToastError}
            toastMsgTitle={
              !isApproveRejectLeaveError
                ? approveRejectLeaveObj?.data?.title || "SUCCESS"
                : approveRejectLeaveObj?.error?.errorMessage || "FAILED"
            }
            toastMsgPara={
              !isApproveRejectLeaveError
                ? approveRejectLeaveObj?.data?.genericMessage
                : approveRejectLeaveObj?.error?.errorMessage
            }
            customToastRightArea={"close-btn-area"}
            closeIcon={ToastClose}
          />
        )}
        <GamificationSuccessPopup
          isVisible={showGamificationPopup}
          handleClose={handleClose}
          submitLeaveObj={approveRejectLeaveObj?.data}
          // leaveType={previewData?.leaveType?.leaveTypeName}
        />
      </div>
    </Spin>
  );
};

export default LeaveManagement;
