import { createSlice } from "@reduxjs/toolkit";
import {
  dynamicApiCall,
  getLMLandingPage,
  getLeaveTemplate,
  getLeaveTypes,
  getRequestingDays,
  getStaticLeaveManagement,
  getAbsentRequestLanding,
  getUpcomingLeaves,
  submitLeaveRequest,
  dynamicApiCallReason,
  dynamicApiCallRelation,
  clearLeaveSubmitData,
  dynamicApiCallLeave,
  deleteLeave,
  approveRejectLeave,
  clearedToastMsgPopup,
  getSearchUserListLM,
  approveRejectLeaveGroup,
  createLeaveForLbFloater,
  clearedDelegateUserData,
  submitEditLeaveRequest,
  dynamicApiCallLeaveClear,
} from "../actions/leavemanagement";

type leavemanagementLandingPageData = {
  error: boolean;
  errorAbsentLanding: boolean;
  loading: boolean;
  loadingList: boolean;
  loadingUpcomingLeaves: boolean;
  loadingAbsentLanding: boolean;
  landingpageData: any;
  absentLandingData: any;
  leaveTemplate: any;
  leaveTemplateLoading: any;
  leaveTypesData: any;
  leaveTypesError: any;
  upcomingLeaves: any;
  dynamicApiData: any;
  dynamicApiLoading: any;
  requestingdays: any;
  requestingdaysLoading: any;
  leavesTypesDataLoading: any;
  loadingLeaveBanner: boolean;
  staticLeaveBannerError: boolean;
  staticLeaveBanner: any;
  templateObj: any;
  previewData: any;
  submitLeaveObj: any;
  submitLeaveLoadnig: boolean;
  reasonLeavedata: any;
  reasonLeavedataLoading: any;
  leaveReasonType: any;
  leaveReasonError: boolean;
  leaveReasonLoading: boolean;
  warningLeaveData: any;
  warningLeaveLoading: boolean;
  warningLeaveError: boolean;
  deleteLeaveLoading: boolean;
  deleteLeaveData: any;
  deleteLeaveError: boolean;
  relationLeaveData: any;
  relationLeaveDataLoading: any;
  checkedHolidays: any;
  uploadedFile: any;
  approveRejectLeaveLoading: boolean;
  approveRejectLeaveObj: any;
  isApproveRejectLeaveError: boolean;
  getSearchUserListLMData: any;
  getSearchUserListLMLoading: boolean;
  editLeaveData: any;
  isEdit: boolean;
};

const initialDetailState: leavemanagementLandingPageData = {
  error: false,
  errorAbsentLanding: false,
  loading: false,
  loadingAbsentLanding: false,
  loadingList: false,
  loadingUpcomingLeaves: false,
  absentLandingData: [],
  landingpageData: [],
  leaveTemplate: [],
  leaveTemplateLoading: false,
  leaveTypesData: [],
  leaveTypesError: false,
  leavesTypesDataLoading: false,
  upcomingLeaves: [],
  dynamicApiData: [],
  dynamicApiLoading: false,
  requestingdays: [],
  requestingdaysLoading: false,
  loadingLeaveBanner: false,
  staticLeaveBannerError: false,
  staticLeaveBanner: [],
  templateObj: [],
  previewData: {},
  submitLeaveObj: {},
  submitLeaveLoadnig: false,
  reasonLeavedata: [],
  reasonLeavedataLoading: false,
  leaveReasonType: [],
  leaveReasonError: false,
  leaveReasonLoading: false,
  warningLeaveData: {},
  warningLeaveLoading: false,
  warningLeaveError: false,
  deleteLeaveLoading: false,
  deleteLeaveData: {},
  deleteLeaveError: false,
  relationLeaveData: [],
  relationLeaveDataLoading: false,
  checkedHolidays: {},
  uploadedFile: {},
  approveRejectLeaveLoading: false,
  approveRejectLeaveObj: {},
  isApproveRejectLeaveError: false,
  getSearchUserListLMData: {},
  getSearchUserListLMLoading: false,
  editLeaveData: {},
  isEdit: false,
};

export const leavemanagement: any = createSlice({
  name: "leavemanagement",
  initialState: initialDetailState,
  reducers: {
    // setActivePayslip: (state, action) => {
    //   state.activePayslip = action?.payload;
    // },
    setTemplateObj: (state, action) => {
      state.templateObj = action?.payload || [];
    },
    setPreviewData: (state, action) => {
      state.previewData = action?.payload || [];
    },
    setCheckedHolidays: (state, action) => {
      state.checkedHolidays = action?.payload || {};
    },
    setUploadedFile: (state, action) => {
      state.uploadedFile = action?.payload || {};
    },
    setEditData: (state, action) => {
      state.editLeaveData = action?.payload || {};
    },
    setIsEditValue: (state, action) => {
      state.isEdit = action?.payload || false;
    },
  },
  extraReducers: (builder) => {
    // getLMLandingPage
    builder.addCase(getLMLandingPage.pending, (state) => {
      state.loadingList = true;
      state.error = false;
    });
    builder.addCase(getLMLandingPage.fulfilled, (state: any, action) => {
      state.loadingList = false;
      state.landingpageData = action?.payload;
    });
    builder.addCase(getLMLandingPage.rejected, (state: any, action) => {
      state.loadingList = false;
      state.error = true;
    });

    //getLeaveTypes
    builder.addCase(getLeaveTypes.pending, (state) => {
      state.leavesTypesDataLoading = true;
      state.leaveTypesError = false;
    });
    builder.addCase(getLeaveTypes.fulfilled, (state: any, action) => {
      state.leavesTypesDataLoading = false;
      state.leaveTypesData = action?.payload;
    });
    builder.addCase(getLeaveTypes.rejected, (state: any, action) => {
      state.leavesTypesDataLoading = false;
      state.leaveTypesError = true;
    });

    //getUpcomingLeaves
    builder.addCase(getUpcomingLeaves.pending, (state) => {
      state.loadingUpcomingLeaves = true;
      state.error = false;
    });
    builder.addCase(getUpcomingLeaves.fulfilled, (state: any, action) => {
      state.loadingUpcomingLeaves = false;
      state.upcomingLeaves = action?.payload;
    });
    builder.addCase(getUpcomingLeaves.rejected, (state: any, action) => {
      state.loadingUpcomingLeaves = false;
      state.error = true;
    });

    //  getLeaveTemplateAPI
    builder.addCase(getLeaveTemplate.pending, (state) => {
      state.leaveTemplateLoading = true;
      state.error = false;
    });
    builder.addCase(getLeaveTemplate.fulfilled, (state: any, action) => {
      state.leaveTemplateLoading = false;
      state.leaveTemplate = action?.payload;
      state.requestingdays = {};
    });
    builder.addCase(getLeaveTemplate.rejected, (state: any, action) => {
      state.leaveTemplateLoading = false;
      state.error = true;
    });

    //requesting days
    builder.addCase(getRequestingDays.pending, (state) => {
      state.requestingdaysLoading = true;
      state.error = false;
    });
    builder.addCase(getRequestingDays.fulfilled, (state: any, action) => {
      state.requestingdaysLoading = false;
      state.requestingdays = action?.payload;
    });
    builder.addCase(getRequestingDays.rejected, (state: any, action) => {
      state.requestingdaysLoading = false;
      state.error = true;
    });

    //dynamicApiData
    builder.addCase(dynamicApiCall.pending, (state) => {
      state.dynamicApiLoading = true;
      state.error = false;
    });
    builder.addCase(dynamicApiCall.fulfilled, (state: any, action) => {
      state.dynamicApiLoading = false;
      state.dynamicApiData = action?.payload;
    });
    builder.addCase(dynamicApiCall.rejected, (state: any, action) => {
      state.dynamicApiLoading = false;
      state.error = true;
    });

    //getstaticbanner
    builder.addCase(getStaticLeaveManagement.pending, (state) => {
      state.loadingLeaveBanner = true;
      state.staticLeaveBannerError = false;
    });
    builder.addCase(
      getStaticLeaveManagement.fulfilled,
      (state: any, action) => {
        state.loadingLeaveBanner = false;
        state.staticLeaveBanner = action?.payload;
      }
    );
    builder.addCase(getStaticLeaveManagement.rejected, (state: any, action) => {
      state.loadingLeaveBanner = false;
      state.staticLeaveBannerError = true;
    });

    //Absent Request Landing
    builder.addCase(getAbsentRequestLanding.pending, (state) => {
      state.loadingAbsentLanding = true;
      state.errorAbsentLanding = false;
    });
    builder.addCase(getAbsentRequestLanding.fulfilled, (state: any, action) => {
      state.loadingAbsentLanding = false;
      state.absentLandingData = action?.payload;
    });
    builder.addCase(getAbsentRequestLanding.rejected, (state: any, action) => {
      state.loadingAbsentLanding = false;
      state.errorAbsentLanding = true;
    });

    //Submit Leave
    builder.addCase(submitLeaveRequest.pending, (state) => {
      state.submitLeaveLoadnig = true;
      state.errorAbsentLanding = false;
    });
    builder.addCase(submitLeaveRequest.fulfilled, (state: any, action) => {
      state.submitLeaveLoadnig = false;
      state.submitLeaveObj = action?.payload;
    });
    builder.addCase(submitLeaveRequest.rejected, (state: any, action) => {
      state.submitLeaveLoadnig = false;
      state.errorAbsentLanding = true;
    });
    //Submit Edit Leave
    builder.addCase(submitEditLeaveRequest.pending, (state) => {
      state.submitLeaveLoadnig = true;
      state.errorAbsentLanding = false;
    });
    builder.addCase(submitEditLeaveRequest.fulfilled, (state: any, action) => {
      state.submitLeaveLoadnig = false;
      state.submitLeaveObj = action?.payload;
    });
    builder.addCase(submitEditLeaveRequest.rejected, (state: any, action) => {
      state.submitLeaveLoadnig = false;
      state.errorAbsentLanding = true;
    });

    //Submit Floater Leave
    builder.addCase(createLeaveForLbFloater.pending, (state) => {
      state.submitLeaveLoadnig = true;
      state.errorAbsentLanding = false;
    });
    builder.addCase(createLeaveForLbFloater.fulfilled, (state: any, action) => {
      state.submitLeaveLoadnig = false;
      state.submitLeaveObj = action?.payload;
    });
    builder.addCase(createLeaveForLbFloater.rejected, (state: any, action) => {
      state.submitLeaveLoadnig = false;
      state.errorAbsentLanding = true;
    });
    //dynamicApiDataReason
    builder.addCase(dynamicApiCallReason.pending, (state) => {
      state.reasonLeavedataLoading = true;
      state.error = false;
    });
    builder.addCase(dynamicApiCallReason.fulfilled, (state: any, action) => {
      state.reasonLeavedataLoading = false;
      state.reasonLeavedata = action?.payload;
    });
    builder.addCase(dynamicApiCallReason.rejected, (state: any, action) => {
      state.reasonLeavedataLoading = false;
      state.error = true;
    });
    builder.addCase(clearLeaveSubmitData.fulfilled, (state: any, action) => {
      state.submitLeaveObj = {};
      state.deleteLeaveData = {};
    });

    //dynamicApiLeave
    builder.addCase(dynamicApiCallLeave.pending, (state) => {
      state.warningLeaveLoading = true;
      state.warningLeaveError = false;
    });
    builder.addCase(dynamicApiCallLeave.fulfilled, (state: any, action) => {
      state.warningLeaveLoading = false;
      state.warningLeaveData = action?.payload;
    });
    builder.addCase(dynamicApiCallLeave.rejected, (state: any, action) => {
      state.warningLeaveLoading = false;
      state.warningLeaveError = true;
    });

    //clear leavebalance
    builder.addCase(
      dynamicApiCallLeaveClear.fulfilled,
      (state: any, action) => {
        state.warningLeaveData = {};
      }
    );

    //cancelLeave
    builder.addCase(deleteLeave.pending, (state) => {
      state.deleteLeaveLoading = true;
      state.deleteLeaveError = false;
    });
    builder.addCase(deleteLeave.fulfilled, (state: any, action) => {
      state.deleteLeaveLoading = false;
      state.deleteLeaveData = action?.payload;
    });
    builder.addCase(deleteLeave.rejected, (state: any, action) => {
      state.deleteLeaveLoading = false;
      state.deleteLeaveError = true;
    });
    //dynamicApiDataRelation
    builder.addCase(dynamicApiCallRelation.pending, (state) => {
      state.relationLeaveDataLoading = true;
      state.error = false;
    });
    builder.addCase(dynamicApiCallRelation.fulfilled, (state: any, action) => {
      state.relationLeaveDataLoading = false;
      state.relationLeaveData = action?.payload;
    });
    builder.addCase(dynamicApiCallRelation.rejected, (state: any, action) => {
      state.relationLeaveDataLoading = false;
      state.error = true;
    });
    //approveRejectLeave
    builder.addCase(approveRejectLeave.pending, (state) => {
      state.approveRejectLeaveLoading = true;
      state.isApproveRejectLeaveError = false;
    });
    builder.addCase(approveRejectLeave.fulfilled, (state: any, action) => {
      state.approveRejectLeaveLoading = false;
      state.approveRejectLeaveObj = action?.payload;
    });
    builder.addCase(approveRejectLeave.rejected, (state: any, action) => {
      state.approveRejectLeaveLoading = false;
      state.isApproveRejectLeaveError = true;
    });
    builder.addCase(clearedToastMsgPopup.fulfilled, (state: any, action) => {
      state.approveRejectLeaveLoading = false;
      state.isApproveRejectLeaveError = false;
      state.approveRejectLeaveObj = {};
    });
    //get Search user
    builder.addCase(getSearchUserListLM.pending, (state) => {
      state.getSearchUserListLMLoading = true;
      state.error = false;
    });
    builder.addCase(getSearchUserListLM.fulfilled, (state: any, action) => {
      console.log("state.error", action?.payload);
      state.getSearchUserListLMLoading = false;
      if (action?.payload?.status === "FAILED") {
        state.getSearchUserListLMData = {};
      } else {
        state.getSearchUserListLMData = action?.payload;
      }
    });
    builder.addCase(getSearchUserListLM.rejected, (state: any, action) => {
      state.getSearchUserListLMLoading = false;
      state.error = true;
      state.getSearchUserListLMData = {};
    });
    builder.addCase(approveRejectLeaveGroup.pending, (state) => {
      state.approveRejectLeaveLoading = true;
      state.isApproveRejectLeaveError = false;
    });
    builder.addCase(approveRejectLeaveGroup.fulfilled, (state: any, action) => {
      state.approveRejectLeaveLoading = false;
      state.approveRejectLeaveObj = action?.payload;
    });
    builder.addCase(approveRejectLeaveGroup.rejected, (state: any, action) => {
      state.approveRejectLeaveLoading = false;
      state.isApproveRejectLeaveError = true;
    });
    builder.addCase(clearedDelegateUserData.fulfilled, (state: any, action) => {
      state.getSearchUserListLMData = {};
    });
  },
});

export default leavemanagement.reducer;
export const {
  setTemplateObj,
  setPreviewData,
  setCheckedHolidays,
  setUploadedFile,
  setEditData,
  setIsEditValue,
} = leavemanagement.actions;
