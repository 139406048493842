import {
  Breadcrumb,
  Dropdown,
  Input,
  MenuProps,
  Modal,
  Table,
  Row,
  Col,
  Select,
  Menu,
  Spin,
} from "antd";
import "./MyActivities.css";
import { Card } from "antd";
import { TabPanel, TabView } from "primereact/tabview";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store/redux-hooks";
import { useEffect, useState } from "react";
import {
  deleteMyActivities,
  getMyActivities,
} from "../../../redux/actions/timesheet";
import { CalendarOutlined } from "@ant-design/icons";
import {
  START,
  TIMESHEET_BREADCRUMB,
  MY_SPACE,
  NO_TIMESHEET,
  NO_TIMESHEET_SUBTITLE,
  MY_ACTIVITIES,
  CREATE_TIMESHEET,
  HOURS_TEXT,
  DELETE,
  CANCEL,
  DELETE_TIMESHEET,
  DELETE_TIMESHEET_MESSAGE,
  DELETE_TOAST_TITLE,
  DELETE_TOAST_MESSAGE,
} from "../../../constants";
import UpDown from "../../../themes/assets/images/svg/CaretUpDown.svg";
import ThreeDots from "../../../themes/assets/images/svg/DotsThreeVertical.svg";
import EmptyCalendar from "../../../themes/assets/images/calendar.png";
import MyActivitiesPreview from "./MyActivitiesPreview";
import {
  paginationLeftIcon,
  paginationRightIcon,
} from "../../common/svg/paginationIcons";
import Delete_Timesheet from "../../../themes/assets/images/Delete_bin.png";

import ToastMessage from "../../common/ToastMsg/toastMessage";
import ToastClose from "../../../themes/assets/images/performance/svg/ToastClose.svg";
import ToastInfo from "../../../themes/assets/images/performance/svg/ToastInfo.svg";
import ToastError from "../../../themes/assets/images/performance/svg/ToastError.svg";
import moment from "moment";
import n from "../../navigation/Route.Names";

interface Data {
  timesheetId: React.ReactNode;
  weekStartDate: React.ReactNode;
  weekEndDate: React.ReactNode;
}

const MyActivities = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { status = "" } = useParams();
  const statusKeys = {
    Approved: 3,
    Rejected: 4,
  };
  const { myActivities, loadingActivities, deleteActivities } = useAppSelector(
    (state: any) => state.timesheet
  );
  const [selectedStatus, setSelectedStatus] = useState("All");
  const employeeTimesheet = myActivities?.data?.tsManagement;
  const notSumbittedTimesheet =
    myActivities?.data?.notSubmittedDetails?.weekValuesList;
  const employeeTimesheetLenght = myActivities?.data?.toalCount;
  const [activeIndex, setActiveIndex] = useState(statusKeys?.[status] || 0);
  const [displayPreview, setDisplayPreview] = useState(false);
  const [start, setStart] = useState(1);
  const [count, setCount] = useState(10);
  const [isPageStart, setIsPageStart] = useState(0);
  const [selectedItems, setSelectedItems] = useState<any>(null);
  const [visible, setVisible] = useState(false);
  const deletionSuccess = !deleteActivities?.error;
  const [deletePopUp, setDeletePopUp] = useState(false);
  const allEmployeeTimesheet =
    employeeTimesheet && notSumbittedTimesheet
      ? [...employeeTimesheet, ...notSumbittedTimesheet]
      : null;
  const [previewSelectedItems, setPreviewSelectedItems] = useState<Data | null>(
    null
  );

  const breadcrumb = [
    {
      title: (
        <Link to={`/${START}`} className="link-highlight">
          {"My Space"}
        </Link>
      ),
    },
    {
      title: (
        <Link to={`/${START}/${MY_SPACE}/timesheet`} className="link-highlight">
          {TIMESHEET_BREADCRUMB}
        </Link>
      ),
    },
    {
      title: MY_ACTIVITIES,
    },
  ];
  const statusIndex = {
    0: "",
    1: "Pending",
    2: "Not Submitted",
    3: "Draft",
    4: "Approved",
    5: "Rejected",
  };
  useEffect(() => {
    const status = statusIndex[activeIndex];
    setSelectedStatus(status);
    const args = {
      status: status,
      start: isPageStart,
      count: 10,
    };
    dispatch(getMyActivities(args));
  }, [isPageStart, activeIndex, dispatch]);

  const showDeleteModal = (item: any) => {
    setSelectedItems(item);
    setDeletePopUp(true);
  };
  const showEditModal = (item: any) => {
    if (item?.status !== "Not Submitted") {
      setDisplayPreview(!displayPreview);
      setSelectedItems(item);
      setPreviewSelectedItems(item);
    }
  };
  const handleRowClick = (record, item) => {
    showEditModal(record);
  };
  const handleDeleteHandler = () => {
    const status = statusIndex[activeIndex];
    const args = {
      status,
      start: isPageStart,
      count: 10,
    };
    if (selectedItems) {
      dispatch(
        deleteMyActivities({
          weekStartDate: selectedItems.weekStartDate || "",
          weekEndDate: selectedItems.weekEndDate || "",
        })
      )
        .unwrap()
        .then(() => {
          dispatch(getMyActivities(args));
        });
      setDeletePopUp(false); // Close the modal after deletion
      setVisible(!visible);
    }
  };

  const deleteHandlerPreview = (record, item) => {
    setDisplayPreview(false);
    setDeletePopUp(true);
  };

  const items: MenuProps["items"] = [
    {
      label: <a onClick={() => setDisplayPreview(!displayPreview)}>Edit</a>,
      key: "0",
    },
    {
      label: <a onClick={handleDeleteHandler}>Delete</a>,
      key: "1",
    },
    {
      label: <a>Create</a>,
      key: "2",
    },
  ];

  const renderActionsMenu = (item) => {
    return (
      <Menu>
        {item?.status === "Approved" ||
        item?.status === "Not Submitted" ? null : (
          <Menu.Item
            onClick={() => {
              setDisplayPreview(!displayPreview);
              navigate(
                `/web/${MY_SPACE}/timesheet/createtimesheet/${item?.shiftType}/${item?.weekStartDate}/${item?.timesheetId}`
              );
              setPreviewSelectedItems(item);
            }}
          >
            Edit
          </Menu.Item>
        )}

        {item?.status === "Draft" || item?.status === "Rejected" ? (
          <Menu.Item key="delete" onClick={() => showDeleteModal(item)}>
            Delete
          </Menu.Item>
        ) : null}
        {selectedStatus === "Not Submitted" ||
        item?.status === "Not Submitted" ? (
          <Menu.Item key="delete" onClick={() => clickOnCreate(item)}>
            Create
          </Menu.Item>
        ) : null}
      </Menu>
    );
  };

  const clickOnCreate = (item) => {
    // const [startDate, endDate] = item;
    console.log("item", item);
    navigate(
      `/web/${n.MY_SPACE}/timesheet/createtimesheet/${myActivities?.data?.notSubmittedDetails?.shiftType}/${item?.startDate}?weekStartDate=${item?.startDate}&weekEndDate=${item?.endDate}&displayValue=${item?.notSubmittedWeek}`
    );
  };

  const renderActionsDropdown = (items, record) => {
    return (
      <Dropdown
        menu={{ items }}
        overlay={() => renderActionsMenu(record)}
        trigger={["click"]}
      >
        <span className="timesheet_icon_span">
          <img src={ThreeDots} className="timesheet_action_icon" alt="" />
        </span>
      </Dropdown>
    );
  };

  //pagination
  const PaginationActivities = () => {
    const ITEMS_PER_PAGE = "Items per page:";
    const total = myActivities?.data?.toalCount;

    const goToPreviousPage = () => {
      if (start > 1) {
        setStart(Math.max(1, start - count));
        setIsPageStart((prev) => prev - count);
      }
    };
    const goToNextPage = () => {
      if (start + count - 1 < total) {
        setStart(Math.min(start + count, total));
        setIsPageStart((prev) => prev + count);
      }
    };
    const handleValueChange = (value) => {
      setCount(value);
      setStart(1);
      setIsPageStart(0);
    };

    return (
      <div className="pagination-timesheet">
        {ITEMS_PER_PAGE}{" "}
        <Select
          defaultValue={10}
          options={[{ value: 10, label: 10 }]}
          onChange={handleValueChange}
        />
        <div className="pagination-timesheet-text">
          {`${start} - ${Math.min(start + count - 1, total)} of ${total} items`}{" "}
        </div>
        <span className="parent_pagination_icons">
          <span
            className={`pagination_left_icon ${start === 1 ? "disabled" : ""}`}
            onClick={goToPreviousPage}
          >
            {paginationLeftIcon()}
          </span>
          <span
            className={`pagination_right_icon ${
              start + count - 1 >= total ? "disabled" : ""
            }`}
            onClick={goToNextPage}
          >
            {paginationRightIcon()}
          </span>
        </span>
      </div>
    );
  };

  const AllActivities = () => {
    const columns =
      statusIndex[activeIndex] !== "Approved"
        ? selectedStatus === "Not Submitted"
          ? [
              {
                title: (
                  <span className="activities_title">
                    <div className="activities_title_text">Timesheet Date</div>{" "}
                    <img src={UpDown} alt="" />
                  </span>
                ),
                dataIndex: "timsesheet",
                key: "timesheet",
                render: (record, item) => (
                  <div
                    className="timesheet_regular"
                    style={{ cursor: "pointer" }}
                  >
                    {record}
                  </div>
                ),
              },
              {
                title: (
                  <span className="activities_title">
                    <div className="activities_title_text">Worked Hours</div>{" "}
                    <img src={UpDown} alt="" />
                    {/* <CalendarOutlined /> */}
                  </span>
                ),
                dataIndex: "worked",
                key: "worked",
                render: (record, item) => (
                  <div
                    className="timesheet_regular"
                    style={{ cursor: "pointer" }}
                  >
                    {myActivities?.data?.notSubmittedDetails?.workingHours}
                  </div>
                ),
              },
              {
                title: "Status",
                dataIndex: "status",
                key: "status",
                render: (record, item) => (
                  <div
                    className={`timesheet_status ${record}`}
                    style={{ cursor: "pointer" }}
                  >
                    {record}
                  </div>
                ),
              },
              {
                title: "Action",
                dataIndex: "action",
                key: "action",
                render: (record, item) => (
                  <div onClick={(e) => e.stopPropagation()}>
                    {item?.status !== "Approved" &&
                      renderActionsDropdown(record, item)}
                  </div>
                ),
              },
            ]
          : [
              {
                title: (
                  <span className="activities_title">
                    <div className="activities_title_text">Timesheet Date</div>{" "}
                    <img src={UpDown} alt="" />
                  </span>
                ),
                dataIndex: "timsesheet",
                key: "timesheet",
                render: (record, item) => (
                  <div
                    className="timesheet_regular"
                    style={{ cursor: "pointer" }}
                  >
                    {record}
                  </div>
                ),
              },
              {
                title: (
                  <span className="activities_title">
                    <div className="activities_title_text">Worked Hours</div>{" "}
                    <img src={UpDown} alt="" />
                    {/* <CalendarOutlined /> */}
                  </span>
                ),
                dataIndex: "worked",
                key: "worked",
                render: (record, item) => (
                  <div
                    className="timesheet_regular"
                    style={{ cursor: "pointer" }}
                  >
                    {" "}
                    {record} {HOURS_TEXT}{" "}
                  </div>
                ),
              },
              {
                title: "Status",
                dataIndex: "status",
                key: "status",
                render: (record, item) => (
                  <div
                    className={`timesheet_status ${record}`}
                    style={{ cursor: "pointer" }}
                  >
                    {record}
                  </div>
                ),
              },
              {
                title: "Action",
                dataIndex: "action",
                key: "action",
                render: (record, item) => (
                  <div onClick={(e) => e.stopPropagation()}>
                    {item?.status !== "Approved" &&
                      renderActionsDropdown(record, item)}
                  </div>
                ),
              },
            ]
        : [
            {
              title: (
                <span className="activities_title">
                  <div className="activities_title_text">Timesheet Date</div>{" "}
                  <img src={UpDown} alt="" />
                </span>
              ),
              dataIndex: "timsesheet",
              key: "timesheet",
              render: (record, item) => (
                <div
                  className="timesheet_regular"
                  style={{ cursor: "pointer" }}
                >
                  {record}
                </div>
              ),
            },
            {
              title: (
                <span className="activities_title">
                  <div className="activities_title_text">Worked Hours</div>{" "}
                  <img src={UpDown} alt="" />
                  {/* <CalendarOutlined /> */}
                </span>
              ),
              dataIndex: "worked",
              key: "worked",
              render: (record, item) => (
                <div
                  className="timesheet_regular"
                  style={{ cursor: "pointer" }}
                >
                  {record}
                </div>
              ),
            },
            {
              title: "Status",
              dataIndex: "status",
              key: "status",
              render: (record, item) => (
                <div
                  className={`timesheet_status ${record}`}
                  style={{ cursor: "pointer" }}
                >
                  {record}
                </div>
              ),
            },
          ];

    const employeeData = employeeTimesheet?.map((data, index) => ({
      timsesheet: (
        <div className="timesheet_regular">{data?.weekDisplayName || data}</div>
      ),
      worked: (
        <div className="timesheet_regular">
          {data?.totalTime?.hours.toString().padStart(2, "0")}.
          {data?.totalTime?.minutes.toString().padStart(2, "0")}
        </div>
      ),
      status: (
        <div className={`timesheet_status ${data?.status}`}>{data?.status}</div>
      ),
      action: (
        <Dropdown
          menu={{ items }}
          overlay={() => renderActionsMenu(data)}
          trigger={["click"]}
        >
          <span className="timesheet_icon_span">
            <img src={ThreeDots} className="timesheet_action_icon" alt="" />
          </span>
        </Dropdown>
      ),
    }));

    const employeeDataNotSub =
      myActivities?.data?.notSubmittedDetails?.weekValuesList?.map(
        (data, index) => ({
          timsesheet: (
            <div className="timesheet_regular">{data?.notSubmittedWeek}</div>
          ),
          worked: (
            <div className="timesheet_regular">
              {myActivities?.data?.notSubmittedDetails?.workingHours}
            </div>
          ),
          status: (
            <div
              className={`timesheet_status ${myActivities?.data?.notSubmittedDetails?.status}`}
            >
              {myActivities?.data?.notSubmittedDetails?.status}
            </div>
          ),
          action: (
            <Dropdown
              menu={{ items }}
              overlay={() => renderActionsMenu(data)}
              trigger={["click"]}
            >
              <span className="timesheet_icon_span">
                <img src={ThreeDots} className="timesheet_action_icon" alt="" />
              </span>
            </Dropdown>
          ),
        })
      );

    const employeeData1 = employeeTimesheet?.map((data, index) => ({
      timesheetId: data?.id,
      timsesheet: data?.weekDisplayName,
      weekStartDate: data?.weekStartDate,
      weekEndDate: data?.weekEndDate,
      worked: `${data?.totalTime?.hours
        .toString()
        .padStart(2, "0")}:${data?.totalTime?.minutes
        .toString()
        .padStart(2, "0")}`,
      status: data?.status,
      shiftType: data?.shiftType,
    }));

    const employeeDataNotSub1 =
      myActivities?.data?.notSubmittedDetails?.weekValuesList?.map(
        (data, index) => ({
          timsesheet: data?.notSubmittedWeek,
          status: myActivities?.data?.notSubmittedDetails?.status,
          startDate: data?.startDate,
          endDate: data?.endDate,
        })
      );

    const employeeDataAllList = allEmployeeTimesheet?.map((data) => {});

    const employeeDataAll = allEmployeeTimesheet?.map((data) => ({
      timsesheet: data?.notSubmittedWeek || data?.weekDisplayName,
      startDate: data?.startDate,
      endDate: data?.endDate,
      timesheetId: data?.id,
      weekStartDate: data?.weekStartDate,
      weekEndDate: data?.weekEndDate,
      worked: data?.status
        ? `${data?.totalTime?.hours
            .toString()
            .padStart(2, "0")}:${data?.totalTime?.minutes
            .toString()
            .padStart(2, "0")}`
        : "45.00",
      status: data?.status || myActivities?.data?.notSubmittedDetails?.status,
      shiftType: data?.shiftType,
    }));

    const ErrorTable = () => {
      return (
        <div className="error_table_tsm">
          <img className="error_table_img" src={EmptyCalendar} alt="" />
          <div className="error_table_header">No Timesheet</div>
          <div className="error_table_content">
            Yet to create your timesheet
          </div>
        </div>
      );
    };
    return (
      <Spin spinning={loadingActivities}>
        <div className="all_table_activities">
          {employeeData?.length || employeeDataNotSub?.length ? (
            <Table
              className="activities_table"
              dataSource={
                selectedStatus === ""
                  ? employeeDataAll
                  : selectedStatus === "Not Submitted"
                  ? employeeDataNotSub1
                  : employeeData1
              }
              columns={columns}
              bordered={false}
              rowClassName={"check"}
              rowHoverable={true}
              onRow={(record, item) => ({
                onClick: () =>
                  selectedStatus !== "Not Submitted" &&
                  handleRowClick(record, item),
              })}
            />
          ) : (
            <ErrorTable />
          )}
        </div>
      </Spin>
    );
  };
  const handlePreview = () => {
    setDisplayPreview(!displayPreview);
  };

  const editHandler = () => {
    const { weekStartDate, shiftType = "" } = selectedItems;
    navigate(
      `/web/${MY_SPACE}/timesheet/createtimesheet/${shiftType}/${weekStartDate}`
    );
  };

  return (
    <div className="timesheet_activity_page">
      <Breadcrumb items={breadcrumb} />
      <div className="timesheet_page">
        <div className="timesheet_header">
          <div className="timesheet_activities">{MY_ACTIVITIES}</div>
          {/* <Input
            className="timesheet_seach"
            placeholder="Select Month"
            suffix={
              <CalendarOutlined style={{ width: "20px", height: "20px" }} />
            }
          /> */}
        </div>
        <div className="timesheet_table">
          <Card style={{ width: "100%" }} className="performance_tab-otr">
            <TabView
              activeIndex={activeIndex}
              onTabChange={(e) => setActiveIndex(e.index)}
              className="manager-tabs"
            >
              <TabPanel header="All">
                <AllActivities />
              </TabPanel>
              <TabPanel header="Pending">
                <AllActivities />
              </TabPanel>
              <TabPanel header="Not Submitted">
                <AllActivities />
              </TabPanel>
              <TabPanel header="Draft">
                <AllActivities />
              </TabPanel>
              <TabPanel header="Approved">
                <AllActivities />
              </TabPanel>
              <TabPanel header="Rejected">
                <AllActivities />
              </TabPanel>
            </TabView>
            {employeeTimesheetLenght > 10 && <PaginationActivities />}
          </Card>
        </div>
      </div>

      {displayPreview && (
        <MyActivitiesPreview
          openPreview={displayPreview}
          closePreview={handlePreview}
          pageName={MY_ACTIVITIES}
          deleteHandlerPreview={deleteHandlerPreview}
          editHandler={editHandler}
          previewRequest={previewSelectedItems}
        />
      )}
      {/* delete Modal */}
      <Modal
        open={deletePopUp}
        className="preview_delete-modal"
        onCancel={() => setDeletePopUp(false)}
        footer={null}
        centered
      >
        <div className="preview_delete">
          <img src={Delete_Timesheet} alt="Delete Icon" />
          <div className="preview_delete_title">{DELETE_TIMESHEET}</div>
          <div className="preview_delete_content">
            {DELETE_TIMESHEET_MESSAGE}
          </div>
          <div className="preview_buttons">
            <button
              onClick={() => setDeletePopUp(false)}
              className="add_manager_delete"
            >
              {CANCEL}
            </button>
            <button onClick={handleDeleteHandler} className="timesheet_submit">
              {DELETE}
            </button>
          </div>
        </div>
      </Modal>
      {visible && (
        <ToastMessage
          closeAction={() => setVisible(false)}
          customToastArea={
            deletionSuccess
              ? "custom-toast-area skills-extracted"
              : "custom-toast-area no-skills-extracted"
          }
          customToastLeftArea={"custom-left-area"}
          icon={deletionSuccess ? ToastInfo : ToastError}
          toastMsgTitle={
            deletionSuccess
              ? deleteActivities?.headerMsg || DELETE_TOAST_TITLE
              : deleteActivities?.error?.errorHeaderMsg
          }
          toastMsgPara={
            deletionSuccess
              ? deleteActivities?.subHeadermsg || DELETE_TOAST_MESSAGE
              : deleteActivities?.error?.errorMessage
          }
          customToastRightArea={"close-btn-area"}
          closeIcon={ToastClose}
        />
      )}
    </div>
  );
};
export default MyActivities;
