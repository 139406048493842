import { useAppSelector, useAppDispatch } from "../../redux/store/redux-hooks";
import { START } from "../../constants";
import n from "../navigation/Route.Names";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form } from "antd";
import "./index.css";
import BreadcrumbComponent from "../common/breadcrumb/breadcrumb";
import { useCallback, useEffect, useState, useMemo } from "react";
import {
  Input,
  Switch,
  Select,
  Table,
  Modal,
  TableProps,
  Tabs,
  TabsProps,
  Spin,
  Space,
} from "antd";
import ToastMessage from "../common/ToastMsg/toastMessage";
import ToastClose from "../../themes/assets/images/performance/svg/ToastClose.svg";
import ToastInfo from "../../themes/assets/images/performance/svg/ToastInfo.svg";
import ToastError from "../../themes/assets/images/performance/svg/ToastError.svg";
import SearchIcon from "../../themes/assets/images/svg/search.svg";
import ManagerExportModal from "./ManagerExport";
import AdminTimesheet from "./AdminTimesheet";
import AdminEmployeeDownload from "./AdminEmployeeDownload";
import moment from "moment";
import {
  getAdminExportData,
  postAdminExportData,
} from "../../redux/actions/timesheet";

import {
  getAdminFinanceLanding,
  searchProjectAdmin,
} from "../../redux/actions/timesheet";
import {
  paginationLeftIcon,
  paginationRightIcon,
} from "../common/svg/paginationIcons";
import { debounce } from "lodash";

const { Search } = Input;
const AdminPanel = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const {
    loadingLanding,
    sendReminder,
    adminFinanceLanding,
    loadingAdminFinanceLanding,
    searchAdminProjectData,
    searchProjectAdminLoading,
  } = useAppSelector((state: any) => state.timesheet);
  const reminderSuccess = !sendReminder?.error;
  const [visibleReminder, setVisibleReminder] = useState(false);
  const [visibleEmailReminder, setVisibleEmailReminder] = useState(false);
  const items = [
    {
      title: (
        <Link
          to={`/${START}`}
          // onClick={(e) => redirection(e, TIMESHEET_BREADCRUMB)}
          className="link-highlight"
        >
          {"My Work"}
        </Link>
      ),
    },
    {
      title: "Admin",
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDownloadEnabled, setIsDownloadEnabled] = useState(false);
  const [dates, setDates] = useState([null, null]); // Store start and end dates
  const [searchValue, setSearchValue] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [selectedProjectList, setSelectedProjectList] = useState<any>([]);

  useEffect(() => {
    if (searchAdminProjectData?.data?.projectNames) {
      setProjectList(searchAdminProjectData?.data?.projectNames);
    }
  }, [searchAdminProjectData?.data?.projectNames]);

  const showModal = () => {
    dispatch(searchProjectAdmin(""));
    setIsModalVisible(true);
  };
  const handleModalCancel = () => {
    setIsModalVisible(false);
    setSelectedProjectList([]);
    form.resetFields();
    setIsDownloadEnabled(false);
    setDates([null, null]); // Reset the date range when modal closes
  };
  const onDateChange = (value, dateString) => {
    setDates(dateString); // Set the selected date range
    // If both start and end dates are selected, enable the download button
    setIsDownloadEnabled(value && value.length === 2 && value[0] && value[1]);
  };
  const clickOnDownload = () => {
    if (isDownloadEnabled && selectedProjectList?.length > 0) {
      setIsModalVisible(false);
      let obj = {
        projectName: selectedProjectList,
        startDate: moment(dates[0], "DD/MM/YYYY").format("YYYY-MM-DD"),
        endDate: moment(dates[1], "DD/MM/YYYY").format("YYYY-MM-DD"),
      };
      dispatch(getAdminExportData(obj))
        .unwrap()
        .then(() => {
          setVisibleReminder(true);
          setSelectedProjectList([]);
        });
    }
  };
  const clickOnEmail = () => {
    if (isDownloadEnabled && selectedProjectList?.length > 0) {
      setIsModalVisible(false);
      let obj = {
        projectName: selectedProjectList,
        startDate: moment(dates[0], "DD/MM/YYYY").format("YYYY-MM-DD"),
        endDate: moment(dates[1], "DD/MM/YYYY").format("YYYY-MM-DD"),
      };
      dispatch(postAdminExportData(obj))
        .unwrap()
        .then(() => {
          setVisibleEmailReminder(true);
          setSelectedProjectList([]);
        });
    }
  };

  const handleClose = () => {
    setVisibleReminder(false);
  };
  const handleClosePopup = () => {
    setVisibleEmailReminder(false);
    setSelectedProjectList([]);
  };
  const [start, setStart] = useState(0);
  const [count, setCount] = useState(10);
  const [isPageStart, setIsPageStart] = useState(1);
  const handleAdminSearchChange = (e) => {
    const searchValueCheck = e.target?.value;
    setSearchValue(searchValueCheck);
    setStart(0);
    setIsPageStart(1);
    if (searchValueCheck || searchValueCheck?.length === 0) {
      const adminSearch = {
        searchKey: searchValueCheck,
        start: start,
        count: 10,
      };
      dispatch(getAdminFinanceLanding(adminSearch));
    }
  };
  useEffect(() => {
    const adminSearch = {
      searchKey: searchValue,
      start: start,
      count: 10,
    };
    dispatch(getAdminFinanceLanding(adminSearch));
  }, [start]);
  const debounceValue = debounce(handleAdminSearchChange, 1000);
  const adminPanelData = adminFinanceLanding;
  const getProjectSearchVal = (searchKey) => {
    dispatch(searchProjectAdmin(searchKey));
  };
  const debounceProjectValue = debounce(getProjectSearchVal, 1000);

  const selectDeselectOption = (item) => {
    let arr = [...selectedProjectList];
    const indexToRemove = arr?.indexOf(item);
    if (indexToRemove > -1) {
      arr?.splice(indexToRemove, 1);
    } else {
      arr?.push(item);
    }
    setSelectedProjectList(arr);
  };

  const handleRemoveSelect = (item) => {
    let arr = [...selectedProjectList];
    const indexToRemove = arr?.indexOf(item);
    if (indexToRemove > -1) {
      arr?.splice(indexToRemove, 1);
    }
    setSelectedProjectList(arr);
  };

  const PaginationActivities = () => {
    const ITEMS_PER_PAGE = "Items per page:";
    const total = adminFinanceLanding?.data?.[0]?.totalCount;
    const goToPreviousPage = () => {
      if (start > 0) {
        const newStart = Math.max(0, start - count);
        setStart(newStart);
        setIsPageStart(newStart + 1);
      }
    };
    const goToNextPage = () => {
      if (start + count < total) {
        const newStart = Math.min(start + count, total);
        setStart(newStart);
        setIsPageStart(newStart + 1);
      }
    };
    const handleValueChange = (value) => {
      setCount(value);
      setStart(0);
      setIsPageStart(1);
    };
    const displayEnd = Math.min(start + count, total);
    return (
      <div className="pagination-timesheet">
        {ITEMS_PER_PAGE}{" "}
        <Select
          defaultValue={10}
          options={[{ value: 10, label: 10 }]}
          onChange={handleValueChange}
        />
        <div className="pagination-timesheet-text">
          {`${isPageStart} - ${displayEnd} of ${total}`}{" "}
        </div>
        <span className="parent_pagination_icons">
          <span
            className={`pagination_left_icon ${start === 0 ? "disabled" : ""}`}
            onClick={goToPreviousPage}
          >
            {paginationLeftIcon()}
          </span>
          <span
            className={`pagination_right_icon ${
              start + count >= total ? "disabled" : ""
            }`}
            onClick={goToNextPage}
          >
            {paginationRightIcon()}
          </span>
        </span>
      </div>
    );
  };

  return (
    <Spin spinning={loadingLanding}>
      <div className="main_container_approval">
        <div className="hidestricky_breadcrump">
          <BreadcrumbComponent items={items} />
          <div className="Seperation-page-title">
            <div className="admin_page-title">
              Admin
              <Space className="admin-search_action" direction="vertical">
                <Search
                  suffix={
                    <img
                      src={SearchIcon}
                      alt="Search Icon"
                      style={{ width: 20, height: 20 }}
                    />
                  }
                  placeholder="Search"
                  onChange={(e) => {
                    debounceValue(e);
                  }}
                />
              </Space>
            </div>
            <div className="landing-page-footer">
              <AdminEmployeeDownload
                showModal={showModal}
                isModalVisible={isModalVisible}
                clickOnDownload={clickOnDownload}
                clickOnEmail={clickOnEmail}
                isRangeSelected
                handleCancel={handleModalCancel}
                onDateChange={onDateChange}
                isDownloadEnabled={isDownloadEnabled}
                dates={dates}
                form={form}
                title="Export Timesheet"
                projectList={projectList}
                getProjectSearchVal={(value) => debounceProjectValue(value)}
                selectDeselectOption={(value) => selectDeselectOption(value)}
                selectedProjectList={selectedProjectList}
                searchAdminProjectData={searchAdminProjectData}
                handleRemoveSelect={(item) => handleRemoveSelect(item)}
              />
            </div>
          </div>
        </div>
        <div className="apprval_tab_parent">
          <div className="admin-fin-pagination">
            <AdminTimesheet
              loadingAdmin={loadingAdminFinanceLanding}
              adminPanelData={adminPanelData}
            />
            {adminFinanceLanding?.data?.[0]?.totalCount ? (
              <PaginationActivities />
            ) : null}
          </div>
        </div>
      </div>
      {visibleReminder && (
        <ToastMessage
          closeAction={handleClose}
          customToastArea={
            reminderSuccess
              ? "custom-toast-area skills-extracted"
              : "custom-toast-area no-skills-extracted"
          }
          customToastLeftArea={"custom-left-area"}
          icon={reminderSuccess ? ToastInfo : ToastError}
          toastMsgTitle={
            reminderSuccess
              ? "Export File Successfully"
              : "Export File Unsuccessfull"
          }
          toastMsgPara={
            reminderSuccess
              ? "File has been successfully downloaded"
              : "Unable to download the file, please try later"
          }
          customToastRightArea={"close-btn-area"}
          closeIcon={ToastClose}
        />
      )}
      {visibleEmailReminder && (
        <ToastMessage
          closeAction={handleClosePopup}
          customToastArea={
            reminderSuccess
              ? "custom-toast-area skills-extracted"
              : "custom-toast-area no-skills-extracted"
          }
          customToastLeftArea={"custom-left-area"}
          icon={reminderSuccess ? ToastInfo : ToastError}
          toastMsgTitle={
            reminderSuccess ? "Email Sent Successfully" : "File not Delivered"
          }
          toastMsgPara={
            reminderSuccess
              ? "Email has been sent successfully"
              : "Unable to deliver the file, please try later"
          }
          customToastRightArea={"close-btn-area"}
          closeIcon={ToastClose}
        />
      )}
    </Spin>
  );
};

export default AdminPanel;
